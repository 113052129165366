import { Pipe, PipeTransform } from "@angular/core";
import { AircraftMapping } from "@orion2/models/settings.models";
import { Store } from "@viewer/core";

/**
 * Returns the business version for the given couple of technical model/version.
 * If no mapping is found, default to the technical version.
 */
@Pipe({
  name: "versionMapping"
})
export class VersionMappingPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(technicalVersion: string, technicalModel: string): string {
    if (!technicalVersion) {
      return "-";
    }
    const mapping = this.store.aircraftMappingSettings.mapping.find(
      (aircraftMapping: AircraftMapping) =>
        aircraftMapping.technicalModel === technicalModel &&
        aircraftMapping.technicalVersion === technicalVersion
    );

    return mapping?.businessVersion || technicalVersion;
  }
}
