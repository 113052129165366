import { MatDialog } from "@angular/material/dialog";
import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { StatusCodes } from "http-status-codes";
import { ConfService, PouchService } from "@viewer/core";
import {
  DisclaimerComponent,
  DisclaimerType
} from "@viewer/help-module/disclaimer/disclaimer.component";
import { MimeType } from "@orion2/models/enums";

@Injectable()
export class GdprService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly handleGdprCheckBind: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly handleRemoteRequestBind: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly handleGdprVersionBind: any;

  constructor(
    private httpClient: HttpClient,
    private pouchService: PouchService,
    private confService: ConfService,
    public dialog: MatDialog
  ) {
    this.handleGdprVersionBind = this.gdprVersion.bind(this);
    this.handleGdprCheckBind = this.gdprCheck.bind(this);
    this.handleRemoteRequestBind = this.performRequest.bind(this);
    addEventListener("gdprVersionRequest", this.handleGdprVersionBind);
    addEventListener("openGdprPopup", this.handleGdprCheckBind);
    addEventListener("sendRequest", this.handleRemoteRequestBind);
  }

  ngOnDestroy(): void {
    removeEventListener("gdprVersionRequest", this.handleGdprVersionBind);
    removeEventListener("openGdprPopup", this.handleGdprCheckBind);
    removeEventListener("sendRequest", this.handleRemoteRequestBind);
  }

  /**
   * Event binded method that retrieves the GDPR version and send it to AuthService
   *
   * @param event
   */
  private gdprVersion(_event): void {
    const version = this.confService.disclaimersVersions[DisclaimerType.GDPR];
    this.postMessage("gdprVersionResponse", version);
  }

  /**
   * Event binded method that opens a fullscreen popup and send the response to AuthService
   *
   * @param event
   */
  private gdprCheck(_event): void {
    if (this.dialog.openDialogs.length === 0) {
      this.dialog
        .open(DisclaimerComponent, {
          disableClose: true,
          closeOnNavigation: false,
          panelClass: "disclaimer-dialog-container",
          data: {
            type: DisclaimerType.GDPR,
            consult: false
          }
        })
        .afterClosed()
        .subscribe((agreement: boolean) => {
          this.postMessage("gdprPopupClosed", agreement);
        });
    }
  }

  /**
   * Event binded method that send either a GET or a PUT request and send the response to AuthService
   * GET request gets all gdpr agreements
   * PUT request update all gdpr agreements
   *
   * @param event
   */
  private performRequest(event: CustomEvent): void {
    const { type, username, token, newAgreements, _rev } = event.detail;
    // We need this.pouchService.databaseConf.couchBaseUrl to be able to have the used DB url, even if it is modified in $dbStatus$
    const url =
      `${this.pouchService.databaseConf.couchBaseUrl}/user_${username}/agreements`.toLowerCase();
    const options = {
      headers: {
        Accept: MimeType.JSON,
        "Content-Type": MimeType.JSON,
        Authorization: `Bearer ${token?.accessToken}`,
        oak: token?.oak as string
      },
      body: { _rev, agreements: newAgreements }
    };
    this.httpClient
      .request(type, url, options)
      .toPromise()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: HttpResponse<any>) => {
        this.postMessage("requestResponse", response);
      })
      .catch(err => {
        if (err.status === StatusCodes.NOT_FOUND) {
          console.warn(`DB at ${url} does not exist`);
        } else {
          console.error(`Error while doing ${type} request ${url}`, err);
        }
        this.postMessage("requestResponse", null);
      });
  }

  /**
   * Method that send a message to AuthService
   *
   * @param eventName
   * @param message
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private postMessage(eventName: string, message: any): void {
    const event = new CustomEvent(eventName, {
      detail: {
        response: message
      }
    });
    dispatchEvent(event);
  }
}
