/**
 * Display message using snackBar
 *
 * @export
 * @class MessageService
 */
import { Injectable, NgZone, OnDestroy } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar,
  MatSnackBarDismiss
} from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subscription } from "rxjs";

interface MessageObject {
  message: string;
  action: any;
  config: any;
}

@Injectable()
export class MessageService implements OnDestroy {
  readonly successClass = "success-snackbar";
  readonly errorClass = "error-snackbar";
  readonly warningClass = "warning-snackbar";
  private queue: Array<MessageObject> = Array<MessageObject>();
  private subscription: Subscription = new Subscription();
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private isInstanceVisible = false;

  constructor(public snackBar: MatSnackBar, private router: Router, private ngZone: NgZone) {}

  /**
   * The observable of the event of the closing of the snackbar (because timeout or action).
   *  The resolved MatSnackbarDismissed has an boolean attribute 'dismissedByAction' indicates the reason of the closing.
   */
  get onDismiss(): Observable<MatSnackBarDismiss> {
    return this.snackBar._openedSnackBarRef.afterDismissed();
  }

  /**
   * The observable of the event of pressing the action button
   */
  get onAction(): Observable<void> {
    return this.snackBar._openedSnackBarRef.onAction();
  }

  /**
   * Display a success message
   *
   * @param {any} message
   * @param action
   * @param {any} [duration]
   * @returns
   * @memberOf MessageService
   */
  success(message: string, action?: string, duration?: number): void {
    this._sendMessage(message, action, this.successClass, duration);
  }

  /**
   * Display an error message
   *
   * @param {any} message
   * @param {any} [duration]
   * @param action
   * @returns {MatSnackBarRef<SimpleSnackBar>}
   * @memberOf MessageService
   */
  error(message: string, action?: string, duration?: number): void {
    return this._sendMessage(message, action, this.errorClass, duration);
  }

  /**
   * Display an warning message
   *
   * @param {any} message
   * @param {any} [duration]
   * @returns {MatSnackBarRef<SimpleSnackBar>}
   * @memberOf MessageService
   */
  warning(message: string, action?: string, duration?: number): void {
    return this._sendMessage(message, action, this.warningClass, duration);
  }

  /**
   * Add action to snackbar and navigate
   *
   * @param {string} url
   * @param {ActivatedRoute} route
   * @returns
   * @memberof MessageService
   */
  addActionNavigate(url: string, route: ActivatedRoute) {
    this.snackBar._openedSnackBarRef.onAction().subscribe(() => {
      this.router.navigate([url], {
        relativeTo: route
      });
    });
  }
  /**
   * Add action to snackbar and navigate
   *
   * @param {string} url
   * @param {ActivatedRoute} route
   * @param params
   * @returns
   * @memberof MessageService
   */
  addActionNavigateWithParams(url: string, route: ActivatedRoute, params) {
    this.snackBar._openedSnackBarRef.onAction().subscribe(() => {
      this.router.navigate([url], {
        queryParams: params,
        relativeTo: route
      });
    });
  }
  addClose() {
    this.snackBar._openedSnackBarRef.onAction().subscribe(() => {
      this.snackBar._openedSnackBarRef.dismiss();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Display the next message in the queue
   */
  private showNext(): void {
    if (this.queue.length === 0) {
      return;
    }
    const message: MessageObject = this.queue.shift();
    this.isInstanceVisible = true;
    // force all snackbars opening inside the same zone
    // ref: BUG-1650
    this.snackBarRef = this.ngZone.run(() =>
      this.snackBar.open(message.message, message.action, message.config)
    );
    this.subscription.add(
      this.snackBarRef.afterDismissed().subscribe(() => {
        this.isInstanceVisible = false;
        this.showNext();
      })
    );
  }

  /**
   * Add message to queue
   *
   * @private
   * @param {any} message
   * @param action
   * @param classLabel
   * @param {number} [duration=4000]
   * @returns {void}
   * @memberof MessageService
   */
  private _sendMessage(message, action, classLabel, duration?): void {
    // If there is an action I want the user to see it so we leave him more time by default
    if (!duration) {
      duration = action ? 10000 : 2000;
    }
    const messageObject: MessageObject = {
      message,
      action,
      config: { panelClass: [classLabel], duration }
    };

    // If there is an action we want the user to be able to see it quickly so we bypass the queue
    if (!action) {
      this.queue.push(messageObject);
    } else {
      this.queue.unshift(messageObject);
    }
    // We force the print of the message now if there is an action or if no message is currently show
    if (action || !this.isInstanceVisible) {
      this.showNext();
    }
  }
}
