import { Component, Inject, OnInit } from "@angular/core";
import { BookmarkService, Store } from "@viewer/core";
import { cloneDeep } from "lodash";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { BookmarkTreeService } from "@viewer/toc-items/bookmark-module/bookmark-list/bookmark-tree.service";
import { BookmarkEditData, TreeNode } from "@viewer/toc-items/bookmark-module/bookmark-list/models";
import { Bookmark } from "@orion2/models/tocitem.models";
import { OrionCardData } from "@viewer/shared-module/orion-card/orion-card.component";

@Component({
  selector: "o-bookmark-edit",
  templateUrl: "./bookmark-edit.component.html",
  styleUrls: ["./bookmark-edit.component.scss"]
})
export class BookmarkEditComponent implements OnInit {
  public form: FormGroup;
  public folders: TreeNode[];
  private disabledFolders: string[];
  private dmc: string;
  private title: string;
  private reference: string;
  private revision: string;
  private versions: string[];
  private applicabilityMD5: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BookmarkEditData,
    private bookmarkService: BookmarkService,
    private store: Store,
    private bookmarkTreeService: BookmarkTreeService,
    private dialogRef: MatDialogRef<BookmarkEditComponent>
  ) {}

  ngOnInit(): void {
    const bookmarkData = this.data.bookmark;
    const tocNode = this.store.currentTocNode;

    this.folders = this.bookmarkTreeService.getFolders();
    this.dmc = bookmarkData?.dmc || tocNode.dmc;
    this.title = bookmarkData?.title || tocNode.shortTitle || tocNode.title;
    this.reference = bookmarkData?.reference || tocNode.reference;
    this.revision = bookmarkData?.revision || tocNode.type || tocNode.revision;
    this.versions = bookmarkData?.versions || tocNode.versions;
    this.applicabilityMD5 = bookmarkData?.applicabilityMD5 || tocNode.applicabilityMD5;

    this.bookmarkService.getBookmarksByDMC(this.dmc).then((bookmarksFromDMC: Bookmark[]) => {
      this.disabledFolders = this.folders
        .filter((folder: TreeNode) =>
          bookmarksFromDMC.find((bookmarkFromDMC: Bookmark) =>
            this.bookmarkTreeService.folderHasBookmarkId(folder, bookmarkFromDMC._id)
          )
        )
        .map(folder => folder.id);
      if (this.data.mode === "add") {
        this.form = new FormGroup({
          title: new FormControl(this.title, [Validators.required]),
          folder: new FormControl(this.disabledFolders.length > 0 ? this.disabledFolders : ["root"])
        });
      } else {
        this.form = new FormGroup({
          title: new FormControl(this.title, [Validators.required]),
          folder: new FormControl(
            {
              value: this.data.parent,
              disabled: this.folders.length < 2
            },
            [Validators.required]
          )
        });
      }
      this.form.setValidators(this.ChangeValidator());
    });
  }

  public save(): Promise<boolean> {
    this.dialogRef.close(true);
    if (this.data.mode === "edit") {
      const editedBookmark = cloneDeep(this.data.bookmark);
      const destination = this.form.get("folder").value;
      editedBookmark.title = this.form.get("title").value;
      return this.bookmarkService
        .update(this.data.bookmark, editedBookmark)
        .then(() =>
          this.bookmarkTreeService.moveItem(this.data.bookmark._id, editedBookmark._id, destination)
        );
    } else {
      const destinations: string[] = this.form.get("folder").value;
      const bookmarkData: OrionCardData = {
        dmc: this.dmc,
        title: this.form.get("title").value,
        subtitle: this.reference,
        revisionMark: this.revision,
        versions: this.versions,
        applicabilityMD5: this.applicabilityMD5
      };
      const newDestinations = destinations.filter(
        destination => !this.disabledFolders.includes(destination)
      );
      return this.bookmarkService
        .addBookmarks(newDestinations.length, bookmarkData)
        .then(newBookmarks => this.bookmarkTreeService.addBookmarks(newBookmarks, newDestinations));
    }
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public isSelected(folderUsed: string, folder: string) {
    return folderUsed === folder;
  }

  public hasBookmarkOfCurrentDMC(folderId): boolean {
    return (
      this.disabledFolders.filter(id => id === folderId).length === 1 && this.data.mode === "add"
    );
  }

  private ChangeValidator(): ValidatorFn {
    return (): ValidationErrors | null =>
      this.detectChanges() ? null : { noChange: { value: false } };
  }

  private detectChanges(): boolean {
    if (this.data.mode === "add") {
      const folders = this.form.get("folder").value;
      return this.disabledFolders.length !== folders.length;
    } else {
      const title = this.form.get("title").value;
      const folder = this.form.get("folder").value;
      return this.data.parent !== folder || this.title !== title;
    }
  }
}
