import { Injectable, Injector } from "@angular/core";
import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { NoteComponent } from "@viewer/toc-items/note-module/note/note.component";
import { Note } from "@orion2/models/tocitem.models";
import { ApplicabilityService } from "@viewer/core/applicability/applicability.service";
import { DBConnexionType } from "@viewer/core/pouchdb/types";
import { v4 as uuidv4 } from "uuid";
import { isFullscreenView } from "@orion2/utils/front.utils";

@Injectable()
export class NoteService extends TocItemService {
  protected tiType = "notes";
  protected tiTarget = DBConnexionType.BOTH;
  // By setting this param, I limit calls to DBs where notes can exist
  protected visibilityMap = {
    private: "tocUserCaller",
    corporate: "tocCorpCaller",
    public: "tocPublicCaller"
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;
  private noteComponents: NoteComponent[];

  constructor(protected injector: Injector, private applicabilityService: ApplicabilityService) {
    super(injector);
    this.noteComponents = [];
  }

  /**
   * Create note and add it in the list
   *
   * @memberof NoteService
   */
  public createNote(): void {
    const note: Note = this.initNote();
    this._tocItemsForTarget.unshift(note);
  }

  /**
   * Delete note
   *
   * @param note
   * @memberof NoteService
   */
  public deleteWithUndo(note: Note): Promise<boolean> {
    const noteCopy: Note = { ...note };
    return this.delete(note, note.status)
      .then((ret: boolean) => {
        // we dont display message if note is empty on delete (cancel the creation of note)
        if (ret && note.customTitle) {
          const deleteMsg = note.customTitle + this.translate.instant("notes.deletedMsg");
          const undoMsg = this.translate.instant("undo");
          this.messageService.success(deleteMsg, undoMsg);

          // Leave the possibility to undo the deletion via a button in the snackbar
          this.messageService.onAction.subscribe(this.undoDeleteFactory(noteCopy));
        }
        return ret;
      })
      .catch(() => {
        const errMessage = this.translate.instant("tocItem.errorDelete", {
          tocItemType: this.tiType
        });
        this.messageService.error(errMessage);
        return false;
      });
  }

  public addNoteComponent(noteComponent: NoteComponent): void {
    const index = this.noteComponents.indexOf(noteComponent);
    if (index < 0) {
      this.noteComponents.push(noteComponent);
    }
  }

  public removeNoteComponent(noteComponent: NoteComponent): void {
    const index = this.noteComponents.indexOf(noteComponent, 0);
    if (index > -1) {
      this.noteComponents.splice(index, 1);
    }
  }

  /**
   * Delete all new notes that haven't been saved
   */
  public deleteNewNotes(): Promise<boolean[]> {
    return Promise.all(
      this._tocItemsForTarget
        .filter((note: Note) => note.customTitle === "")
        .map((note: Note) => this.deleteWithUndo(note))
    );
  }

  /**
   * Save all dirty notes
   *
   * @returns A boolean in a promise representing if all notes are now
   */
  public saveAllNotes(): Promise<boolean> {
    let notesToBeSaved: NoteComponent[] = this.noteComponents
      .filter(n => !n.saved)
      .sort((note1, note2) =>
        note1.currentNote.customTitle >= note2.currentNote.customTitle ? -1 : 1
      );

    /**
      We possibily have 2 instances of same note on notesTosaved (from drawer and from fulltext)
      that cause redirect bug after save. One of the duplicated note got an empty title and empty description.
      And we want to focus the note component not saved, if we don't remove duplicated notes (with the same _id)
      We will considering the unwanted note to not be saved and need to be focused.
     */
    notesToBeSaved = Array.from(new Set(notesToBeSaved.map(note => note.currentNote._id))).map(id =>
      notesToBeSaved.find(note => note.currentNote._id === id)
    );

    // We first try to save all dirty notes and store the result of each in an array of promised results
    const savedNoteResults: Promise<boolean>[] = notesToBeSaved.map(note => {
      if (note.currentNote.customTitle.trim() !== "") {
        return note.saveNote(note.currentNote);
      } else {
        // If no title or only white spaces, we can't save the note and invite the user to modify it by setting the focus on it
        // if we are on fullscreen view we need to open the drawer before focus it
        if (isFullscreenView(this.router.url, this.store.view) || this.store.isEncapsulatedPdf) {
          this.store.noteDrawerOpen = true;
          const input = document.querySelector<HTMLElement>(".from_drawer input");
          input.focus();
        } else {
          note.title.nativeElement.focus();
        }
        return Promise.resolve(false);
      }
    });

    return Promise.all(savedNoteResults)
      .then((resp: boolean[]) => {
        // We check if all notes are now saved
        const allSaved: boolean = resp.reduce((acc, saved) => acc && !!saved, true);

        if (allSaved) {
          const message = this.translate.instant("notes.saveAll");
          this.messageService.success(message);
        } else {
          const message = this.translate.instant("notes.canNotBeSave");
          this.messageService.warning(message);
        }
        return allSaved;
      })
      .catch(error => {
        console.error(error);
        const message = this.translate.instant("tocItem.notSavedMsg");
        this.messageService.error(message);
        return false;
      });
  }

  public canDeactivate(): boolean {
    return this.noteComponents.filter(n => n.saved).length !== this.noteComponents.length;
  }

  /**
   * Create a standard undo function to pass to the deleteWithUndo function
   *
   * @param noteToRestore - The note to restore
   */
  protected undoDeleteFactory(noteToRestore: Note): () => Promise<Note[]> {
    return () => {
      delete noteToRestore._rev;
      return this.save(noteToRestore, noteToRestore.status).then((res: boolean) => {
        if (res) {
          const undoMessage = this.translate.instant("undo.ok");
          this.messageService.success(undoMessage);
          return this.getItemsOfType() as Promise<Note[]>;
        } else {
          const errMessage = this.translate.instant("undo.error");
          this.messageService.error(errMessage);
          return [];
        }
      });
    };
  }

  /**
   * Initialize note
   */
  private initNote(): Note {
    const note = {
      _id: `${this.store.currentTocNode._id}__${
        this.store.publicationRevision
      }__notes__${uuidv4()}`,
      type: this.tiType,
      minRevision: this.store.publicationRevision,
      status: "private",
      shortTitle: this.titleService.headerTitle,
      customTitle: "",
      content: "",
      dmc: this.store.currentDMC,
      shortDMC: this.store.currentTocNode.reference,
      manualLabel: this.store.duObject.xml?.querySelector("manualLabel")?.innerHTML,
      author: this.store.user.userName,
      parents: [this.tiType],
      date: new Date(),
      technicalEvent: null,
      color: undefined,
      applic: {
        version: undefined,
        serialno: undefined
      }
    };
    // If a filter is on and the toc node is applicable, then we store the applic to remember this state
    if (
      this.applicabilityService.userSelectedCriterias.filterOn &&
      this.applicabilityService.userSelectedCriterias.version !== "" &&
      this.applicabilityService.isApplicable(this.store.currentTocNode)
    ) {
      note.applic.version = this.applicabilityService.userSelectedCriterias.version;
      if (this.applicabilityService.userSelectedCriterias.serialno !== "") {
        note.applic.serialno = this.applicabilityService.userSelectedCriterias.serialno;
      }
    }
    return note;
  }
}
