import { Injectable } from "@angular/core";
import { Store } from "@viewer/core";
import { TocMode } from "@viewer/layout/toc-mode.model";

// all properties must be set in percent of screen
export interface Bound {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const TOC_ANIM_DELAY = 350;

@Injectable({
  providedIn: "root"
})
export class PdfIosService {
  // With the pdf viewer, click event are managed by the pdf viewer
  // And we can set some elements managed by cordova
  // In debug mode you can see cordova clickable elements with a red background
  // and pdf viewers areas without background
  public pdfClosed = false;
  public searchColor = "#e1e000";
  public eventMap = new Map<string, EventListener>();

  // clickableElements are all the elements whose clicks are redirected to the cordova view
  // after click on them we check if we are in fullscreen mode or not
  private clickableElements = [
    // Publication TOC
    ".toc-sidenav",
    // publications header
    "o-header",
    // non applicable header
    ".header__not-applicable",
    // user manual header
    ".home-header",
    // notes buttons
    "o-notes-button",
    // preprint header
    ".header-preprint"
  ];

  // in fullscreen mode the body click is listening
  // after click on it reset pdf position and clickable area
  private bodyElement = ["body"];

  constructor(public store: Store) {}

  public setup(): void {
    setTimeout(() => {
      this.eventMap.set("orientation", this.resetPositions.bind(this));
      window.addEventListener("orientationchange", this.eventMap.get("orientation"));
      this.addListeners(this.clickableElements, this.resetPositions.bind(this));
      this.setSafeAreaGuide();
      this.setTransparentBackground();
      this.resetPositions();
    }, 0);
  }

  public resetPositions() {
    setTimeout(() => {
      this.setPdfViewerPosition();
      this.setClickableAreas();
    }, TOC_ANIM_DELAY);
  }

  public displayPDF(filepath: string, searchQuery: string): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).plugins.nativePdfViewer.loadPDF(
      filepath,
      () => {
        console.log("[Native PDF Viewer] PDF loaded successfully");
      },
      err => {
        console.error(`[Native PDF Viewer] Error while loading PDF: ${err}`);
      }
    );
    this.pdfClosed = false;
    this.setClickableAreas();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).plugins.nativePdfViewer.search(
      searchQuery,
      this.searchColor,
      () => {
        console.log("[Native PDF Viewer] Success to search" + searchQuery);
      },
      err => {
        console.error(`[Native PDF Viewer] Error on search: ${err}`);
      }
    );
  }

  public dispose(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).plugins.nativePdfViewer.loadPDF(null, null, null);
    // remove click listeners
    window.removeEventListener("orientationchange", this.eventMap.get("orientation"));
    this.removeListeners([...this.bodyElement, ...this.clickableElements]);
    this.eventMap.clear();
    this.setTransparentBackground(true);
    this.pdfClosed = true;
    this.setClickableAreas();
  }

  private setClickableAreas(): void {
    const clickableElementsBounds: Bound[] = [];

    const tocOverOpened =
      document.querySelector(".toc-button")?.classList.contains("opened") &&
      this.store.tocMode === TocMode.OVER;

    const notePanelOpened = document
      .querySelector(".note-sidenav")
      ?.classList.contains("mat-drawer-opened");

    const settingsPanelOpened = document
      .querySelector(".setting-button")
      ?.classList.contains("opened");

    const isModalActive = document.querySelector(".cdk-overlay-backdrop");

    // we are in fullscreen interative mode
    if (
      this.pdfClosed ||
      tocOverOpened ||
      notePanelOpened ||
      settingsPanelOpened ||
      isModalActive
    ) {
      const fullscreenBound: Bound = {
        x: 0.0,
        y: 0.0,
        width: 1.0,
        height: 1.0
      };
      clickableElementsBounds.push(fullscreenBound);
      this.addListeners(this.bodyElement, this.bodyClick.bind(this));
    } else {
      const windowsBound = this.getWindowBound();
      this.clickableElements.forEach((element: string) => {
        const clientBound = document.querySelector(element)?.getBoundingClientRect();
        if (clientBound) {
          const percentBound: Bound = {
            x: clientBound.x / windowsBound.width,
            y: clientBound.y / windowsBound.height,
            width: clientBound.width / windowsBound.width,
            height: clientBound.height / windowsBound.height
          };
          clickableElementsBounds.push(percentBound);
        }
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).plugins.nativePdfViewer.setCordovaClickableBounds(
      clickableElementsBounds,
      this.store.debugMode,
      () => {
        console.log("[Native PDF Viewer] Success to set clickable bounds");
      },
      err => {
        console.error(`[Native PDF Viewer] Error to set clickable bounds ${err}`);
      }
    );
  }

  /**
   * Set initial management of clickable areas
   */
  private bodyClick(): void {
    if (this.pdfClosed) {
      return;
    }
    this.removeListeners(this.bodyElement);
    this.resetPositions();
  }

  /**
   * Set pdf viewer origin position
   */
  private setPdfViewerPosition() {
    let topMargin = 0.0;
    let leftMargin = 0.0;
    const clientBound = document.querySelector("o-pdf")?.getBoundingClientRect();
    if (clientBound) {
      topMargin = clientBound.y;
      leftMargin = clientBound.x;
    }
    const windowsBound = this.getWindowBound();
    const marginTop = topMargin / windowsBound.height;
    const marginLeft = leftMargin / windowsBound.width;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).plugins.nativePdfViewer.setPDFViewerOffset(
      marginTop,
      marginLeft,
      () => {
        console.log("[Native PDF Viewer] Success to position pdf");
      },
      err => {
        console.error(`[Native PDF Viewer] Error to position pdf ${err}`);
      }
    );
  }

  /**
   * Safe area guide includes status bar and notch
   */
  private setSafeAreaGuide(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).plugins.nativePdfViewer.useSafeAreaGuide(
      true,
      false,
      () => {
        console.log("[Native PDF Viewer] success to set area guide");
      },
      err => {
        console.error(`[Native PDF Viewer] error to set area guide ${err}`);
      }
    );
  }

  /**
   * Add or remove transparent background on all parent element of o-pdf.
   * The native pdf player is behind the orion app so to see the pdf we
   * need to set transparent background on all parent elements of o-pdf.
   * There is concurrency with class non-applicable-content and
   * preprint-media which also set a background important. So we need to also
   * remove these class.
   */
  private setTransparentBackground(reset = false): void {
    let target = document.querySelector("o-pdf");
    const noteContainer = document.querySelector(".noteContainer");
    const main = document.querySelector("main");
    if (!reset) {
      noteContainer?.classList.remove("non-applicable-content");
      main?.classList.remove("preprint-media");
    }

    do {
      if (reset) {
        target?.classList.remove("tranparent_background");
      } else {
        target?.classList.add("tranparent_background");
      }
      target = target?.parentElement;
    } while (target && target.nodeName !== "HTML");
  }

  private getWindowBound(): Bound {
    return {
      x: 0.0,
      y: 0.0,
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  /**
   * Add a given callback as event listeners on a list of selectors.
   */
  private addListeners(selectors: string[], callback: Function): void {
    for (const selector of selectors) {
      const element = document.querySelector(selector);
      if (element && !this.eventMap.has(selector)) {
        this.eventMap.set(selector, callback.bind(this, selector));
        element.addEventListener("click", this.eventMap.get(selector));
      }
    }
  }

  /**
   * Remove event listeners from all elements associated to the given list of selectors.
   *
   * @param selectors An array of css selectors.
   */
  private removeListeners(selectors: string[]): void {
    for (const selector of selectors) {
      const element = document.querySelector(selector);
      if (element && this.eventMap.has(selector)) {
        element.removeEventListener("click", this.eventMap.get(selector));
        this.eventMap.delete(selector);
      }
    }
  }
}
