import xpath from "xpath";
import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";
import { OBJECT_SEPARATOR, ARRAY_SEPARATOR } from "@orion2/utils/datamodule.utils";

export class Applic {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static extractLegacy(xmlNode: Node): string {
    // Not available for legacy
    return "";
  }

  public static extractH160(xmlNode: Node): string {
    const applicRefId = xpath.select("string(@applicRefId)", xmlNode).toString();

    const modApplicabilitie = xpath.select(
      `ancestor::content//referencedApplicGroup/applic[@id = '${applicRefId}']`,
      xmlNode
    )[0] as Node;

    if (modApplicabilitie) {
      const applicObj: TaskNamespace.Applic = {
        climat: []
      };

      const descendants = xpath.select("descendant::assert", modApplicabilitie);
      descendants.forEach((descendant: Node) => {
        const applicPropertyIdent = xpath
          .select("string(@applicPropertyIdent)", descendant)
          .toString();

        const applicPropertyValues = xpath
          .select("string(@applicPropertyValues)", descendant)
          .toString();

        if (applicPropertyIdent.includes("Climat")) {
          applicObj.climat.push(applicPropertyValues);
        }
      });

      return [applicObj.climat.join(ARRAY_SEPARATOR)].join(OBJECT_SEPARATOR);
    } else {
      return "";
    }
  }

  public static unserialize(data: string): TaskNamespace.Applic {
    if (data) {
      const obj = data.split(OBJECT_SEPARATOR);

      return {
        climat: obj[0].split(ARRAY_SEPARATOR)
      };
    }

    return undefined;
  }
}
