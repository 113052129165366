import xpath from "xpath";
import { Util, OBJECT_SEPARATOR, ARRAY_SEPARATOR } from "@orion2/utils/datamodule.utils";
import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";

export class PnrData {
  public static extractLegacy(xmlNode: Node): string {
    const data: string[] = [];

    const pnrdatas = xpath.select("MAINLIMI/PNRLIST/PNRDATA", xmlNode);

    pnrdatas.forEach((pnrdata: Node) => {
      const changed = xpath.select("string(.//@CHANGEMARK)", pnrdata).length > 0;
      const pnr = Util.cleanXml(xpath.select("string(PNR)", pnrdata));
      const pn = Util.cleanXml(xpath.select("string(PN)", pnrdata));

      const title = Util.cleanXml(xpath.select("string(PNR/@*[name()='xlink:title'])", pnrdata));

      const href = Util.cleanXml(xpath.select("string(PNR/@*[name()='xlink:href'])", pnrdata));

      data.push(
        pnr +
          OBJECT_SEPARATOR +
          pn +
          OBJECT_SEPARATOR +
          href +
          OBJECT_SEPARATOR +
          title +
          OBJECT_SEPARATOR +
          changed
      );
    });

    return data.join(ARRAY_SEPARATOR);
  }

  public static extractH160(xmlNode: Node): string {
    const data: string[] = [];

    const pnrdatas = xpath.select("equipGroup/equip", xmlNode);

    pnrdatas.forEach((pnrdata: Node) => {
      const changed = xpath.select("string(.//@changeMark)", pnrdata).length > 0;
      let pnr: string = Util.cleanXml(
        xpath.select("string(identNumber/partAndSerialNumber/partNumber)", pnrdata)
      );
      if (!pnr) {
        pnr = Util.cleanXml(xpath.select("string(partRef/@partNumberValue)", pnrdata));
      }
      const ecpn = Util.cleanXml(xpath.select("string(partRef/@partNumberValue)", pnrdata));

      const href = Util.cleanXml(
        xpath.select(
          "string(identNumber/partAndSerialNumber/partNumber/@*[name()='xlink:href'])",
          pnrdata
        )
      );
      const title = Util.cleanXml(
        xpath.select(
          "string(identNumber/partAndSerialNumber/partNumber/@*[name()='xlink:title'])",
          pnrdata
        )
      );

      data.push(
        (pnr ? pnr : "") +
          OBJECT_SEPARATOR +
          (ecpn ? ecpn : "-") +
          OBJECT_SEPARATOR +
          href +
          OBJECT_SEPARATOR +
          title +
          OBJECT_SEPARATOR +
          changed
      );
    });

    return data.join(ARRAY_SEPARATOR);
  }

  public static unserialize(data: string): TaskNamespace.MpnPn[] {
    return data
      ?.split(ARRAY_SEPARATOR)
      .map((pnrdata: string) => pnrdata.split(OBJECT_SEPARATOR))
      .map((val: string[]) => ({
        mpn: val[0],
        pn: val[1],
        linkDm: val[2],
        linkTitle: val[3],
        changed: val[4] === "true"
      }));
  }
}
