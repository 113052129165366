// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-dialog-content {
  margin: 24px;
  padding: 0;
  text-align: center;
}
.mat-mdc-dialog-content h1 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
}

.bottom-buttons {
  margin: 20px 0 0;
  padding: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbmZpcm0tZGlhbG9nLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0UsWUFBQTtFQUNBLFVBQUE7RUFDQSxrQkFBQTtBQURGO0FBR0U7RUFDRSxlQUFBO0VBQ0EsaUJBQUE7RUFDQSxtQkFBQTtBQURKOztBQUtBO0VBQ0UsZ0JBQUE7RUFDQSxVQUFBO0FBRkYiLCJmaWxlIjoiY29uZmlybS1kaWFsb2cuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IFwibGlicy90aGVtZS9vcmlvbi10aGVtZVwiO1xuXG4ubWF0LW1kYy1kaWFsb2ctY29udGVudCB7XG4gIG1hcmdpbjogMjRweDtcbiAgcGFkZGluZzogMDtcbiAgdGV4dC1hbGlnbjogY2VudGVyO1xuXG4gIGgxIHtcbiAgICBmb250LXNpemU6IDI2cHg7XG4gICAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gICAgbWFyZ2luLWJvdHRvbTogMjBweDtcbiAgfVxufVxuXG4uYm90dG9tLWJ1dHRvbnMge1xuICBtYXJnaW46IDIwcHggMCAwO1xuICBwYWRkaW5nOiAwO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/shared-module/confirm-dialog/confirm-dialog.component.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,UAAA;EACA,kBAAA;AADF;AAGE;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AADJ;;AAKA;EACE,gBAAA;EACA,UAAA;AAFF;AACA,ovBAAovB","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n.mat-mdc-dialog-content {\n  margin: 24px;\n  padding: 0;\n  text-align: center;\n\n  h1 {\n    font-size: 26px;\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n}\n\n.bottom-buttons {\n  margin: 20px 0 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
