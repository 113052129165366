export interface OnClickInfo {
  functionName: string;
  parameters: string[];
}

/**
 * surond all table by o-table class
 *
 * @param html
 */
export function handleTableScroll(html) {
  const tables = html.querySelectorAll("table");
  for (const table of tables) {
    table.outerHTML = `<div class="o-table">${table.outerHTML}</div>`;
  }
}

export function createEndOfDocument() {
  const end = document.createElement("p");
  end.classList.add("end-document");
  end.innerText = "© Copyright - Airbus";
  return end;
}

// numeratation for legacy
export function addLevelForHierarchy(html) {
  const titles = html.querySelectorAll("p.margin_T15");
  for (const title of titles as unknown as HTMLElement[]) {
    title.removeChild(title.firstChild);
    title.parentElement.classList.add("level");
  }
}

/**
 * displayGraphicElement link handler
 *
 * @param domElement
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function infoFromLink(domElement: any) {
  const returnElement = {
    functionName: null,
    hotspotId: null,
    imgId: null
  };
  const linkClickInfo: OnClickInfo = getOnClickInfo(domElement);
  if (linkClickInfo) {
    returnElement.functionName = linkClickInfo.functionName;
    returnElement.imgId = linkClickInfo.parameters[0];
    returnElement.hotspotId = linkClickInfo.parameters[1];
  }
  return returnElement;
}
/*****************************************************************************************************************
explanation
/ [\"\'],[\"\']|\([\"\']|[\"\']\) / gm

1st Alternative [\"\'],[\"\']
Match a single character present in the list below [\"\']
\" matches the character " literally (case sensitive)
\' matches the character ' literally (case sensitive)
, matches the character , literally (case sensitive)
Match a single character present in the list below [\"\']
\" matches the character " literally (case sensitive)
\' matches the character ' literally (case sensitive)

2nd Alternative \([\"\']
\( matches the character ( literally (case sensitive)
Match a single character present in the list below [\"\']
\" matches the character " literally (case sensitive)
\' matches the character ' literally (case sensitive)

3rd Alternative [\"\']\)
Match a single character present in the list below [\"\']
\" matches the character " literally (case sensitive)
\' matches the character ' literally (case sensitive)
\) matches the character ) literally (case sensitive)

Global pattern flags
g modifier: global. All matches (don't return after first match)
m modifier: multi line. Causes ^ and $ to match the begin/end of each line (not only begin/end of string)
**************************************************************************************************************/
// eslint-disable-next-line max-len
// exemple showPreview('DMC-AS332-L2-0000-13-40-40-01000-000F-T_en-EN##13-40-40-010-240##00N',"ICO 13-40-40-01-240 - ASSEMBLY,GENERATORS","IPC")
// eslint-disable-next-line max-len
// exemple gotoRefExt("DMC-EC175-B-0000-05-30-00-00001-003T-M_en-EN","Safety And General Maintenance Instructions - Maintenance Checks ",false)
// exemple gotoExternal("MM", "DMC-SHE060-XX-79-34-00-01A01-340A-A")
export function getOnclickAttribute(element: Element): string[] | null {
  const onclick = element.getAttribute("onclick");
  return onclick ? onclick.split(/['"],['"]|\(['"]|['"]\)|['"],|\)$/) : null;
}

/**
 * Transform node list to array
 *
 * @param queryResult
 */
export function querySelectorAllToList(queryResult) {
  return [].slice.call(queryResult);
}

/**
 * Parse a onclick string and return the function name with param array
 * do not handle multiple onclick element attribute
 * Remove quote of string parameter
 *
 * @param element
 */
export function getOnClickInfo(element): OnClickInfo {
  const onclick = element.getAttribute("onclick");
  if (onclick) {
    const functionToParse = removeQuote(onclick.split(";")[0]);
    const startOfParams = functionToParse.indexOf("(");
    const endOfParams = functionToParse.indexOf(")");

    const noParams =
      endOfParams === startOfParams + 1 || endOfParams === -1 || startOfParams === -1;

    const params = noParams
      ? []
      : cleanParameters(functionToParse.substring(startOfParams + 1, endOfParams).split(","));

    return {
      functionName: functionToParse.substring(0, startOfParams),
      parameters: params
    } as OnClickInfo;
  }

  return undefined;
}

function cleanParameters(parameters: string[]) {
  return parameters.map(removeQuote);
}

function removeQuote(element: string) {
  return element.replace(/['"]+/g, "");
}
