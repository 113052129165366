import { ViewerMessageService } from "@viewer/core/message/viewer.message.service";
import { Injectable, NgZone } from "@angular/core";
import { LoggerService } from "@viewer/core/logger/logger.service";
import { PouchService } from "@viewer/core/pouchdb/pouch.service";
import { WebWorkerService } from "@viewer/core/web-worker/web-worker.service";
import { Store } from "@viewer/core/state/store";
import { makeObservable, runInAction, when } from "mobx";
import { action } from "mobx-angular";
import { ESearchState } from "@viewer/core/search/searchState";
import { ApplicabilityService } from "@viewer/core/applicability/applicability.service";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest, Subject, take } from "rxjs";
import { FacetService } from "@viewer/core/search/facet.service";
import { toStream } from "mobx-utils";
import { SearchResultsDataSource } from "@viewer/search-result-module/search-result/search-table-data";
import { InspectionService } from "@viewer/core/toc-items/inspection.service";
import { SearchResult, SearchResponse } from "@viewer/core/search/searchModel";
import { SearchProvider } from "@viewer/core/search/searchProvider";
import { SupersededService } from "@viewer/core/superseded/superseded.service";
import { CsvService } from "@viewer/core/csv/csv.service";
import { handleS1000DSearchByDmc, isS1000DSearchByDmc } from "@viewer/shared-module/helper.utils";
import { SafeDatePipe } from "libs/pipe/safe-date.pipe";
import { PreprintService } from "@viewer/core/toc-items/preprint.service";
import { protobufToJs } from "@viewer/core/pouchdb/core/protobufToJs";
import { Preprint } from "@orion2/models/tocitem.models";

@Injectable()
export class SearchService {
  public searchProvider: SearchProvider;
  public dataSource: SearchResultsDataSource = new SearchResultsDataSource();
  public isLoadingUniverseDoc = false;
  public isLoadingUniverseTask = false;
  public isLoadingUniversePart = false;
  // Here we need a BehaviorSubject because we might send a new value before the
  // search-result.component is ready to subscribe to it
  // hence we might miss it.
  public searchResults: BehaviorSubject<SearchResponse> = new BehaviorSubject(undefined);

  public selectedSearchResults = new Set<SearchResult>();
  // this should stay false until searchResultComponent is ready
  // see facet-search.component
  public updateFilteredResults: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public lastSearchPage = 0;

  constructor(
    private applicabilityService: ApplicabilityService,
    private pouchService: PouchService,
    private logger: LoggerService,
    private messageService: ViewerMessageService,
    private workerService: WebWorkerService,
    private translate: TranslateService,
    public store: Store,
    public facetService: FacetService,
    private inspectionService: InspectionService,
    private supersedService: SupersededService,
    private ngZone: NgZone,
    private csvService: CsvService,
    private datePipe: SafeDatePipe,
    private preprintService: PreprintService
  ) {
    // Add search conf params in the conf file "viewer.conf.***.json"
    // that is loaded in confService.confObject.search
    // Search provider use phonetizer param

    this.searchProvider = new SearchProvider(
      this.applicabilityService,
      this.store,
      this,
      this.workerService,
      this.inspectionService,
      this.supersedService,
      this.ngZone,
      this.preprintService
    );

    // The force typing is needed as we have a getter that can send either boolean or Subject<boolean>.
    // This is as it is because both getter and setter must have the same type.
    (this.inspectionService.activateUpdate as Subject<boolean>).subscribe(() => {
      this.updateSearch();
      this.resetSelectedSearchResults();
    });

    const obsShowNotApplicable = toStream(() => this.store.showNotApplicable);
    const obsApplicableMd5 = toStream(() => this.store.applicableMD5);
    const obsSearchInput = toStream(() => this.store.searchInput);

    combineLatest([obsSearchInput, obsApplicableMd5, obsShowNotApplicable]).subscribe(() => {
      this.searchWhenReady(this.store.searchInput);
    });
    makeObservable<SearchService, "setOfflineSearchReady" | "setOfflineSearchLoading">(this, {
      setOfflineSearchReady: action,
      setOfflineSearchLoading: action
    });
  }

  public get supersededCount(): number {
    return this.supersedService.isSupersededSearch(this.store.searchInput)
      ? this.dataSource.supersededCount
      : 0;
  }

  // This function is called on inspection CRUD
  // This is why we have to loadInspectionsAndTasks and then the search to refresh the cards and facets
  public updateSearch(): Promise<void> {
    return this.ngZone.runOutsideAngular(() =>
      this.searchProvider
        .loadInspectionsAndTasks()
        .then(() => this.updateMetaData())
        .then(() => this.searchWhenReady(this.store.searchInput))
    );
  }

  public resetSelectedSearchResults(): void {
    if (this.selectedSearchResults) {
      this.selectedSearchResults.forEach((val, key) => {
        // This selectedSearchResults will contain only selected item in order to get quickly the number of selected item
        this.selectedSearchResults.delete(key);
      });
    }
  }

  /**
   * Will reload the search index,
   * do nothing if search load in progress
   *
   * @memberof SearchService
   */
  public reloadIndex(): Promise<void | boolean> {
    const isLoading = this.store.offlineLoadingState === ESearchState.LOADING;
    if (!isLoading) {
      this.setOfflineSearchLoading();
      return this.loadIndex();
    }
    // tell if index already load or loading
    return Promise.resolve(false);
  }

  /**
   * Launch the previous search in case of direct url access
   *
   * @private
   * @param {any} isReady
   * @memberof SearchResultComponent
   */

  public searchWhenReady(searchInput: string): void {
    if (!this.store.offlineSearchReady) {
      when(
        () => this.store.offlineSearchReady,
        () => this.search(searchInput)
      );
      if (this.store.searchInput !== undefined) {
        setTimeout(() => {
          const warning = this.translate.instant("search.msgInit");
          this.messageService.warning(warning);
        });
      }
    } else {
      this.search(searchInput);
    }
  }

  public updateMetaData(): Promise<void> {
    return Promise.resolve(this.dataSource.updateMetaData(this.store.referenceToResultMap));
  }

  /**
   *
   * @param input
   * @param background
   * @param searchBy
   * @returns
   * @memberof SearchService
   */
  public async search(
    input: string,
    background = false,
    searchBy?: keyof SearchResult
  ): Promise<SearchResponse> {
    // as we reset everything each time we leave the search-result page,
    // we don't want to do a search on undefined.
    // on empty routes like search/documents or search/task input is "".
    if (input === undefined) {
      return;
    }
    // SPEC If searchBy is defined we will search in referenceToResultMap to find all
    // It allow to find by SearchResult attribute
    if (searchBy) {
      // SPEC: If searching by DMC or ShortDMC, we remove tasks from map
      const searchMap = this.store.referenceToResultMap.filter(
        (e: SearchResult) => !e.task?.length
      );
      let searchResponse: SearchResult[];
      // SPEC : on dmc route, we can have a DM container (Z reference) (ex : H160-A-34-24-1004-00Z-520A-A)
      // in this case we want to find all variants of the DM container :
      // H160-A-34-24-1004-00A-520A-A
      // H160-A-34-24-1004-00B-520A-A
      // H160-A-34-24-1004-00C-520A-A..
      if (searchBy === "dmc" && isS1000DSearchByDmc(input)) {
        searchResponse = handleS1000DSearchByDmc(input, searchMap);
      } else {
        // SPEC: Be able to redirect (/shortDMC/:key) to DMC without specifying its manuel type
        searchResponse = searchMap.filter((e: SearchResult) =>
          e[searchBy]?.toString().match(`${input}$`)
        );
      }
      // SPEC: Allow to search incomplete dmc
      // We prioritize full equality before include
      // SPEC: For example shortDMC = WDM 34-51-37-002 (WD) and searchInput = WDM 34-51-37-002
      // in this case `${input}$` no match so we look `${input}`
      if (searchBy === "reference" && searchResponse.length === 0) {
        searchResponse = searchMap.filter((e: SearchResult) =>
          e[searchBy]?.toString().match(`${input}`)
        );
      }
      return Promise.resolve({
        search: searchResponse,
        facet: undefined,
        input
      });
    }
    if (background) {
      return this.searchProvider.search(input, background);
    }
    const startTime = this.setSearchStart();
    const searchResults: SearchResponse = await this.ngZone.runOutsideAngular(() =>
      this.searchProvider.search(input)
    );
    return this.facetService.initFacetValuesAndControls(searchResults.facet).then(() => {
      // mobx doest not support associative array
      runInAction(() => {
        this.store.searchTime = Math.round(performance.now() - startTime);
      });
      // set highlightKeyword here to have highlight on route : /first
      this.store.highlightKeyword = this.store.searchInput;
      this.searchResults.next(searchResults);
      return searchResults;
    });
  }

  /**
   *
   * @param documentIds
   */
  public exportDocumentCSV(documentIds: SearchResult[]): void {
    const filename = this.getExportFileName();

    const content = [];

    // Add column header
    content.push([
      "Document",
      "Title",
      "Language",
      "Issue Date",
      "Model",
      "Version",
      "Serial Number",
      "Status"
    ]);

    documentIds.forEach((metadata: SearchResult) => {
      const documentCsv = this.convertDocumentToCSV(metadata);

      if (documentCsv) {
        content.push(documentCsv);
      }
    });

    this.csvService.download(filename, content);
  }

  /**
   *  Replicate search data will getting them
   */

  public getBaseStructure() {
    return this.pouchService.searchCaller
      .getBaseStructure()
      .then(docsFromPouch => protobufToJs(docsFromPouch));
  }

  public getFieldForLetters(listKey: string[]) {
    return this.pouchService.searchCaller.getFieldForLetters(listKey).then(docFromPouch => {
      docFromPouch =
        docFromPouch ||
        listKey.map(key => ({
          _id: key,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _attachments: { "att.txt": { data: new Uint8Array(undefined) } }
        }));
      return protobufToJs(docFromPouch);
    });
  }

  private convertDocumentToCSV(docMetadata: SearchResult): string[] {
    return [
      docMetadata.reference,
      docMetadata.shortTitle,
      this.store.pubInfo.lang,
      docMetadata.date && this.datePipe.transform(docMetadata.date, "yyyy.MM.dd"),
      this.store.pubInfo.model,
      docMetadata.versions?.join(", "),
      docMetadata.serialNo?.join(", "),
      docMetadata.revision
    ];
  }

  private getExportFileName(): string {
    const exportDate = new Date();

    return [
      "documents",
      this.store.publicationID,
      this.store.user.userName,
      exportDate.getFullYear(),
      exportDate.getMonth() + 1,
      exportDate.getDay(),
      exportDate.getUTCDate(),
      this.store.pubInfo.verbatimText,
      this.store.pubInfo.revision,
      this.store.pubInfo.lang
    ].join("_");
  }

  private loadIndex(): Promise<void> {
    return this.ngZone
      .runOutsideAngular(() => this.searchProvider.loadIndex())
      .then(() => {
        // We overwrite data in search map for all preprints attached to a DMC
        this.preprintService.tocItemsOfType.pipe(take(1)).subscribe((preprints: Preprint[]) => {
          preprints
            .filter((preprint: Preprint) => !preprint.dmc.startsWith("preprint"))
            .forEach((preprint: Preprint) => {
              const index = this.store.resultToReferenceMap.get(preprint.dmc);
              if (index >= 0) {
                const meta = this.store.referenceToResultMap[index];
                meta.attachedPreprint = true;
                meta.shortTitle = preprint.title;
                meta.reference = preprint.reference;
              }
            });
          this.updateMetaData();
          const msgSuccess = this.translate.instant("search.msgSuccess");
          this.messageService.success(msgSuccess);
          this.setOfflineSearchReady();
        });
      })
      .catch(e => {
        console.error(e);
      });
  }

  private setOfflineSearchReady(): void {
    this.store.offlineLoadingState = ESearchState.READY;
  }

  private setOfflineSearchLoading(): void {
    this.store.offlineLoadingState = ESearchState.LOADING;
  }

  private setSearchStart(): number {
    return performance.now();
  }
}
