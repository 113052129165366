import { PubDoc } from "libs/models/couch.models";

export const user_manual: PubDoc = {
  _id: "user_manual__11.000",
  packageId: "user_manual",
  verbatimText: "How to use O.R.I.O.N.",
  uidRev: "011.00",
  revision: "011.00",
  revisionDate: "2024-09-03",
  jacket: "assets/img/user_manual.jpg",
  lang: "en",
  status: "offline",
  isPublished: true,
  occurrenceCode: "",
  versionImporter: "",
  buildInfo: "",
  partS1000D: false,
  taskS1000D: false,
  import_date: "",
  isPackagePDF: false,
  dbs: {},
  sizes: undefined,
  capabilities: undefined,
  minRelease: "",
  pubSchema: undefined,
  reasonForUpdate: [],
  subtitle: undefined,
  manualCode: "ORIO",
  isConsultable: true,
  priority: 0,
  infos: "How to use O.R.I.O.N."
};
