import { Injectable, NgZone } from "@angular/core";
import { Store } from "@viewer/core/state/store";
import { PouchService } from "@viewer/core/pouchdb/pouch.service";
import { getNumericValue, hasOneSameValue } from "@viewer/shared-module/helper.utils";
import { runInAction, reaction } from "mobx";
import { PouchAllDocsResult, TocInfo } from "@orion2/models/couch.models";
import { TocItem } from "@orion2/models/tocitem.models";
import { ApplicabilityFleet, SerialNumber } from "@orion2/models/applicability.models";
import { isEqual } from "lodash";
import { IpcFormatter } from "@orion2/ipc-formatter/formatter";
import { splitRange } from "@orion2/utils/functions.utils";

export interface UserCriterias {
  filterOn?: boolean;
  model: string;
  version: string;
  serialno: string;
  showNotApplicable?: boolean;
}

export enum CriteriaNames {
  MODEL = "model",
  VERSION = "version",
  SERIALNO = "serialno"
}

export type CriteriaStringValue = "model" | "version" | "serialno";

export interface CriteriaSubapplic {
  model?: {
    md5: string;
  };
  version?: {
    md5: string;
  };
  serialno?: {
    md5: string;
    from: string;
    to: string;
  };
}

export interface MatchingCriteria {
  model: string[];
  version: string[];
  serialno: string[];
}

export interface CriteriaItem {
  name: string;
  type: string;
  from?: string;
  to?: string;
  value?: string;
}

@Injectable()
export class ApplicabilityService {
  fleet: ApplicabilityFleet;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fleetJson: any;
  userSelectedCriterias: UserCriterias;

  applicableMD5Prom: Promise<PouchAllDocsResult> = undefined;
  applicableMD5: string[] = [];

  currentMatchingSubApplic: string[];

  private fleetData = {};
  private criteriaData: { [md5: string]: CriteriaItem } = {};
  private subApplicData = {};

  constructor(private pouchService: PouchService, private store: Store, private ngZone: NgZone) {
    reaction(
      () => ({
        pubId: this.store.publicationID,
        pouchReady: this.store.pouchReady
      }),

      async state => {
        if (state.pubId && state.pouchReady) {
          this.applicableMD5 = [];
          this.applicableMD5Prom = this.ngZone.runOutsideAngular(() =>
            this.pouchService.applicCaller.getAll()
          );
          const isFilteringAvailable = await this.isFilteringAvailable();
          await this.ngZone.runOutsideAngular(async () => this.filter(await this.getCriterias()));
          runInAction(() => {
            // This will launch the modal if we come from pubs
            // However, we should ensure that userCriterias is set first
            // Hence we run runInAction after filter
            this.store.isFilteringAvailable = isFilteringAvailable;
          });
          return true;
        }
        this.currentMatchingSubApplic = undefined;
        return false;
      },
      { fireImmediately: true }
    );
  }

  /**
   * To get all the version, model and serial of a publication,
   * We have to read the fleet which contain this kind of objects :
   * {model: string, version: string, serialno: string}
   */
  public initFleet(): ApplicabilityFleet {
    const fleet: ApplicabilityFleet = {
      model: [],
      version: [],
      serialno: []
    };

    if (this.store.isFilteringAvailable) {
      for (const item of this.fleetJson) {
        ["model", "version"].forEach(criteriaName => {
          if (fleet[criteriaName].indexOf(item[criteriaName]) === -1) {
            fleet[criteriaName].push(item[criteriaName]);
          }
        });

        const serial = item.serialno;
        if (fleet["serialno"].indexOf(item["serialno"]) === -1) {
          fleet["serialno"].push({
            label: this.paddingLeft(serial, 4),
            realValue: serial,
            version: item["version"]
          });
        }
      }
      fleet.model.sort((model1: string, model2: string) => model1.localeCompare(model2));
      fleet.version.sort((version1: string, version2: string) => version1.localeCompare(version2));
      fleet.serialno.sort((a, b) => (a.realValue > b.realValue ? 1 : -1));
    }
    this.fleet = fleet;
    return fleet;
  }

  /**
   * Add a padding to a number by adding trailing zero in the front.
   *
   * @param num The number that need padding.
   * @param size The size of the returned string.
   * @returns
   */
  paddingLeft(num: number, size: number): string {
    const s = "0000000000" + num;
    return s.slice(-size);
  }

  /**
   * Checks if the 3 mandatory files are available in the current publication
   * to determine if Applicability filtering is available.
   * Those mandatory files are:
   * - "fleet.json",
   * - "criterias.json",
   * - "subapplicabilities.json"
   *
   * During the process, this.fleetJSON is set with the content of fleet.json.
   *
   * @returns the availability of the filtering.
   * @memberof ApplicabilityService
   */
  async isFilteringAvailable(): Promise<boolean> {
    const mandatoryFilesKey = ["fleet.json", "criterias.json", "subapplicabilities.json"];

    if (!this.store.showNotApplicable) {
      // We need a reaction on showApplicable to avoid running the search more than once.
      // See searchService (combineLatest).
      runInAction(() => {
        this.store.showNotApplicable = this.userSelectedCriterias?.showNotApplicable || true;
      });
    }

    if (this.store.pubInfo.isPackagePDF) {
      return Promise.resolve(false);
    }
    const mandatoryFiles = await this.applicableMD5Prom.then((applics: PouchAllDocsResult) =>
      applics.rows.filter(applic => mandatoryFilesKey.some(key => key === applic._id))
    );
    const isAvailable = mandatoryFiles.filter(row => !!row).length === mandatoryFilesKey.length;

    if (isAvailable) {
      const fleetFile = mandatoryFiles.find(doc => doc._id === "fleet.json");
      this.fleetData = fleetFile?.data;
      this.criteriaData = mandatoryFiles.find(doc => doc._id === "criterias.json")?.data;
      this.subApplicData = mandatoryFiles.find(doc => doc._id === "subapplicabilities.json")?.data;
      this.fleetJson = this.fleetData || [];
    }
    return isAvailable;
  }

  /**
   * Get last user criterias saved in localdatabase
   *
   * @returns
   */
  async getCriterias(): Promise<UserCriterias> {
    const defaultCriterias: UserCriterias = {
      filterOn: true,
      model: "",
      version: "",
      serialno: "",
      showNotApplicable: true
    };
    //If we cant find data or there is an error, it return undefined
    const savedCriterias = await this.pouchService.userCaller.getApplicCriterias(
      this.store.publicationID
    );
    return Promise.resolve(savedCriterias || defaultCriterias);
  }

  /**
   * The purpose of this function is to find all the applicability md5
   * which match the criterias selected by the user
   *
   * @param criteriasUser
   */
  public filter(criteriasUser: UserCriterias): void {
    //If new criterias are same than actuals, we skip the function
    if (isEqual(criteriasUser, this.userSelectedCriterias)) {
      return;
    }
    this.userSelectedCriterias = criteriasUser;
    this.handleFilterOff(criteriasUser);
    const matchingCriterias = this.getMatchingCriterias();
    const matchingSubApplic = this.getMatchingSubapplicabilities(matchingCriterias);

    if (
      this.store.applicableMD5.length < 1 ||
      !this.currentMatchingSubApplic ||
      this.currentMatchingSubApplic.length !== matchingSubApplic.length ||
      this.currentMatchingSubApplic.findIndex(
        (value, index) => value !== matchingSubApplic[index]
      ) > -1
    ) {
      this.currentMatchingSubApplic = matchingSubApplic;
      this.populateApplicMD5(matchingSubApplic);

      // // We don't need to wait the end of this promise beceause there is reaction in store.applicableMD5
      this.userSelectedCriterias = Object.assign({}, criteriasUser);
    }
    this.saveCriterias();
  }

  // We don't to reset the filterOn value that's why you pass the value in the parameter
  public reset(filterOn: boolean): void {
    runInAction(() => {
      this.store.applicableMD5 = [];
    });
    this.userSelectedCriterias = {
      filterOn,
      model: "",
      version: "",
      serialno: "",
      showNotApplicable: true
    };
    this.saveCriterias();
  }

  /**
   * Get list of serial no from specific version
   *
   * @param version
   * @returns
   * @memberof ApplicabilityService
   */
  getAllSNFromVersion(version): SerialNumber[] {
    return this.fleet.serialno.filter(item => item.version === version);
  }

  /**
   * In the db we store objects of this form
   * subapplicmd5: [applicmd5];
   * So we just want to retrieve all the docs which have the subapplic md5 as id
   *
   * @param matchingSubApplics
   * @returns
   */
  async populateApplicMD5(matchingSubApplics: string[]): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.applicableMD5Prom.then((applics: any) => {
      this.applicableMD5 = applics.rows
        .filter(applic => matchingSubApplics.some(sub => sub === applic._id))
        .reduce((acc, applic) => acc.concat(applic.data), []);
      runInAction(() => {
        this.store.applicableMD5 = this.applicableMD5;
      });
    });

    // We do not want replication in the applicMD5 array because it is in the RAM
  }

  isApplicablePart(partResult): boolean {
    const filterSN = this.userSelectedCriterias?.serialno;
    if (!filterSN || (!partResult.part?.length && !partResult.applicability)) {
      return true;
    }
    let partData;
    if (partResult.applicability) {
      partData = { applic: partResult.applicability.replaceAll(", ", ",") };
    } else {
      partData = IpcFormatter.unserialize(partResult.part);
    }
    if (!partData.applic) {
      return true;
    }
    const applic = [];
    const applicData = partData.applic.split(",");
    for (const serialNo of applicData) {
      applic.push(...splitRange(serialNo));
    }
    return applic.includes(filterSN);
  }

  /**
   * Check if the applicMD5 of a toc node
   * is in the array of applicable md5
   *
   * @param tocNode
   */
  isApplicable(tocNode): boolean {
    // Be carefull, indexOf doesn't work with S1000D applic inline

    // SPEC 1/ no filter available => everything is applicable
    // so return `!this.store.isFilteringAvailable`

    // SPEC 2/ If in my array there is no element i don't need to search if it contains the one I have,
    // so i'll just return "true" because it simply won't be there.

    // SPEC 3/ If toc node is undefined the DM will be applicable. This use case will happend when the function
    // setCurrentTocNode is not yet called inside the content provider

    // SPEC 4/ the node applicabilityMD5 is undefined is applicable (ex.
    // as with preprint, sb, documents which parent is a folder, or if
    // node is the "root", or LOAP ...) => returns true

    // SPEC 5/ a node is applicable is its applicabilityMD5 is present
    // in the array of applicableMD5 (see the construction of
    // this.store.applicableMD5)
    return (
      !this.store.isFilteringAvailable ||
      this.store.applicableMD5.length === 0 ||
      !tocNode?.applicabilityMD5 ||
      this.store.applicableMD5.indexOf(tocNode.applicabilityMD5) > -1
    );
  }

  /**
   * Test applicability inline
   *
   * @param node part element generated by ipc service
   */
  isApplicableInline(node) {
    // if applicability is not defined then the part is applicable ?
    if (!node.applicabilityMD5 || this.store.applicableMD5.length === 0) {
      return this.isApplicablePart(node);
    }
    return this.store.applicableMD5.includes(node.applicabilityMD5);
  }

  /**
   * We need two different implementation for du and folder
   * Because for for folders, we have to iterate over all their descendance
   * and if one leaf is applicable, then the folder is applicable
   *
   * @param tocNode
   * @returns if the folder is applicable.
   */
  public async isFolderApplicable(tocNode: TocInfo | TocItem): Promise<boolean> {
    // LOAP is always applicable. It doesn't have applicabilityMD5 property
    // and fail in this.pouchService.tocCaller.children as it's a node and not a folder.
    // Solution return true.
    if (tocNode._id === "loap") {
      return true;
    }

    // Custom folders are always applicable
    if (this._isCustomFolder(tocNode)) {
      return true;
    }

    // Some folders have an applicability_md5 attribute si if this is the case, it's the same
    // principe than DU
    if (!this.store.isFilteringAvailable || this.store.applicableMD5.length === 0) {
      return true;
    }
    if ((tocNode as TocInfo).applicabilityMD5) {
      return this.isApplicable(tocNode);
    }

    // SPEC: In order to get a folder applicability
    // we need to get all children applicabilty to know if it's applicable
    if (tocNode._id.startsWith("f__")) {
      const children = await this.ngZone.runOutsideAngular(() =>
        this.pouchService.tocCaller.children(tocNode._id)
      );

      for (const child of children) {
        if (await this.isFolderApplicable(child)) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Check if the current tocNode is a custom folder.
   * // TODO: ObjectUnsubscribedError: object unsubscribed error
   *
   * @param tocNode the tocNode to check.
   * @returns if the current tocNode is a custom folder.
   */
  private _isCustomFolder(tocNode: TocInfo | TocItem): boolean {
    return (
      "type" in tocNode &&
      (tocNode.type === "folder" ||
        ("parents" in tocNode && (tocNode.parents[0] as string)?.startsWith("f__")))
    );
  }

  /**
   * Save current user criterias in local database
   */
  private saveCriterias() {
    this.pouchService.userCaller.saveApplicCriterias(
      this.store.publicationID,
      this.userSelectedCriterias
    );
  }

  /**
   * Reset criterias when filter is off
   *
   * @param criterias
   */
  private handleFilterOff(criterias: UserCriterias) {
    if (!criterias.filterOn) {
      this.userSelectedCriterias.model = "";
      this.userSelectedCriterias.version = "";
      this.userSelectedCriterias.serialno = "";
      this.userSelectedCriterias.showNotApplicable = true;
    }
  }

  /**
   * First step to retrieve the applicability md5
   * Get all the criterias and check if they check the criterias chosen by the user
   * So we get an array of criterias md5
   */
  private getMatchingCriterias(): MatchingCriteria {
    const filteredCriterias = {
      model: [],
      version: [],
      serialno: []
    } as MatchingCriteria;

    // To make greater than or lesser than operation we have to convert the string serialno into number
    const serialNumericValue = getNumericValue(this.userSelectedCriterias.serialno);
    // Iterate over all criterias available in the publication
    Object.keys(this.criteriaData).forEach((key: string) => {
      const criteriaItem = this.criteriaData[key];
      const userSelectionForCriteria = this.userSelectedCriterias[criteriaItem.name];
      if (userSelectionForCriteria) {
        // For all kind of criterias, check if it matches the user selection
        switch (criteriaItem.name) {
          case CriteriaNames.MODEL: {
            if (userSelectionForCriteria === criteriaItem.value) {
              filteredCriterias.model.push(key);
            }
            break;
          }
          case CriteriaNames.VERSION: {
            if (this.isMatchingVersion(userSelectionForCriteria, criteriaItem)) {
              filteredCriterias.version.push(key);
            }
            break;
          }
          case CriteriaNames.SERIALNO: {
            if (this.isMatchingSerial(criteriaItem, serialNumericValue)) {
              filteredCriterias.serialno.push(key);
            }
            break;
          }
          default: {
            throw new Error("Unknown criteria name : " + criteriaItem.name);
          }
        }
      }
    });

    return filteredCriterias;
  }

  /**
   * Second step of the filter function
   * We get all the subapplic md5 from the criterias md5 calculated in previous function
   *
   * @param matchingCriterias
   */
  private getMatchingSubapplicabilities(matchingCriterias: MatchingCriteria): string[] {
    const filteredSubapplicabilities: string[] = [];
    // Select the criterias the subapplics has to respect
    const criteriaMatchingRequirementdMap = {
      serialno:
        matchingCriterias.serialno.length !== 0 ||
        // If the user selects a SN, we have to take care of the SN even if there is no criteria about this SN
        !!this.userSelectedCriterias.serialno,
      version: matchingCriterias.version.length !== 0,
      model: matchingCriterias.model.length !== 0
    };

    /*
      For all subApplicability we check the criteria matches with user criteria (data are in fleet.json)
      If yes we add the md5 in filteredSubapplicabilities who will be used in populateApplicMD5() in order to get
      the good applic md5
    */
    Object.keys(this.subApplicData).forEach((subapplicMD5: string) => {
      const criteriaSubapplicObject = this.createSubapplicCriteriaObject(
        this.subApplicData[subapplicMD5].criterias
      );

      // Subapplic does not contain all criteria types
      // criteriasToSearch = which criterias do we have to check
      const criteriasToSearch = this.getCriteriaToSearch(
        criteriaSubapplicObject,
        criteriaMatchingRequirementdMap
      );

      // For all criteria (i.e : model, version) we check if criteria is valid
      // We should to get all the criterias valid that's why we add the valid criteria in array
      // If all criteria are valid we save the subapplic
      const matchingCriteriasArray = [];
      criteriasToSearch.forEach((criteriaKey: CriteriaStringValue) => {
        if (this.isMatchingCriteria(criteriaKey, criteriaSubapplicObject, matchingCriterias)) {
          matchingCriteriasArray.push(criteriaKey);
        }
      });

      if (matchingCriteriasArray.length === criteriasToSearch.length) {
        filteredSubapplicabilities.push(subapplicMD5);
      }
    });

    return filteredSubapplicabilities;
  }

  /**
   * Check if one of the md5 criterias presents in the subapplicObject
   * is present in the array of criterias md5 which match the user selection
   *
   * @param criteriaName
   * @param criteriaSubapplicObject
   * @param matchingCriterias
   */
  private isMatchingCriteria(
    criteriaName: CriteriaStringValue,
    criteriaSubapplicObject: CriteriaSubapplic,
    matchingCriterias: MatchingCriteria
  ): boolean {
    const criteriasMD5 = Object.keys(criteriaSubapplicObject).map(
      criteriaNameKey => criteriaSubapplicObject[criteriaNameKey].md5
    );
    return hasOneSameValue(criteriasMD5, matchingCriterias[criteriaName]);
  }

  /*{
    model? :
    sn? : {
      md5 :
      fromValue :
      ToValue
    }
    version? :
  }*/
  private createSubapplicCriteriaObject(subapplicCriterias): CriteriaSubapplic {
    const subapplicCriteriaObject = {};
    // Iteration over all the md5 contained in the criterias array of the subapplic entry
    // most of the time we have (model/sn) or (model/version)
    for (const criteriaMD5 of subapplicCriterias) {
      const criteriaElement = this.criteriaData[criteriaMD5];
      if (criteriaElement) {
        const criteriaName = criteriaElement.name;
        subapplicCriteriaObject[criteriaName] =
          criteriaName === "serialno"
            ? {
                md5: criteriaMD5,
                from: criteriaElement.from,
                to: criteriaElement.to
              }
            : { md5: criteriaMD5 };
      } else {
        console.warn("missing criteria", criteriaMD5);
      }
    }
    return subapplicCriteriaObject;
  }

  /**
   * Return a string array of all criterias name
   * Of a subapplic which match the criterias we have to check
   *
   * @param criteriaSubapplicObject
   * @param criteriaMatchingRequirementdMap
   */
  private getCriteriaToSearch(
    criteriaSubapplicObject: CriteriaSubapplic,
    criteriaMatchingRequirementdMap // Map of all criterias we have to check
  ): CriteriaStringValue[] {
    const isMatchingCriteriaMap = [];
    Object.keys(criteriaSubapplicObject).forEach(criteriaName => {
      if (criteriaMatchingRequirementdMap[criteriaName]) {
        isMatchingCriteriaMap.push(criteriaName.toLowerCase());
      }
    });

    return isMatchingCriteriaMap;
  }

  /**
   * Test if the version of the criteria matches the user selection
   *
   * @param userSelectionForCriteria
   * @param criteriaItem
   */
  private isMatchingVersion(userSelectionForCriteria, criteriaItem) {
    const versions = criteriaItem.value.split(",");
    return versions.indexOf(userSelectionForCriteria) > -1;
  }

  /**
   * Test if the sn of the criteria matches the user selection
   *
   * @param criteriaItem
   * @param serialNumericValue
   * @returns
   */
  private isMatchingSerial(criteriaItem: CriteriaItem, serialNumericValue: number): boolean {
    const fromNumericValue = getNumericValue(criteriaItem.from);
    const toNumericValue = getNumericValue(criteriaItem.to);
    const betweenSerial =
      serialNumericValue >= fromNumericValue && serialNumericValue <= toNumericValue;

    return (
      criteriaItem.from.toLowerCase() === "all" ||
      fromNumericValue === serialNumericValue ||
      betweenSerial
    );
  }
}
