import { Injectable, Injector } from "@angular/core";
import { ItemBasket } from "@orion2/models/tocitem.models";
import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { IpcService } from "@viewer/core/ipc/ipc.service";
import xpath from "xpath";
import { XmlDoc } from "@orion2/models/couch.models";
import { PartNamespace } from "@orion2/ipc-formatter/index";
import { CsvService } from "@viewer/core/csv/csv.service";
import { MatDialog } from "@angular/material/dialog";
import { EOrderingDialogComponent } from "@viewer/toc-items/shopping-basket/eordering-dialog/e-ordering-dialog.component";
import { join } from "lodash";
import { InformationDialogComponent } from "libs/components/information-dialog/information-dialog.component";

export interface DiscrepancyData {
  ipc: string;
  rev: string;
  model: string;
  chap: string;
  ata_desc: string;
  pos: string;
  desc: string;
  pnr: string;
  mfc: string;
  qty: number;
  shortDmc: string;
}

@Injectable()
export class ShoppingService extends TocItemService {
  public csvService: CsvService;
  protected tiType = "shopping";
  protected tiScope = "private";

  private ipcService: IpcService;
  private previousBasket: ItemBasket[];

  constructor(injector: Injector, private dialogRef: MatDialog) {
    super(injector);
    this.ipcService = injector.get(IpcService);
    this.csvService = injector.get(CsvService);
  }

  /**
   * Initialise an Item Basket from an IpcItem
   *
   * @param ipcItem
   */
  initItemBasket(ipcItem: ItemBasket) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const qty = isNaN(parseInt(ipcItem.qty as any, 10)) ? 1 : parseInt(ipcItem.qty as any, 10);
    if (ipcItem._id !== undefined) {
      return { ...ipcItem };
    } else {
      return {
        ...ipcItem,
        // As an id we set the type and dmc in first to be able easily to acces all the items of one given dmc directly using
        //  couchdb keys in the future. We also add the ipcItem.id (CSN) to add unicity between keys.
        _id:
          this.tiType +
          "__" +
          (ipcItem.dmc ?? this.store.currentDMC) +
          "__" +
          this.store.publicationRevision +
          "__" +
          ipcItem.mpn +
          "__" +
          ipcItem.mfc,
        title: ipcItem.title ?? this.store.currentTocNode.shortTitle,
        versions: ipcItem.versions?.join() ?? this.store.currentTocNode.versions.join(),
        dmc: ipcItem.dmc ?? this.store.currentDMC,
        mediaId: this.store.currentMediaId,
        reference: ipcItem.reference ?? this.titleService.headerSubtitle,
        qty,
        qtyInBasket: qty,
        // We store some info to be able later to reconstruct direct links to the target part
        packageId: this.store.pubInfo.packageId,
        revision: this.store.pubInfo.revision,
        minRevision: this.store.publicationRevision,
        type: this.tiType,
        parents: [this.tiType],
        allIsn: JSON.stringify(ipcItem.allIsn),
        addingDate: Date.now(), // Used to sort basket by date
        label: ipcItem.label?.trim() // SPEC: We need to trim because for some reasons there is a \n in the label data
      };
    }
  }

  /**
   * Add items in shopping basket
   * @param items - The items to add
   * @param onUndo - Callback function to call when undo the deletion
   */
  public addItemsToBasket(items: ItemBasket[], onUndo?: () => void): Promise<void> {
    const undoMsg: string = onUndo ? this.translate.instant("undo") : undefined;
    let message: string;
    const label: string =
      items.length === 1 ? items[0].label : this.translate.instant("shopping.selection");

    return this.getItemsOfType().then(async (inBasket: ItemBasket[]) => {
      // Contains the non-obtainable items
      const notProcurable: ItemBasket[] = items.filter((item: ItemBasket) => !item.isProcurable);

      // Contains items not in the basket
      const alreadyInBasket: ItemBasket[] = items.filter((item: ItemBasket) =>
        this.isInBasket(inBasket, item.csn, item.applicableVersion)
      );

      // Contain the obtainable items and that are not in basket
      const itemsToAdd: ItemBasket[] = items.filter(
        (item: ItemBasket) =>
          item.isProcurable && !this.isInBasket(inBasket, item.csn, item.applicableVersion)
      );

      // If not addable parts have been added to basket, display dialog
      if (notProcurable.length > 0) {
        // SPEC: wait for the dialog to be closed before showing snackbar
        await this.displayNotAddableDialog(notProcurable);
      }

      // There are selected products that are already in basket
      if (!itemsToAdd.length && notProcurable.length !== items.length) {
        message = this.translate.instant("shopping.selection.already.basket", {
          label
        });
        this.messageService.warning(message, "", 5000);
        return Promise.resolve();
      }

      if (!itemsToAdd.length) {
        return Promise.resolve();
      }

      // Used in undo function
      this.previousBasket = [...inBasket];

      // Added to shopping basket
      const promises: Promise<boolean>[] = itemsToAdd.map((item: ItemBasket) =>
        this.save(item, this.tiScope, true, true).then((res: boolean) => res)
      );
      return Promise.all(promises).then(() => {
        message = alreadyInBasket.length
          ? this.translate.instant("shopping.selection.part.already.basket")
          : this.translate.instant("shopping.addedMsg", { label });
        this.messageService.success(message, undoMsg, 5000);
        if (onUndo) {
          this.messageService.onAction.subscribe(onUndo);
        }
      });
    });
  }

  /**
   * Display the InformationDialog component to show the list of not addable items
   *
   * @param notAddable - Items that are not addable
   */
  private async displayNotAddableDialog(notAddableItems: ItemBasket[]): Promise<void> {
    const informations = notAddableItems.map(
      (item: ItemBasket) =>
        `${item.label} <span class="no-break">(${item.reference.replace("Fig. ", "")}</span> item ${
          item.hotspotId
        })`
    );
    const dialogRef = this.dialogRef.open(InformationDialogComponent, {
      panelClass: "information-dialog",
      data: {
        dialogTitle: "loap.info.title",
        sections: [{ title: "shopping.cannotBeOrdered", informations: informations }]
      }
    });

    return dialogRef.afterClosed().toPromise();
  }

  /**
   * Check whether an item is already in the basket
   *
   * @param inBasket
   * @param csn - the csn to search
   * @param applicableVersion - the applicableVersion to search
   */
  public isInBasket(inBasket: ItemBasket[], csn: string, applicableVersion: string): boolean {
    return inBasket?.some(
      (item: ItemBasket) => item.csn === csn && item.applicableVersion === applicableVersion
    );
  }

  /**
   * Create an standard undo function to pass to the addItemsToBasket 'onUndo' function using the given particular set of items
   *
   * @param itemsToRemove - The set of added items to remove
   */
  public undoAddFactory(itemsToRemove: ItemBasket[]): () => void {
    return () => {
      const inBasketId = this.previousBasket?.map(el => el._id);
      const removable = itemsToRemove.filter(item => !inBasketId?.includes(item._id));
      return Promise.all(
        removable.map(item => this.deleteWithUndo(item, undefined, undefined, false))
      )
        .then(() => {
          this.messageService.success(this.translate.instant("undo.ok"));
          Promise.resolve(true);
        })
        .catch(() => {
          const errMessage = this.translate.instant("undo.error");
          this.messageService.error(errMessage);
          return Promise.resolve(false);
        });
    };
  }

  /**
   * Get all the shopping basket items
   */
  public async getShoppingItems(): Promise<ItemBasket[]> {
    return this.getItemsOfType(this.tiType, this.tiScope) as unknown as ItemBasket[];
  }

  /**
   * Remove all items for the current item basket
   *
   * @param savedList - An array of deleted items to pass to undoDeleteFactory function
   */
  async deleteAll(savedList: ItemBasket[]): Promise<boolean> {
    const listCopy: ItemBasket[] = savedList.map(item => ({ ...item }));
    return this.getItemsOfType()
      .then((items: ItemBasket[]) =>
        Promise.all(items.map(item => this.delete(item))).then(deletions => {
          // If some deletion(s) went wrong (ex: [true, false, true, ...], we log an error to tell user that the deletion is partial
          if (!deletions.reduce((prev, next) => prev && next, true)) {
            const errMessage = this.translate.instant("shopping.errorPartial");
            this.messageService.error(errMessage);
            return false;
          }

          const deleteMsg = this.translate.instant("shopping.deleteAll");
          const undoMsg = this.translate.instant("undo");
          this.messageService.success(deleteMsg, undoMsg);

          // Leave the possibility to undo the deletion via a button in the snackbar
          this.messageService.onAction.subscribe(this.undoDeleteFactory(listCopy));
          return true;
        })
      )
      .catch(() => {
        const errMessage = this.translate.instant("shopping.errorDeleteAll");
        this.messageService.error(errMessage);
        return Promise.resolve(false);
      });
  }

  /**
   * Get all discrepancy portal objects from a list of ItemBasket
   *
   * @param shoppings
   * @returns
   * @memberof ShoppingService
   */
  public getDiscrepancyPortalData(shoppings: ItemBasket[]): Promise<DiscrepancyData[]> {
    const xml_ids = shoppings.map(s => s.dmc);
    return this.pouchService.xmlCaller.get(xml_ids).then((docs: XmlDoc[]) =>
      shoppings.map(shopping => {
        const xmlDoc = docs.find(doc => doc._id === shopping.dmc);
        const xml = new DOMParser().parseFromString(xmlDoc.data, "text/xml");
        return this.createDiscrepancyPortalObj(xml, shopping);
      })
    );
  }

  public partToItemBasket(partData: PartNamespace.IpcPart, linkData: any): ItemBasket {
    const s1000dHotspotIdEnd = partData.isn[2] === "0" ? "" : partData.isn[2];
    const itemBasket: ItemBasket = {
      id: partData.csn,
      title: linkData.shortTitle,
      reference: linkData.reference,
      versions: partData.versions,
      mpn: partData.mpn,
      nsn: partData.nsn,
      mfc: partData.mfc,
      isProcurable: partData.isProcurable,
      dmc: partData.parent,
      label: partData.label,
      qty: partData.qty,
      csn: partData.csn,
      isnId: partData.isn,
      applicableVersion: linkData.versions.join(),
      hotspotId: this.store.pubInfo.partS1000D
        ? partData.csn.split("-").pop() + s1000dHotspotIdEnd
        : partData.csn.split("-").pop(),
      sn: this.store.pubInfo.partS1000D ? linkData.serialNo : partData.applic
    } as unknown as ItemBasket;
    return this.initItemBasket(itemBasket);
  }

  /**
   * Define Shopping Basket Excel
   *
   * @memberof ShoppingListComponent
   */
  public exportToExcel(list: ItemBasket[] = []): void {
    const rowList = [];
    const creationDate = new Date();
    rowList.push([this.setFormatDate(creationDate), "", "", "", "", "", "", ""]);
    rowList.push([
      "IPC Ref",
      "Item number",
      "Manufacturer code",
      "Part number",
      "Designation",
      "NATO Stock Number (NSN)",
      "Quantity",
      "Procurability",
      "Aircraft",
      "Applicable Version",
      "S/N"
    ]);
    list.forEach((item: ItemBasket) => {
      const aircraft = item.dmc.split("-");
      rowList.push([
        item.reference,
        item.hotspotId,
        item.mfc,
        item.mpn,
        // SPEC: We need to trim for retrocompatibility as we used to store label with a \n
        item.label?.trim(),
        // SPEC: For retrocompatibility this attribute was called NSN with uppercase
        item.nsn || item.NSN,
        item.qtyInBasket,
        item.isProcurable ? "P" : "NP",
        aircraft[1],
        item.applicableVersion,
        item.sn ? item.sn : item.applicability
      ]);
    });
    const filename = this.setFormatFileName(creationDate);
    this.csvService.download(filename, rowList);
  }

  /**
   * Define Shopping Basket E-Ordering
   *
   * @memberof ShoppingListComponent
   */
  public exportToFileEordering(list: ItemBasket[] = []): void {
    const rowList: string[][] = [];
    const creationDate = new Date();
    rowList.push(["Ordered Reference", "Quantity"]);
    list.forEach(item => {
      rowList.push([item.mpn, item.qtyInBasket.toString()]);
    });
    const filename = this.setFormatFileName(creationDate) + "_e_ordering";
    this.csvService.download(filename, rowList);
  }

  /**
   * Open the 'export to eOrdering' popup
   *
   * @memberof ShoppingListComponent
   */
  public exportToEOrdering(list: ItemBasket[] = []): void {
    const quantity = list.reduce((sum, item) => sum + item.qtyInBasket, 0);
    if (quantity > 0) {
      this.dialogRef.open(EOrderingDialogComponent, {
        panelClass: "eOrdering-dialog-container"
      });
    }
  }

  /**
   * Set the format File Name for export
   *
   * @param creationDate
   * @returns
   * @memberof ShoppingListComponent
   */
  public setFormatFileName(creationDate: Date): string {
    return join(
      [
        this.store.publicationID,
        // "export_shopping_basket",
        "export_search",
        this.setFormatDate(new Date()).replace(new RegExp(/[\/]/gm), "-"),
        creationDate.getHours().toString(),
        creationDate.getMinutes().toString(),
        creationDate.getSeconds().toString()
      ],
      "_"
    );
  }

  /**
   * Set the format Date for export
   *
   * @param time
   * @returns
   * @memberof ShoppingListComponent
   */
  public setFormatDate(time: Date): string {
    const frDate = time.toLocaleDateString("fr").split("/").reverse();
    return join(frDate, "/");
  }

  /**
   * Create a standard undo function to pass to the deleteWithUndo function
   *
   * @param itemsToRestore - The set of deleted items to restore
   */
  protected undoDeleteFactory(
    itemsToRestore: ItemBasket | ItemBasket[]
  ): () => Promise<ItemBasket[]> {
    return () => {
      const docToSave = Array.isArray(itemsToRestore) ? itemsToRestore : [itemsToRestore];

      const saveProm = docToSave.map((doc: ItemBasket) => {
        delete doc._rev;
        return this.addItemsToBasket([doc]);
      });

      return Promise.all(saveProm)
        .then(() => {
          const undoMessage = this.translate.instant("undo.ok");
          this.messageService.success(undoMessage);
          // We need to refresh the ItemOftypes as save() function doesn't do it by default.
          // To update the datasource in shopping list
          return this.getItemsOfType() as Promise<ItemBasket[]>;
        })
        .catch(() => {
          const errMessage = this.translate.instant("undo.error");
          this.messageService.error(errMessage);
          return [] as ItemBasket[];
        });
    };
  }

  /**
   * Init and create a discrepancy portal object
   * from a xml and a ItemBasket
   *
   * @private
   * @param xml
   * @param shopping
   * @returns
   * @memberof ShoppingService
   */
  // SPEC: Missing xpath for S1000D aircraft
  private createDiscrepancyPortalObj(xml: Document, shopping: ItemBasket): DiscrepancyData {
    const isn = shopping.isn.find(isnObj => isnObj.pnr === shopping.mpn);

    const isnXml = xpath.select1(
      `dmodule/content/IPC/CSN[@CSN="${shopping.id}"]/ISN[@ID="${isn.id}"]`,
      xml
    ) as Node;
    const dmAdress = xpath.select1("dmodule/identAndStatusSection/dmAddress", xml) as Node;
    const wp6Status = xpath.select1("dmodule/WP6Status", xml) as Node;

    const desc = xpath
      .select("(PAS/DFP | CBS/DFL)/text()", isnXml)
      .map((value: Node) => value.nodeValue);

    return {
      ipc: xpath.select1(
        "concat(dmIdent/dmCode/@modelIdentCode, dmIdent/identExtension/@extensionCode)",
        dmAdress
      ) as string,
      rev: xpath.select1(
        "concat(dmAddressItems/issueDate/@year,'.', dmAddressItems/issueDate/@month,'.', dmAddressItems/issueDate/@day)",
        dmAdress
      ) as string,
      model: this.store.pubInfo.verbatimText,
      chap: xpath.select1("string(simpleShortDmc)", wp6Status) as string,
      ata_desc: shopping.title,
      pos: shopping.hotspotId,
      desc: `"${desc.join(" ")}"`,
      pnr: shopping.mpn,
      mfc: shopping.mfc,
      qty: +shopping.qty,
      shortDmc: shopping.reference
    };
  }
}
