import xpath from "xpath";

import { Util, ARRAY_SEPARATOR, OBJECT_SEPARATOR } from "@orion2/utils/datamodule.utils";
import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";

export class Description {
  public static convertTypoCodeToTypology(typoCode: string): string {
    switch (typoCode) {
      case "lt51":
      case "lt04":
      case "taskcd02":
        return "DIS";
      case "lt01":
      case "taskcd52":
        return "RES";
      case "taskcd01":
        return "DET";
      case "taskcd03":
        return "FNC";
      case "taskcd04":
        return "GVI";
      case "taskcd05":
        return "LUB";
      case "taskcd06":
        return "OPC";
      case "taskcd07":
        return "RST";
      case "taskcd08":
        return "SVC";
      case "taskcd09":
        return "VCK";
      case "taskcd51":
        return "SDI";
      default:
        return "";
    }
  }

  public static extractLegacy(xmlNode: Node): string {
    const data: string[] = [];
    let changed = false;

    const amsApplicability = Util.cleanXml(xpath.select("string(AMSAPPLICABILITY/PARA)", xmlNode));
    changed = xpath.select("string(AMSAPPLICABILITY/PARA//CHANGEMARK)", xmlNode).length > 0;
    data.push(amsApplicability + OBJECT_SEPARATOR + changed);

    const softwareVersion = Util.cleanXml(xpath.select("string(SOFTWAREVERSION)", xmlNode));
    changed = xpath.select("string(SOFTWAREVERSION//CHANGEMARK)", xmlNode).length > 0;
    data.push(softwareVersion + OBJECT_SEPARATOR + changed);

    const textualApplicability = xpath.select("string(TEXTUALAPPLICABILITY)", xmlNode).toString();
    changed = xpath.select("string(TEXTUALAPPLICABILITY//CHANGEMARK)", xmlNode).length > 0;
    data.push(textualApplicability + OBJECT_SEPARATOR + changed);

    const climaticConditions = xpath.select("CLIMATICCONDITIONLIST/CLIMATICCONDITION", xmlNode);
    climaticConditions.forEach((climaticCondition: Document) => {
      const conditions = Util.cleanXml(xpath.select("string(PARA)", climaticCondition));
      changed = xpath.select("string(.//CHANGEMARK)", climaticCondition).length > 0;
      data.push(conditions + OBJECT_SEPARATOR + changed);
    });

    const nrd = Util.cleanXml(xpath.select("string(NRD/PARA)", xmlNode));
    changed = xpath.select("string(NRD//CHANGEMARK)", xmlNode).length > 0;
    data.push(nrd + OBJECT_SEPARATOR + changed);

    const descriptions = xpath.select("DESC/PARA", xmlNode);
    descriptions.forEach((description: Node) => {
      const text = Util.cleanXml(xpath.select("string()", description));
      changed = xpath.select("string(.//CHANGEMARK)", description).length > 0;
      data.push(text + " " + OBJECT_SEPARATOR + changed);
    });

    const mrb = Util.cleanXml(xpath.select("string(MRB)", xmlNode));
    changed = xpath.select("string(MRB//CHANGEMARK)", xmlNode).length > 0;
    data.push(mrb + OBJECT_SEPARATOR + changed);

    const typology = Util.cleanXml(xpath.select("string(TYPOLOGY/PARA)", xmlNode));
    changed = xpath.select("string(TYPOLOGY//CHANGEMARK)", xmlNode).length > 0;
    data.push(typology + OBJECT_SEPARATOR + changed);

    const symbology = Util.cleanXml(xpath.select("string(SYMBOLOGY)", xmlNode));
    changed = xpath.select("string(SYMBOLOGY//CHANGEMARK)", xmlNode).length > 0;
    data.push(symbology + OBJECT_SEPARATOR + changed);

    const workpackage = Util.cleanXml(xpath.select("string(WORKPACKAGE)", xmlNode));
    changed = xpath.select("string(WORKPACKAGE//CHANGEMARK)", xmlNode).length > 0;
    data.push(workpackage + OBJECT_SEPARATOR + changed);

    return data.join(ARRAY_SEPARATOR);
  }

  public static extractH160(xmlNode: Node): string {
    const data: string[] = [];
    let changed = false;
    // Task linked through MPN
    const conditionLink = Util.cleanXml(
      xpath.select("string(relatedTask/@relatedTaskDescr)", xmlNode)
    );
    const taskIdent = Util.cleanXml(xpath.select("string(relatedTask/@taskIdent)", xmlNode));
    changed = xpath.select("string(relatedTask//@changeMark)", xmlNode).length > 0;
    if (conditionLink === "with") {
      data.push("Task linked to the " + taskIdent + OBJECT_SEPARATOR + changed);
    }
    if (conditionLink === "precludes") {
      data.push(
        "Task linked to the normal climatic condition task " +
          taskIdent +
          OBJECT_SEPARATOR +
          changed
      );
    }

    // MOD + Climat
    const modApplicabilities = xpath.select(
      "ancestor::content//referencedApplicGroup/applic",
      xmlNode
    );
    const applicRefId = xpath.select("string(@applicRefId)", xmlNode).toString();
    modApplicabilities.forEach((mod: Node) => {
      const id = xpath.select("string(@id)", mod).toString();
      if (id === applicRefId) {
        const evaluates = xpath.select("./evaluate", mod);
        if (evaluates?.length > 0) {
          evaluates.forEach((evaluate: Node) => {
            changed = xpath.select("string(.//@changeMark)", evaluate).length > 0;
            const evalValue = this.setApplicDesc(evaluate, "");
            data.push(evalValue + OBJECT_SEPARATOR + changed);
          });
        } else {
          // SPEC: Sometimes there is no evaluate in the referencedApplicGroup and we get directly the assert
          const asserts = xpath.select("./assert", mod);
          asserts.forEach((assert: Node) => {
            changed = xpath.select("string(.//@changeMark)", assert).length > 0;
            const evalValue = this.getAssertValue(assert);
            data.push(evalValue + OBJECT_SEPARATOR + changed);
          });
        }
      }
    });

    // NRD Punctual
    const remarks = xpath.select("remarks/simplePara", xmlNode);

    remarks.forEach((remark: Node) => {
      changed = xpath.select("string(../@changeMark | ./@changeMark)", remark).length > 0;
      const simplePara = Util.cleanXml(xpath.select("string(.)", remark));
      data.push(simplePara + OBJECT_SEPARATOR + changed);
    });

    // Description
    const taskDesc = xpath.select(
      "timeLimit/remarks/simplePara[position() > 1] | task/taskDescr/simplePara",
      xmlNode
    );

    taskDesc.forEach((desc: Node) => {
      changed =
        xpath.select("string(../../@changeMark | ../@changeMark | ./@changeMark)", desc).length > 0;
      const simplePara = Util.cleanXml(xpath.select("string(.)", desc));
      data.push(simplePara + OBJECT_SEPARATOR + changed);
    });

    // Factor K
    const factorK = xpath.select("factorK", xmlNode);
    factorK.forEach((factor: Node) => {
      changed = xpath.select("string(.//changeMark)", factor).length > 0;
      const particularUsingCond = xpath.select("string(@particularUsingCond)", factor).toString();
      const multiplicationFactorValue = xpath
        .select("string(@multiplicationFactorValue)", factor)
        .toString();
      const multiplacationFactorUnit = xpath
        .select("string(@multiplacationFactorUnit)", factor)
        .toString();

      data.push(
        "Operation with " +
          particularUsingCond +
          " corrective multiplication factor: " +
          multiplicationFactorValue +
          " " +
          multiplacationFactorUnit +
          "." +
          OBJECT_SEPARATOR +
          changed
      );
    });

    // Display the source document MRB code
    const sourceDocList = {};
    const rqmtSources = xpath.select("rqmtSource", xmlNode);
    if (rqmtSources) {
      rqmtSources.forEach((rqmtSource: Node) => {
        const source = xpath.select("string(@sourceOfRqmt)", rqmtSource).toString();
        const type = source.split("-")[0];

        const sourceValue = source.replace(type + "-", "");

        //If rqmtSource is empty, don't display anything
        if (source) {
          if (!sourceDocList[type]) {
            sourceDocList[type] = sourceValue;
          } else {
            sourceDocList[type] += " / " + sourceValue;
          }
        }
      });
      Object.keys(sourceDocList).forEach(key => {
        if (sourceDocList[key].length > 0) {
          data.push(key + " : " + sourceDocList[key] + ".");
        }
      });
    }

    // Typology
    const typoCode = xpath
      .select("string(@taskCode | timeLimit/limitType/@limitUnitType)", xmlNode)
      .toString();
    data.push(this.convertTypoCodeToTypology(typoCode));

    return data.join(ARRAY_SEPARATOR);
  }

  public static unserialize(data: string): TaskNamespace.Description[] {
    return data
      ?.split(ARRAY_SEPARATOR)
      .map((description: string) => description.split(OBJECT_SEPARATOR))
      .filter((val: string[]) => val[0] !== "")
      .map((val: string[]) => ({
        value: val[0],
        changed: val[1] === "true"
      }));
  }

  private static setApplicDesc(evaluate: Node, evalValue: string): string {
    let andOr = xpath.select("string(@andOr)", evaluate).toString();
    const lastEval = xpath.select(".//evaluate", evaluate).length === 0;
    // the parent of first evaluate is applic
    // for nested evaluate we need to add parenthesis
    const needParenthesis = xpath.select("name(..)", evaluate).toString() !== "applic";

    if (andOr === "and") {
      andOr = "&";
    } else if (andOr === "or") {
      andOr = "//";
    }
    const asserts = xpath.select("./assert", evaluate);
    if (needParenthesis) {
      evalValue += "(";
    }
    asserts.forEach((assert: Node, index: number) => {
      evalValue += this.getAssertValue(assert);
      if (index !== asserts.length - 1 || !lastEval) {
        evalValue += " " + andOr + " ";
      }
    });
    if (needParenthesis) {
      evalValue += ")";
    }

    xpath.select("./evaluate", evaluate).forEach((e: Node) => {
      evalValue += this.setApplicDesc(e, evalValue);
    });
    return evalValue;
  }

  private static getAssertValue(assert: Node): string {
    const applicDisplayClass = xpath.select("string(@applicDisplayClass)", assert).toString();
    const applicPropertyIdent = xpath.select("string(@applicPropertyIdent)", assert).toString();
    const applicPropertyValues = xpath.select("string(@applicPropertyValues)", assert).toString();
    if (applicDisplayClass) {
      return `${applicPropertyValues} ${applicDisplayClass} ${applicPropertyIdent}`;
    }
    return applicPropertyIdent.startsWith("MOD-") || applicPropertyIdent.startsWith("ECP-")
      ? applicPropertyValues + " " + applicPropertyIdent.split("-")[1]
      : applicPropertyValues;
  }
}
