import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Navigation } from "@orion2/models/tocitem.models";
import { Store } from "@viewer/core";
import { OrionCardData } from "@viewer/shared-module/orion-card/orion-card.component";

@Component({
  selector: "o-navigation-tree",
  templateUrl: "./navigation-tree.component.html",
  styleUrls: ["./navigation-tree.component.scss"]
})
export class NavigationTreeComponent implements OnInit {
  @Input() navigation: Navigation;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<Navigation> = new EventEmitter();
  dmcSelected: string;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.dmcSelected = this.store.currentDMC;
  }

  emitClose(item: Navigation): void {
    this.onClick.emit(item);
  }

  public toggle(item: Navigation): void {
    item.active = !item.active;
  }

  public getUrl(dmc: string): string {
    if (dmc === this.dmcSelected) {
      return;
    }
    return `/pub/${this.store.publicationID}/${this.store.publicationRevision}/du/${dmc}`;
  }

  public getOrionCardData(element: Navigation): OrionCardData {
    return {
      dmc: element.dmc,
      title: element.dmc,
      showAdditionalInfos: true,
      forceVisibility: true
    };
  }
}
