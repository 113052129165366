import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "pubLang"
})
export class PubLangPipe implements PipeTransform {
  private static cache: Object = {};
  public langAvailable = ["en", "fr", "es", "de"];

  translate(key: string, lang: string): string {
    if (!lang || !this.langAvailable.includes(lang)) {
      lang = "en";
    }

    if (!PubLangPipe.cache.hasOwnProperty(lang)) {
      PubLangPipe.cache[lang] = {};
    }

    // Load in memory if don't exist
    if (!PubLangPipe.cache[lang].hasOwnProperty(key)) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      PubLangPipe.cache[lang][key] = require(`../../apps/viewer/src/assets/i18n/${lang}.json`)[key];
    }

    return PubLangPipe.cache[lang][key];
  }

  transform(key: string, lang: string): string {
    return this.translate(key, lang);
  }
}
