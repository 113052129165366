// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-form-field {
  width: 120px;
}

.lang-select {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.selected-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

::ng-deep .mdc-list-item__primary-text {
  width: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlbGVjdC1sYW5nLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsWUFBQTtBQUNGOztBQUVBO0VBQ0UsbUJBQUE7RUFDQSxhQUFBO0VBQ0EsOEJBQUE7RUFDQSxlQUFBO0FBQ0Y7O0FBRUE7RUFDRSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSx1QkFBQTtBQUNGOztBQUVBO0VBQ0UsV0FBQTtBQUNGIiwiZmlsZSI6InNlbGVjdC1sYW5nLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsibWF0LWZvcm0tZmllbGQge1xuICB3aWR0aDogMTIwcHg7XG59XG5cbi5sYW5nLXNlbGVjdCB7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgcGFkZGluZzogMCAxNnB4O1xufVxuXG4uc2VsZWN0ZWQtdGV4dCB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xufVxuXG46Om5nLWRlZXAgLm1kYy1saXN0LWl0ZW1fX3ByaW1hcnktdGV4dCB7XG4gIHdpZHRoOiAxMDAlO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/shared-module/select-lang/select-lang.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;AACF;AACA,o0BAAo0B","sourcesContent":["mat-form-field {\n  width: 120px;\n}\n\n.lang-select {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  padding: 0 16px;\n}\n\n.selected-text {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n::ng-deep .mdc-list-item__primary-text {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
