import { DbItemInterface, PubDoc, TocInfo } from "@orion2/models/couch.models";
import { IpcDetail } from "@orion2/models/ipc.models";
import { PymResponse, PymNode } from "@orion2/models/pym.models";
import { TreeNode } from "@viewer/toc-items/bookmark-module/bookmark-list/models";
import { Settings } from "libs/models/settings.models";

export interface ParamFindTocItem {
  selector: {
    $and: {
      [key: string]: unknown;
    }[];
  };
  limit: number;
  bookmark?: string;
  skip?: number;
}

export interface TocItemInterface extends DbItemInterface {
  type: string;
  from?: string;
  minRevision?: string;
  maxRevision?: string;
}

export interface TechnicalEvent {
  id: number;
  reference: string;
  subId: number;
  isInternal: boolean;
}

export interface Note extends TocItemInterface {
  author: string;
  content: string;
  customTitle: string;
  dmc: string;
  shortDMC: string;
  status: string;
  parents: string[];
  technicalEvent?: TechnicalEvent;
  manualLabel?: string;
  color?: string;
  applic?: {
    version: string;
    serialno: string;
  };
}

export interface Parent {
  id: string;
  title: string;
}

export interface TaskId {
  idRefSearch: number;
  idRefDM: string;
}

export interface Inspection extends TocItemInterface {
  title: string;
  tasks: TaskId[];
  date: Date;
  author: string;
}

export interface TaskInspectionDoc extends TocItemInterface {
  _id: string;
  parents: Parent[];
}

export interface Bookmark extends TocItemInterface {
  dmc: string;
  title: string;
  author: string;
}

export interface TocItemTree extends TocItemInterface {
  data: TreeNode[];
}

export type PubIdentifier = Pick<PubDoc, "verbatimText" | "occurrenceCode" | "lang">;

export interface News extends TocItemInterface {
  title: string;
  isImportant: boolean;
  isGlobal: boolean;
  date: Date;
  publication?: PubIdentifier;
  read?: boolean;
  lastRevRead?: string;
}

export interface Preprint extends TocItemInterface, TocInfo {
  minRevision: string;
  title: string;
  date: Date;
  parents: string[];
  reference: string;
  _attachments: PouchDB.Core.Attachments;
}

export interface ItemBasket extends TocItemInterface, IpcDetail {
  packageId: string;
  revision: string;
  title: string;
  label: string;
  mpn: string;
  mfc: string;
  qty: number | string;
  dmc: string;
  hotspotId: string;
  mediaId: string;
  id: string;
  reference: string;
  qtyInBasket: number;
  versions: [string];
  parents: string[];
  allIsn: string;
  nsn: string;
  // SPEC: Attribute used for retrocompatibilty only for toc item
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NSN?: string;
  addingDate: number;
  isProcurable: boolean;
  sn: string;
}

export interface HistoryElem {
  dmc: string;
  date: Date;
  from?: string;
}

export interface Historic extends TocItemInterface {
  history: HistoryElem[];
}

export interface Navigation extends TocItemInterface {
  children: Navigation[];
  parent: string; // TODO: Maybe optional
  dmc: string;
  active?: boolean;
}

export interface Folder extends TocItemInterface, TocInfo {
  minRevision: string;
  title: string;
  date: Date;
  parents: string[];
}

export interface DocumentDoc extends TocItemInterface, TocInfo {
  minRevision: string;
  title: string;
  date: Date;
  parents: string[];
  reference: string;
  _attachments: PouchDB.Core.Attachments;
}

export interface ServiceBulletin extends TocItemInterface, TocInfo {
  minRevision: string;
  title: string;
  date: Date;
  parents: string[];
  impactedDM?: string[];
  reference: string;
  status: string;
  _attachments: PouchDB.Core.Attachments;
}

export interface Pym extends Bookmark, PymResponse {
  children?: PymNode[]; // Only for tree visualisation
}

export enum TocPublicType {
  PREPRINT = "preprint",
  NEWS = "news",
  FOLDER = "folder",
  SB = "sb",
  DOCUMENT = "document",
  NOTE = "notes"
}

export type TocPublicItems = News | AdditionalDocs | Note;

export type AdditionalDocs = Preprint | Folder | ServiceBulletin | DocumentDoc;

// Polymorphic type in typescript lul
export type TocItem =
  | Bookmark
  | Inspection
  | Note
  | TaskInspectionDoc
  | ItemBasket
  | Historic
  | Navigation
  | Pym
  | Settings
  | TocPublicItems
  | TocItemTree;
