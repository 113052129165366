import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatSidenav } from "@angular/material/sidenav";
import { ActivatedRoute, Router } from "@angular/router";
import { Basepub } from "@orion2/models/basepub.models";
import { SearchProductsService } from "@viewer/home/search-products/service/search-products.service";
import { HomeRoute } from "@viewer/home/models";

@Component({
  selector: "o-search-products",
  templateUrl: "./search-products.component.html",
  styleUrls: ["./search-products.component.scss"]
})
export class SearchProductsComponent implements OnInit {
  @Input() searchSidenav: MatSidenav;
  public aircrafts: Basepub.Aircraft[];
  public aircraftTechnicalName = "";
  public aircraftVersions: string[];

  public searchForm = new FormGroup({
    aircraftTechnicalName: new FormControl(""),
    aircraftVersion: new FormControl({ value: "", disabled: true }),
    productType: new FormControl({ value: "", disabled: true }),
    manualCode: new FormControl({ value: "", disabled: true }),
    lang: new FormControl({ value: "", disabled: true }),
    manualCategory: new FormControl({ value: "", disabled: true })
  });

  constructor(
    public searchProductsService: SearchProductsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getAircrafts().then(() => {
      this.searchForm.patchValue(this.route.snapshot.queryParams);
      const aircraft = this.searchForm.get("aircraftTechnicalName").value;
      this.aircraftVersions = this.getAircraftVersions(aircraft);
    });

    this.searchForm.get("aircraftTechnicalName")?.valueChanges.subscribe((value: string) => {
      this.toggleControls(value);
    });

    this.searchProductsService.filterReset.subscribe((control: string) => {
      this.searchForm.get(control).setValue("");
      this.onFilterChange("", control);
    });
  }

  public close(): void {
    this.searchSidenav.close();
  }

  public reset(): void {
    this.searchForm.reset();
    this.aircraftTechnicalName = "";
    this.router.navigate([HomeRoute.ONLINE], { queryParams: { lastConsulted: true } });
  }

  public onFilterChange(value: string, category: string): void {
    if (category === "commercialName") {
      this.aircraftTechnicalName = this.getTechnicalName(value);
      this.aircraftVersions = this.getAircraftVersions(value);
      this.searchForm.controls.aircraftVersion.reset();
    }

    const queryParams = {
      aircraftTechnicalName: this.getTechnicalName(
        this.searchForm.get("aircraftTechnicalName").value
      ),
      aircraftVersion: this.searchForm.get("aircraftVersion").value || undefined,
      productType: this.searchForm.get("productType").value || undefined,
      manualCode: this.searchForm.get("manualCode").value || undefined,
      lang: this.searchForm.get("lang").value || undefined,
      manualCategory: this.searchForm.get("manualCategory").value || undefined
    };

    this.router.navigate([HomeRoute.ONLINE], { queryParams });
  }

  /**
   * Get the technical name for a given commercial name.
   *
   * @param commercialname An aircraft commercial name.
   */
  private getTechnicalName(commercialName: string): string {
    const currentAircraft = this.aircrafts.find(
      (aircraft: Basepub.Aircraft) => aircraft.commercialName === commercialName
    );
    return currentAircraft?.technicalName;
  }

  /**
   * Fetch the list of available aircrafts from Basepub.
   */
  private getAircrafts(): Promise<void> {
    return this.searchProductsService.getAircrafts().then((aircrafts: Basepub.Aircraft[]) => {
      this.aircrafts = aircrafts;
    });
  }

  /**
   * Get all versions available for the selected aircraft.
   */
  private getAircraftVersions(commercialName: string): string[] {
    const currentAircraft = this.aircrafts.find(
      (aircraft: Basepub.Aircraft) => aircraft.commercialName === commercialName
    );
    return currentAircraft?.version.sort((v1: string, v2: string) => v1.localeCompare(v2));
  }

  private toggleControls(value: string): void {
    const controlsToToggle = [
      "aircraftVersion",
      "productType",
      "manualCode",
      "lang",
      "manualCategory"
    ];
    controlsToToggle.forEach((controlName: string) => {
      const control = this.searchForm.get(controlName);
      if (value) {
        control?.enable();
      } else {
        control?.disable();
      }
      control?.updateValueAndValidity();
    });
  }
}
