/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.model = (function() {

    /**
     * Namespace model.
     * @exports model
     * @namespace
     */
    var model = {};

    model.Doc = (function() {

        /**
         * Properties of a Doc.
         * @memberof model
         * @interface IDoc
         * @property {number|null} [tf] Doc tf
         */

        /**
         * Constructs a new Doc.
         * @memberof model
         * @classdesc Represents a Doc.
         * @implements IDoc
         * @constructor
         * @param {model.IDoc=} [properties] Properties to set
         */
        function Doc(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Doc tf.
         * @member {number} tf
         * @memberof model.Doc
         * @instance
         */
        Doc.prototype.tf = 0;

        /**
         * Creates a new Doc instance using the specified properties.
         * @function create
         * @memberof model.Doc
         * @static
         * @param {model.IDoc=} [properties] Properties to set
         * @returns {model.Doc} Doc instance
         */
        Doc.create = function create(properties) {
            return new Doc(properties);
        };

        /**
         * Encodes the specified Doc message. Does not implicitly {@link model.Doc.verify|verify} messages.
         * @function encode
         * @memberof model.Doc
         * @static
         * @param {model.IDoc} message Doc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Doc.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tf != null && Object.hasOwnProperty.call(message, "tf"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.tf);
            return writer;
        };

        /**
         * Encodes the specified Doc message, length delimited. Does not implicitly {@link model.Doc.verify|verify} messages.
         * @function encodeDelimited
         * @memberof model.Doc
         * @static
         * @param {model.IDoc} message Doc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Doc.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Doc message from the specified reader or buffer.
         * @function decode
         * @memberof model.Doc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {model.Doc} Doc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Doc.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.model.Doc();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.tf = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Doc message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof model.Doc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {model.Doc} Doc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Doc.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Doc message.
         * @function verify
         * @memberof model.Doc
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Doc.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tf != null && message.hasOwnProperty("tf"))
                if (typeof message.tf !== "number")
                    return "tf: number expected";
            return null;
        };

        /**
         * Creates a Doc message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof model.Doc
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {model.Doc} Doc
         */
        Doc.fromObject = function fromObject(object) {
            if (object instanceof $root.model.Doc)
                return object;
            var message = new $root.model.Doc();
            if (object.tf != null)
                message.tf = Number(object.tf);
            return message;
        };

        /**
         * Creates a plain object from a Doc message. Also converts values to other types if specified.
         * @function toObject
         * @memberof model.Doc
         * @static
         * @param {model.Doc} message Doc
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Doc.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.tf = 0;
            if (message.tf != null && message.hasOwnProperty("tf"))
                object.tf = options.json && !isFinite(message.tf) ? String(message.tf) : message.tf;
            return object;
        };

        /**
         * Converts this Doc to JSON.
         * @function toJSON
         * @memberof model.Doc
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Doc.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Doc;
    })();

    model.DocumentStore = (function() {

        /**
         * Properties of a DocumentStore.
         * @memberof model
         * @interface IDocumentStore
         * @property {Object.<string,model.IIndexFields>|null} [docInfo] DocumentStore docInfo
         * @property {Object.<string,string>|null} [docs] DocumentStore docs
         */

        /**
         * Constructs a new DocumentStore.
         * @memberof model
         * @classdesc Represents a DocumentStore.
         * @implements IDocumentStore
         * @constructor
         * @param {model.IDocumentStore=} [properties] Properties to set
         */
        function DocumentStore(properties) {
            this.docInfo = {};
            this.docs = {};
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DocumentStore docInfo.
         * @member {Object.<string,model.IIndexFields>} docInfo
         * @memberof model.DocumentStore
         * @instance
         */
        DocumentStore.prototype.docInfo = $util.emptyObject;

        /**
         * DocumentStore docs.
         * @member {Object.<string,string>} docs
         * @memberof model.DocumentStore
         * @instance
         */
        DocumentStore.prototype.docs = $util.emptyObject;

        /**
         * Creates a new DocumentStore instance using the specified properties.
         * @function create
         * @memberof model.DocumentStore
         * @static
         * @param {model.IDocumentStore=} [properties] Properties to set
         * @returns {model.DocumentStore} DocumentStore instance
         */
        DocumentStore.create = function create(properties) {
            return new DocumentStore(properties);
        };

        /**
         * Encodes the specified DocumentStore message. Does not implicitly {@link model.DocumentStore.verify|verify} messages.
         * @function encode
         * @memberof model.DocumentStore
         * @static
         * @param {model.IDocumentStore} message DocumentStore message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DocumentStore.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.docInfo != null && Object.hasOwnProperty.call(message, "docInfo"))
                for (var keys = Object.keys(message.docInfo), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                    $root.model.IndexFields.encode(message.docInfo[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            if (message.docs != null && Object.hasOwnProperty.call(message, "docs"))
                for (var keys = Object.keys(message.docs), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 0 =*/8).uint32(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.docs[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DocumentStore message, length delimited. Does not implicitly {@link model.DocumentStore.verify|verify} messages.
         * @function encodeDelimited
         * @memberof model.DocumentStore
         * @static
         * @param {model.IDocumentStore} message DocumentStore message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DocumentStore.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DocumentStore message from the specified reader or buffer.
         * @function decode
         * @memberof model.DocumentStore
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {model.DocumentStore} DocumentStore
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DocumentStore.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.model.DocumentStore(), key, value;
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (message.docInfo === $util.emptyObject)
                        message.docInfo = {};
                    var end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = null;
                    while (reader.pos < end2) {
                        var tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = $root.model.IndexFields.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.docInfo[key] = value;
                    break;
                case 2:
                    if (message.docs === $util.emptyObject)
                        message.docs = {};
                    var end2 = reader.uint32() + reader.pos;
                    key = 0;
                    value = "";
                    while (reader.pos < end2) {
                        var tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.uint32();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.docs[key] = value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DocumentStore message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof model.DocumentStore
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {model.DocumentStore} DocumentStore
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DocumentStore.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DocumentStore message.
         * @function verify
         * @memberof model.DocumentStore
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DocumentStore.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.docInfo != null && message.hasOwnProperty("docInfo")) {
                if (!$util.isObject(message.docInfo))
                    return "docInfo: object expected";
                var key = Object.keys(message.docInfo);
                for (var i = 0; i < key.length; ++i) {
                    var error = $root.model.IndexFields.verify(message.docInfo[key[i]]);
                    if (error)
                        return "docInfo." + error;
                }
            }
            if (message.docs != null && message.hasOwnProperty("docs")) {
                if (!$util.isObject(message.docs))
                    return "docs: object expected";
                var key = Object.keys(message.docs);
                for (var i = 0; i < key.length; ++i) {
                    if (!$util.key32Re.test(key[i]))
                        return "docs: integer key{k:uint32} expected";
                    if (!$util.isString(message.docs[key[i]]))
                        return "docs: string{k:uint32} expected";
                }
            }
            return null;
        };

        /**
         * Creates a DocumentStore message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof model.DocumentStore
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {model.DocumentStore} DocumentStore
         */
        DocumentStore.fromObject = function fromObject(object) {
            if (object instanceof $root.model.DocumentStore)
                return object;
            var message = new $root.model.DocumentStore();
            if (object.docInfo) {
                if (typeof object.docInfo !== "object")
                    throw TypeError(".model.DocumentStore.docInfo: object expected");
                message.docInfo = {};
                for (var keys = Object.keys(object.docInfo), i = 0; i < keys.length; ++i) {
                    if (typeof object.docInfo[keys[i]] !== "object")
                        throw TypeError(".model.DocumentStore.docInfo: object expected");
                    message.docInfo[keys[i]] = $root.model.IndexFields.fromObject(object.docInfo[keys[i]]);
                }
            }
            if (object.docs) {
                if (typeof object.docs !== "object")
                    throw TypeError(".model.DocumentStore.docs: object expected");
                message.docs = {};
                for (var keys = Object.keys(object.docs), i = 0; i < keys.length; ++i)
                    message.docs[keys[i]] = String(object.docs[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from a DocumentStore message. Also converts values to other types if specified.
         * @function toObject
         * @memberof model.DocumentStore
         * @static
         * @param {model.DocumentStore} message DocumentStore
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DocumentStore.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.objects || options.defaults) {
                object.docInfo = {};
                object.docs = {};
            }
            var keys2;
            if (message.docInfo && (keys2 = Object.keys(message.docInfo)).length) {
                object.docInfo = {};
                for (var j = 0; j < keys2.length; ++j)
                    object.docInfo[keys2[j]] = $root.model.IndexFields.toObject(message.docInfo[keys2[j]], options);
            }
            if (message.docs && (keys2 = Object.keys(message.docs)).length) {
                object.docs = {};
                for (var j = 0; j < keys2.length; ++j)
                    object.docs[keys2[j]] = message.docs[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this DocumentStore to JSON.
         * @function toJSON
         * @memberof model.DocumentStore
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DocumentStore.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DocumentStore;
    })();

    model.IndexFields = (function() {

        /**
         * Properties of an IndexFields.
         * @memberof model
         * @interface IIndexFields
         * @property {number|null} [text] IndexFields text
         * @property {number|null} [infoName] IndexFields infoName
         * @property {number|null} [techName] IndexFields techName
         */

        /**
         * Constructs a new IndexFields.
         * @memberof model
         * @classdesc Represents an IndexFields.
         * @implements IIndexFields
         * @constructor
         * @param {model.IIndexFields=} [properties] Properties to set
         */
        function IndexFields(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IndexFields text.
         * @member {number} text
         * @memberof model.IndexFields
         * @instance
         */
        IndexFields.prototype.text = 0;

        /**
         * IndexFields infoName.
         * @member {number} infoName
         * @memberof model.IndexFields
         * @instance
         */
        IndexFields.prototype.infoName = 0;

        /**
         * IndexFields techName.
         * @member {number} techName
         * @memberof model.IndexFields
         * @instance
         */
        IndexFields.prototype.techName = 0;

        /**
         * Creates a new IndexFields instance using the specified properties.
         * @function create
         * @memberof model.IndexFields
         * @static
         * @param {model.IIndexFields=} [properties] Properties to set
         * @returns {model.IndexFields} IndexFields instance
         */
        IndexFields.create = function create(properties) {
            return new IndexFields(properties);
        };

        /**
         * Encodes the specified IndexFields message. Does not implicitly {@link model.IndexFields.verify|verify} messages.
         * @function encode
         * @memberof model.IndexFields
         * @static
         * @param {model.IIndexFields} message IndexFields message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IndexFields.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.text);
            if (message.infoName != null && Object.hasOwnProperty.call(message, "infoName"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.infoName);
            if (message.techName != null && Object.hasOwnProperty.call(message, "techName"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.techName);
            return writer;
        };

        /**
         * Encodes the specified IndexFields message, length delimited. Does not implicitly {@link model.IndexFields.verify|verify} messages.
         * @function encodeDelimited
         * @memberof model.IndexFields
         * @static
         * @param {model.IIndexFields} message IndexFields message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IndexFields.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IndexFields message from the specified reader or buffer.
         * @function decode
         * @memberof model.IndexFields
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {model.IndexFields} IndexFields
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IndexFields.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.model.IndexFields();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.text = reader.uint32();
                    break;
                case 2:
                    message.infoName = reader.uint32();
                    break;
                case 3:
                    message.techName = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IndexFields message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof model.IndexFields
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {model.IndexFields} IndexFields
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IndexFields.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IndexFields message.
         * @function verify
         * @memberof model.IndexFields
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IndexFields.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isInteger(message.text))
                    return "text: integer expected";
            if (message.infoName != null && message.hasOwnProperty("infoName"))
                if (!$util.isInteger(message.infoName))
                    return "infoName: integer expected";
            if (message.techName != null && message.hasOwnProperty("techName"))
                if (!$util.isInteger(message.techName))
                    return "techName: integer expected";
            return null;
        };

        /**
         * Creates an IndexFields message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof model.IndexFields
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {model.IndexFields} IndexFields
         */
        IndexFields.fromObject = function fromObject(object) {
            if (object instanceof $root.model.IndexFields)
                return object;
            var message = new $root.model.IndexFields();
            if (object.text != null)
                message.text = object.text >>> 0;
            if (object.infoName != null)
                message.infoName = object.infoName >>> 0;
            if (object.techName != null)
                message.techName = object.techName >>> 0;
            return message;
        };

        /**
         * Creates a plain object from an IndexFields message. Also converts values to other types if specified.
         * @function toObject
         * @memberof model.IndexFields
         * @static
         * @param {model.IndexFields} message IndexFields
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IndexFields.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.text = 0;
                object.infoName = 0;
                object.techName = 0;
            }
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            if (message.infoName != null && message.hasOwnProperty("infoName"))
                object.infoName = message.infoName;
            if (message.techName != null && message.hasOwnProperty("techName"))
                object.techName = message.techName;
            return object;
        };

        /**
         * Converts this IndexFields to JSON.
         * @function toJSON
         * @memberof model.IndexFields
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IndexFields.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return IndexFields;
    })();

    model.Index = (function() {

        /**
         * Properties of an Index.
         * @memberof model
         * @interface IIndex
         * @property {string|null} [version] Index version
         * @property {Array.<string>|null} [fields] Index fields
         * @property {string|null} [ref] Index ref
         * @property {model.IDocumentStore|null} [documentStore] Index documentStore
         * @property {Array.<string>|null} [pipeline] Index pipeline
         */

        /**
         * Constructs a new Index.
         * @memberof model
         * @classdesc Represents an Index.
         * @implements IIndex
         * @constructor
         * @param {model.IIndex=} [properties] Properties to set
         */
        function Index(properties) {
            this.fields = [];
            this.pipeline = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Index version.
         * @member {string} version
         * @memberof model.Index
         * @instance
         */
        Index.prototype.version = "";

        /**
         * Index fields.
         * @member {Array.<string>} fields
         * @memberof model.Index
         * @instance
         */
        Index.prototype.fields = $util.emptyArray;

        /**
         * Index ref.
         * @member {string} ref
         * @memberof model.Index
         * @instance
         */
        Index.prototype.ref = "";

        /**
         * Index documentStore.
         * @member {model.IDocumentStore|null|undefined} documentStore
         * @memberof model.Index
         * @instance
         */
        Index.prototype.documentStore = null;

        /**
         * Index pipeline.
         * @member {Array.<string>} pipeline
         * @memberof model.Index
         * @instance
         */
        Index.prototype.pipeline = $util.emptyArray;

        /**
         * Creates a new Index instance using the specified properties.
         * @function create
         * @memberof model.Index
         * @static
         * @param {model.IIndex=} [properties] Properties to set
         * @returns {model.Index} Index instance
         */
        Index.create = function create(properties) {
            return new Index(properties);
        };

        /**
         * Encodes the specified Index message. Does not implicitly {@link model.Index.verify|verify} messages.
         * @function encode
         * @memberof model.Index
         * @static
         * @param {model.IIndex} message Index message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Index.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.version);
            if (message.fields != null && message.fields.length)
                for (var i = 0; i < message.fields.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.fields[i]);
            if (message.ref != null && Object.hasOwnProperty.call(message, "ref"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.ref);
            if (message.documentStore != null && Object.hasOwnProperty.call(message, "documentStore"))
                $root.model.DocumentStore.encode(message.documentStore, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.pipeline != null && message.pipeline.length)
                for (var i = 0; i < message.pipeline.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.pipeline[i]);
            return writer;
        };

        /**
         * Encodes the specified Index message, length delimited. Does not implicitly {@link model.Index.verify|verify} messages.
         * @function encodeDelimited
         * @memberof model.Index
         * @static
         * @param {model.IIndex} message Index message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Index.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Index message from the specified reader or buffer.
         * @function decode
         * @memberof model.Index
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {model.Index} Index
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Index.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.model.Index();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.version = reader.string();
                    break;
                case 2:
                    if (!(message.fields && message.fields.length))
                        message.fields = [];
                    message.fields.push(reader.string());
                    break;
                case 3:
                    message.ref = reader.string();
                    break;
                case 4:
                    message.documentStore = $root.model.DocumentStore.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.pipeline && message.pipeline.length))
                        message.pipeline = [];
                    message.pipeline.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Index message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof model.Index
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {model.Index} Index
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Index.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Index message.
         * @function verify
         * @memberof model.Index
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Index.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isString(message.version))
                    return "version: string expected";
            if (message.fields != null && message.hasOwnProperty("fields")) {
                if (!Array.isArray(message.fields))
                    return "fields: array expected";
                for (var i = 0; i < message.fields.length; ++i)
                    if (!$util.isString(message.fields[i]))
                        return "fields: string[] expected";
            }
            if (message.ref != null && message.hasOwnProperty("ref"))
                if (!$util.isString(message.ref))
                    return "ref: string expected";
            if (message.documentStore != null && message.hasOwnProperty("documentStore")) {
                var error = $root.model.DocumentStore.verify(message.documentStore);
                if (error)
                    return "documentStore." + error;
            }
            if (message.pipeline != null && message.hasOwnProperty("pipeline")) {
                if (!Array.isArray(message.pipeline))
                    return "pipeline: array expected";
                for (var i = 0; i < message.pipeline.length; ++i)
                    if (!$util.isString(message.pipeline[i]))
                        return "pipeline: string[] expected";
            }
            return null;
        };

        /**
         * Creates an Index message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof model.Index
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {model.Index} Index
         */
        Index.fromObject = function fromObject(object) {
            if (object instanceof $root.model.Index)
                return object;
            var message = new $root.model.Index();
            if (object.version != null)
                message.version = String(object.version);
            if (object.fields) {
                if (!Array.isArray(object.fields))
                    throw TypeError(".model.Index.fields: array expected");
                message.fields = [];
                for (var i = 0; i < object.fields.length; ++i)
                    message.fields[i] = String(object.fields[i]);
            }
            if (object.ref != null)
                message.ref = String(object.ref);
            if (object.documentStore != null) {
                if (typeof object.documentStore !== "object")
                    throw TypeError(".model.Index.documentStore: object expected");
                message.documentStore = $root.model.DocumentStore.fromObject(object.documentStore);
            }
            if (object.pipeline) {
                if (!Array.isArray(object.pipeline))
                    throw TypeError(".model.Index.pipeline: array expected");
                message.pipeline = [];
                for (var i = 0; i < object.pipeline.length; ++i)
                    message.pipeline[i] = String(object.pipeline[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an Index message. Also converts values to other types if specified.
         * @function toObject
         * @memberof model.Index
         * @static
         * @param {model.Index} message Index
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Index.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.fields = [];
                object.pipeline = [];
            }
            if (options.defaults) {
                object.version = "";
                object.ref = "";
                object.documentStore = null;
            }
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            if (message.fields && message.fields.length) {
                object.fields = [];
                for (var j = 0; j < message.fields.length; ++j)
                    object.fields[j] = message.fields[j];
            }
            if (message.ref != null && message.hasOwnProperty("ref"))
                object.ref = message.ref;
            if (message.documentStore != null && message.hasOwnProperty("documentStore"))
                object.documentStore = $root.model.DocumentStore.toObject(message.documentStore, options);
            if (message.pipeline && message.pipeline.length) {
                object.pipeline = [];
                for (var j = 0; j < message.pipeline.length; ++j)
                    object.pipeline[j] = message.pipeline[j];
            }
            return object;
        };

        /**
         * Converts this Index to JSON.
         * @function toJSON
         * @memberof model.Index
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Index.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Index;
    })();

    model.TSMap = (function() {

        /**
         * Properties of a TSMap.
         * @memberof model
         * @interface ITSMap
         * @property {Array.<number>|null} [_keys] TSMap _keys
         * @property {Array.<model.ITSMap>|null} [_values] TSMap _values
         * @property {number|null} [df] TSMap df
         * @property {Object.<string,model.IDoc>|null} [docs] TSMap docs
         */

        /**
         * Constructs a new TSMap.
         * @memberof model
         * @classdesc Represents a TSMap.
         * @implements ITSMap
         * @constructor
         * @param {model.ITSMap=} [properties] Properties to set
         */
        function TSMap(properties) {
            this._keys = [];
            this._values = [];
            this.docs = {};
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TSMap _keys.
         * @member {Array.<number>} _keys
         * @memberof model.TSMap
         * @instance
         */
        TSMap.prototype._keys = $util.emptyArray;

        /**
         * TSMap _values.
         * @member {Array.<model.ITSMap>} _values
         * @memberof model.TSMap
         * @instance
         */
        TSMap.prototype._values = $util.emptyArray;

        /**
         * TSMap df.
         * @member {number} df
         * @memberof model.TSMap
         * @instance
         */
        TSMap.prototype.df = 0;

        /**
         * TSMap docs.
         * @member {Object.<string,model.IDoc>} docs
         * @memberof model.TSMap
         * @instance
         */
        TSMap.prototype.docs = $util.emptyObject;

        /**
         * Creates a new TSMap instance using the specified properties.
         * @function create
         * @memberof model.TSMap
         * @static
         * @param {model.ITSMap=} [properties] Properties to set
         * @returns {model.TSMap} TSMap instance
         */
        TSMap.create = function create(properties) {
            return new TSMap(properties);
        };

        /**
         * Encodes the specified TSMap message. Does not implicitly {@link model.TSMap.verify|verify} messages.
         * @function encode
         * @memberof model.TSMap
         * @static
         * @param {model.ITSMap} message TSMap message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TSMap.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message._keys != null && message._keys.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message._keys.length; ++i)
                    writer.uint32(message._keys[i]);
                writer.ldelim();
            }
            if (message._values != null && message._values.length)
                for (var i = 0; i < message._values.length; ++i)
                    $root.model.TSMap.encode(message._values[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.df != null && Object.hasOwnProperty.call(message, "df"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.df);
            if (message.docs != null && Object.hasOwnProperty.call(message, "docs"))
                for (var keys = Object.keys(message.docs), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 0 =*/8).uint32(keys[i]);
                    $root.model.Doc.encode(message.docs[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Encodes the specified TSMap message, length delimited. Does not implicitly {@link model.TSMap.verify|verify} messages.
         * @function encodeDelimited
         * @memberof model.TSMap
         * @static
         * @param {model.ITSMap} message TSMap message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TSMap.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TSMap message from the specified reader or buffer.
         * @function decode
         * @memberof model.TSMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {model.TSMap} TSMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TSMap.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.model.TSMap(), key, value;
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message._keys && message._keys.length))
                        message._keys = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message._keys.push(reader.uint32());
                    } else
                        message._keys.push(reader.uint32());
                    break;
                case 2:
                    if (!(message._values && message._values.length))
                        message._values = [];
                    message._values.push($root.model.TSMap.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.df = reader.uint32();
                    break;
                case 4:
                    if (message.docs === $util.emptyObject)
                        message.docs = {};
                    var end2 = reader.uint32() + reader.pos;
                    key = 0;
                    value = null;
                    while (reader.pos < end2) {
                        var tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.uint32();
                            break;
                        case 2:
                            value = $root.model.Doc.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.docs[key] = value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TSMap message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof model.TSMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {model.TSMap} TSMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TSMap.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TSMap message.
         * @function verify
         * @memberof model.TSMap
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TSMap.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message._keys != null && message.hasOwnProperty("_keys")) {
                if (!Array.isArray(message._keys))
                    return "_keys: array expected";
                for (var i = 0; i < message._keys.length; ++i)
                    if (!$util.isInteger(message._keys[i]))
                        return "_keys: integer[] expected";
            }
            if (message._values != null && message.hasOwnProperty("_values")) {
                if (!Array.isArray(message._values))
                    return "_values: array expected";
                for (var i = 0; i < message._values.length; ++i) {
                    var error = $root.model.TSMap.verify(message._values[i]);
                    if (error)
                        return "_values." + error;
                }
            }
            if (message.df != null && message.hasOwnProperty("df"))
                if (!$util.isInteger(message.df))
                    return "df: integer expected";
            if (message.docs != null && message.hasOwnProperty("docs")) {
                if (!$util.isObject(message.docs))
                    return "docs: object expected";
                var key = Object.keys(message.docs);
                for (var i = 0; i < key.length; ++i) {
                    if (!$util.key32Re.test(key[i]))
                        return "docs: integer key{k:uint32} expected";
                    {
                        var error = $root.model.Doc.verify(message.docs[key[i]]);
                        if (error)
                            return "docs." + error;
                    }
                }
            }
            return null;
        };

        /**
         * Creates a TSMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof model.TSMap
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {model.TSMap} TSMap
         */
        TSMap.fromObject = function fromObject(object) {
            if (object instanceof $root.model.TSMap)
                return object;
            var message = new $root.model.TSMap();
            if (object._keys) {
                if (!Array.isArray(object._keys))
                    throw TypeError(".model.TSMap._keys: array expected");
                message._keys = [];
                for (var i = 0; i < object._keys.length; ++i)
                    message._keys[i] = object._keys[i] >>> 0;
            }
            if (object._values) {
                if (!Array.isArray(object._values))
                    throw TypeError(".model.TSMap._values: array expected");
                message._values = [];
                for (var i = 0; i < object._values.length; ++i) {
                    if (typeof object._values[i] !== "object")
                        throw TypeError(".model.TSMap._values: object expected");
                    message._values[i] = $root.model.TSMap.fromObject(object._values[i]);
                }
            }
            if (object.df != null)
                message.df = object.df >>> 0;
            if (object.docs) {
                if (typeof object.docs !== "object")
                    throw TypeError(".model.TSMap.docs: object expected");
                message.docs = {};
                for (var keys = Object.keys(object.docs), i = 0; i < keys.length; ++i) {
                    if (typeof object.docs[keys[i]] !== "object")
                        throw TypeError(".model.TSMap.docs: object expected");
                    message.docs[keys[i]] = $root.model.Doc.fromObject(object.docs[keys[i]]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a TSMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof model.TSMap
         * @static
         * @param {model.TSMap} message TSMap
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TSMap.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object._keys = [];
                object._values = [];
            }
            if (options.objects || options.defaults)
                object.docs = {};
            if (options.defaults)
                object.df = 0;
            if (message._keys && message._keys.length) {
                object._keys = [];
                for (var j = 0; j < message._keys.length; ++j)
                    object._keys[j] = message._keys[j];
            }
            if (message._values && message._values.length) {
                object._values = [];
                for (var j = 0; j < message._values.length; ++j)
                    object._values[j] = $root.model.TSMap.toObject(message._values[j], options);
            }
            if (message.df != null && message.hasOwnProperty("df"))
                object.df = message.df;
            var keys2;
            if (message.docs && (keys2 = Object.keys(message.docs)).length) {
                object.docs = {};
                for (var j = 0; j < keys2.length; ++j)
                    object.docs[keys2[j]] = $root.model.Doc.toObject(message.docs[keys2[j]], options);
            }
            return object;
        };

        /**
         * Converts this TSMap to JSON.
         * @function toJSON
         * @memberof model.TSMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TSMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TSMap;
    })();

    model.metaData = (function() {

        /**
         * Properties of a metaData.
         * @memberof model
         * @interface ImetaData
         * @property {string|null} [applicabilityMD5] metaData applicabilityMD5
         * @property {string|null} [shortTitle] metaData shortTitle
         * @property {string|null} [reference] metaData reference
         * @property {string|null} [revision] metaData revision
         * @property {Array.<string>|null} [versions] metaData versions
         * @property {string|null} [dmc] metaData dmc
         * @property {string|null} [manual] metaData manual
         * @property {Array.<string>|null} [task] metaData task
         * @property {string|null} [date] metaData date
         * @property {string|null} [type] metaData type
         * @property {string|null} [minRevision] metaData minRevision
         * @property {Array.<string>|null} [serialNo] metaData serialNo
         * @property {string|null} [attachedDMC] metaData attachedDMC
         * @property {Array.<string>|null} [part] metaData part
         * @property {boolean|null} [isS1000D] metaData isS1000D
         */

        /**
         * Constructs a new metaData.
         * @memberof model
         * @classdesc Represents a metaData.
         * @implements ImetaData
         * @constructor
         * @param {model.ImetaData=} [properties] Properties to set
         */
        function metaData(properties) {
            this.versions = [];
            this.task = [];
            this.serialNo = [];
            this.part = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * metaData applicabilityMD5.
         * @member {string} applicabilityMD5
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.applicabilityMD5 = "";

        /**
         * metaData shortTitle.
         * @member {string} shortTitle
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.shortTitle = "";

        /**
         * metaData reference.
         * @member {string} reference
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.reference = "";

        /**
         * metaData revision.
         * @member {string} revision
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.revision = "";

        /**
         * metaData versions.
         * @member {Array.<string>} versions
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.versions = $util.emptyArray;

        /**
         * metaData dmc.
         * @member {string} dmc
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.dmc = "";

        /**
         * metaData manual.
         * @member {string} manual
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.manual = "";

        /**
         * metaData task.
         * @member {Array.<string>} task
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.task = $util.emptyArray;

        /**
         * metaData date.
         * @member {string} date
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.date = "";

        /**
         * metaData type.
         * @member {string} type
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.type = "";

        /**
         * metaData minRevision.
         * @member {string} minRevision
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.minRevision = "";

        /**
         * metaData serialNo.
         * @member {Array.<string>} serialNo
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.serialNo = $util.emptyArray;

        /**
         * metaData attachedDMC.
         * @member {string} attachedDMC
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.attachedDMC = "";

        /**
         * metaData part.
         * @member {Array.<string>} part
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.part = $util.emptyArray;

        /**
         * metaData isS1000D.
         * @member {boolean} isS1000D
         * @memberof model.metaData
         * @instance
         */
        metaData.prototype.isS1000D = false;

        /**
         * Creates a new metaData instance using the specified properties.
         * @function create
         * @memberof model.metaData
         * @static
         * @param {model.ImetaData=} [properties] Properties to set
         * @returns {model.metaData} metaData instance
         */
        metaData.create = function create(properties) {
            return new metaData(properties);
        };

        /**
         * Encodes the specified metaData message. Does not implicitly {@link model.metaData.verify|verify} messages.
         * @function encode
         * @memberof model.metaData
         * @static
         * @param {model.ImetaData} message metaData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        metaData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.applicabilityMD5 != null && Object.hasOwnProperty.call(message, "applicabilityMD5"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.applicabilityMD5);
            if (message.shortTitle != null && Object.hasOwnProperty.call(message, "shortTitle"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.shortTitle);
            if (message.reference != null && Object.hasOwnProperty.call(message, "reference"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.reference);
            if (message.revision != null && Object.hasOwnProperty.call(message, "revision"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.revision);
            if (message.versions != null && message.versions.length)
                for (var i = 0; i < message.versions.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.versions[i]);
            if (message.dmc != null && Object.hasOwnProperty.call(message, "dmc"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.dmc);
            if (message.manual != null && Object.hasOwnProperty.call(message, "manual"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.manual);
            if (message.task != null && message.task.length)
                for (var i = 0; i < message.task.length; ++i)
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.task[i]);
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.date);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.type);
            if (message.minRevision != null && Object.hasOwnProperty.call(message, "minRevision"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.minRevision);
            if (message.serialNo != null && message.serialNo.length)
                for (var i = 0; i < message.serialNo.length; ++i)
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.serialNo[i]);
            if (message.attachedDMC != null && Object.hasOwnProperty.call(message, "attachedDMC"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.attachedDMC);
            if (message.part != null && message.part.length)
                for (var i = 0; i < message.part.length; ++i)
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.part[i]);
            if (message.isS1000D != null && Object.hasOwnProperty.call(message, "isS1000D"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.isS1000D);
            return writer;
        };

        /**
         * Encodes the specified metaData message, length delimited. Does not implicitly {@link model.metaData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof model.metaData
         * @static
         * @param {model.ImetaData} message metaData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        metaData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a metaData message from the specified reader or buffer.
         * @function decode
         * @memberof model.metaData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {model.metaData} metaData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        metaData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.model.metaData();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.applicabilityMD5 = reader.string();
                    break;
                case 2:
                    message.shortTitle = reader.string();
                    break;
                case 3:
                    message.reference = reader.string();
                    break;
                case 4:
                    message.revision = reader.string();
                    break;
                case 5:
                    if (!(message.versions && message.versions.length))
                        message.versions = [];
                    message.versions.push(reader.string());
                    break;
                case 6:
                    message.dmc = reader.string();
                    break;
                case 7:
                    message.manual = reader.string();
                    break;
                case 8:
                    if (!(message.task && message.task.length))
                        message.task = [];
                    message.task.push(reader.string());
                    break;
                case 9:
                    message.date = reader.string();
                    break;
                case 10:
                    message.type = reader.string();
                    break;
                case 11:
                    message.minRevision = reader.string();
                    break;
                case 12:
                    if (!(message.serialNo && message.serialNo.length))
                        message.serialNo = [];
                    message.serialNo.push(reader.string());
                    break;
                case 13:
                    message.attachedDMC = reader.string();
                    break;
                case 14:
                    if (!(message.part && message.part.length))
                        message.part = [];
                    message.part.push(reader.string());
                    break;
                case 15:
                    message.isS1000D = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a metaData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof model.metaData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {model.metaData} metaData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        metaData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a metaData message.
         * @function verify
         * @memberof model.metaData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        metaData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.applicabilityMD5 != null && message.hasOwnProperty("applicabilityMD5"))
                if (!$util.isString(message.applicabilityMD5))
                    return "applicabilityMD5: string expected";
            if (message.shortTitle != null && message.hasOwnProperty("shortTitle"))
                if (!$util.isString(message.shortTitle))
                    return "shortTitle: string expected";
            if (message.reference != null && message.hasOwnProperty("reference"))
                if (!$util.isString(message.reference))
                    return "reference: string expected";
            if (message.revision != null && message.hasOwnProperty("revision"))
                if (!$util.isString(message.revision))
                    return "revision: string expected";
            if (message.versions != null && message.hasOwnProperty("versions")) {
                if (!Array.isArray(message.versions))
                    return "versions: array expected";
                for (var i = 0; i < message.versions.length; ++i)
                    if (!$util.isString(message.versions[i]))
                        return "versions: string[] expected";
            }
            if (message.dmc != null && message.hasOwnProperty("dmc"))
                if (!$util.isString(message.dmc))
                    return "dmc: string expected";
            if (message.manual != null && message.hasOwnProperty("manual"))
                if (!$util.isString(message.manual))
                    return "manual: string expected";
            if (message.task != null && message.hasOwnProperty("task")) {
                if (!Array.isArray(message.task))
                    return "task: array expected";
                for (var i = 0; i < message.task.length; ++i)
                    if (!$util.isString(message.task[i]))
                        return "task: string[] expected";
            }
            if (message.date != null && message.hasOwnProperty("date"))
                if (!$util.isString(message.date))
                    return "date: string expected";
            if (message.type != null && message.hasOwnProperty("type"))
                if (!$util.isString(message.type))
                    return "type: string expected";
            if (message.minRevision != null && message.hasOwnProperty("minRevision"))
                if (!$util.isString(message.minRevision))
                    return "minRevision: string expected";
            if (message.serialNo != null && message.hasOwnProperty("serialNo")) {
                if (!Array.isArray(message.serialNo))
                    return "serialNo: array expected";
                for (var i = 0; i < message.serialNo.length; ++i)
                    if (!$util.isString(message.serialNo[i]))
                        return "serialNo: string[] expected";
            }
            if (message.attachedDMC != null && message.hasOwnProperty("attachedDMC"))
                if (!$util.isString(message.attachedDMC))
                    return "attachedDMC: string expected";
            if (message.part != null && message.hasOwnProperty("part")) {
                if (!Array.isArray(message.part))
                    return "part: array expected";
                for (var i = 0; i < message.part.length; ++i)
                    if (!$util.isString(message.part[i]))
                        return "part: string[] expected";
            }
            if (message.isS1000D != null && message.hasOwnProperty("isS1000D"))
                if (typeof message.isS1000D !== "boolean")
                    return "isS1000D: boolean expected";
            return null;
        };

        /**
         * Creates a metaData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof model.metaData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {model.metaData} metaData
         */
        metaData.fromObject = function fromObject(object) {
            if (object instanceof $root.model.metaData)
                return object;
            var message = new $root.model.metaData();
            if (object.applicabilityMD5 != null)
                message.applicabilityMD5 = String(object.applicabilityMD5);
            if (object.shortTitle != null)
                message.shortTitle = String(object.shortTitle);
            if (object.reference != null)
                message.reference = String(object.reference);
            if (object.revision != null)
                message.revision = String(object.revision);
            if (object.versions) {
                if (!Array.isArray(object.versions))
                    throw TypeError(".model.metaData.versions: array expected");
                message.versions = [];
                for (var i = 0; i < object.versions.length; ++i)
                    message.versions[i] = String(object.versions[i]);
            }
            if (object.dmc != null)
                message.dmc = String(object.dmc);
            if (object.manual != null)
                message.manual = String(object.manual);
            if (object.task) {
                if (!Array.isArray(object.task))
                    throw TypeError(".model.metaData.task: array expected");
                message.task = [];
                for (var i = 0; i < object.task.length; ++i)
                    message.task[i] = String(object.task[i]);
            }
            if (object.date != null)
                message.date = String(object.date);
            if (object.type != null)
                message.type = String(object.type);
            if (object.minRevision != null)
                message.minRevision = String(object.minRevision);
            if (object.serialNo) {
                if (!Array.isArray(object.serialNo))
                    throw TypeError(".model.metaData.serialNo: array expected");
                message.serialNo = [];
                for (var i = 0; i < object.serialNo.length; ++i)
                    message.serialNo[i] = String(object.serialNo[i]);
            }
            if (object.attachedDMC != null)
                message.attachedDMC = String(object.attachedDMC);
            if (object.part) {
                if (!Array.isArray(object.part))
                    throw TypeError(".model.metaData.part: array expected");
                message.part = [];
                for (var i = 0; i < object.part.length; ++i)
                    message.part[i] = String(object.part[i]);
            }
            if (object.isS1000D != null)
                message.isS1000D = Boolean(object.isS1000D);
            return message;
        };

        /**
         * Creates a plain object from a metaData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof model.metaData
         * @static
         * @param {model.metaData} message metaData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        metaData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.versions = [];
                object.task = [];
                object.serialNo = [];
                object.part = [];
            }
            if (options.defaults) {
                object.applicabilityMD5 = "";
                object.shortTitle = "";
                object.reference = "";
                object.revision = "";
                object.dmc = "";
                object.manual = "";
                object.date = "";
                object.type = "";
                object.minRevision = "";
                object.attachedDMC = "";
                object.isS1000D = false;
            }
            if (message.applicabilityMD5 != null && message.hasOwnProperty("applicabilityMD5"))
                object.applicabilityMD5 = message.applicabilityMD5;
            if (message.shortTitle != null && message.hasOwnProperty("shortTitle"))
                object.shortTitle = message.shortTitle;
            if (message.reference != null && message.hasOwnProperty("reference"))
                object.reference = message.reference;
            if (message.revision != null && message.hasOwnProperty("revision"))
                object.revision = message.revision;
            if (message.versions && message.versions.length) {
                object.versions = [];
                for (var j = 0; j < message.versions.length; ++j)
                    object.versions[j] = message.versions[j];
            }
            if (message.dmc != null && message.hasOwnProperty("dmc"))
                object.dmc = message.dmc;
            if (message.manual != null && message.hasOwnProperty("manual"))
                object.manual = message.manual;
            if (message.task && message.task.length) {
                object.task = [];
                for (var j = 0; j < message.task.length; ++j)
                    object.task[j] = message.task[j];
            }
            if (message.date != null && message.hasOwnProperty("date"))
                object.date = message.date;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = message.type;
            if (message.minRevision != null && message.hasOwnProperty("minRevision"))
                object.minRevision = message.minRevision;
            if (message.serialNo && message.serialNo.length) {
                object.serialNo = [];
                for (var j = 0; j < message.serialNo.length; ++j)
                    object.serialNo[j] = message.serialNo[j];
            }
            if (message.attachedDMC != null && message.hasOwnProperty("attachedDMC"))
                object.attachedDMC = message.attachedDMC;
            if (message.part && message.part.length) {
                object.part = [];
                for (var j = 0; j < message.part.length; ++j)
                    object.part[j] = message.part[j];
            }
            if (message.isS1000D != null && message.hasOwnProperty("isS1000D"))
                object.isS1000D = message.isS1000D;
            return object;
        };

        /**
         * Converts this metaData to JSON.
         * @function toJSON
         * @memberof model.metaData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        metaData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return metaData;
    })();

    model.invMap = (function() {

        /**
         * Properties of an invMap.
         * @memberof model
         * @interface IinvMap
         * @property {Array.<model.ImetaData>|null} [docs] invMap docs
         */

        /**
         * Constructs a new invMap.
         * @memberof model
         * @classdesc Represents an invMap.
         * @implements IinvMap
         * @constructor
         * @param {model.IinvMap=} [properties] Properties to set
         */
        function invMap(properties) {
            this.docs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * invMap docs.
         * @member {Array.<model.ImetaData>} docs
         * @memberof model.invMap
         * @instance
         */
        invMap.prototype.docs = $util.emptyArray;

        /**
         * Creates a new invMap instance using the specified properties.
         * @function create
         * @memberof model.invMap
         * @static
         * @param {model.IinvMap=} [properties] Properties to set
         * @returns {model.invMap} invMap instance
         */
        invMap.create = function create(properties) {
            return new invMap(properties);
        };

        /**
         * Encodes the specified invMap message. Does not implicitly {@link model.invMap.verify|verify} messages.
         * @function encode
         * @memberof model.invMap
         * @static
         * @param {model.IinvMap} message invMap message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        invMap.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.docs != null && message.docs.length)
                for (var i = 0; i < message.docs.length; ++i)
                    $root.model.metaData.encode(message.docs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified invMap message, length delimited. Does not implicitly {@link model.invMap.verify|verify} messages.
         * @function encodeDelimited
         * @memberof model.invMap
         * @static
         * @param {model.IinvMap} message invMap message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        invMap.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an invMap message from the specified reader or buffer.
         * @function decode
         * @memberof model.invMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {model.invMap} invMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        invMap.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.model.invMap();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.docs && message.docs.length))
                        message.docs = [];
                    message.docs.push($root.model.metaData.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an invMap message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof model.invMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {model.invMap} invMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        invMap.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an invMap message.
         * @function verify
         * @memberof model.invMap
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        invMap.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.docs != null && message.hasOwnProperty("docs")) {
                if (!Array.isArray(message.docs))
                    return "docs: array expected";
                for (var i = 0; i < message.docs.length; ++i) {
                    var error = $root.model.metaData.verify(message.docs[i]);
                    if (error)
                        return "docs." + error;
                }
            }
            return null;
        };

        /**
         * Creates an invMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof model.invMap
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {model.invMap} invMap
         */
        invMap.fromObject = function fromObject(object) {
            if (object instanceof $root.model.invMap)
                return object;
            var message = new $root.model.invMap();
            if (object.docs) {
                if (!Array.isArray(object.docs))
                    throw TypeError(".model.invMap.docs: array expected");
                message.docs = [];
                for (var i = 0; i < object.docs.length; ++i) {
                    if (typeof object.docs[i] !== "object")
                        throw TypeError(".model.invMap.docs: object expected");
                    message.docs[i] = $root.model.metaData.fromObject(object.docs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an invMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof model.invMap
         * @static
         * @param {model.invMap} message invMap
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        invMap.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.docs = [];
            if (message.docs && message.docs.length) {
                object.docs = [];
                for (var j = 0; j < message.docs.length; ++j)
                    object.docs[j] = $root.model.metaData.toObject(message.docs[j], options);
            }
            return object;
        };

        /**
         * Converts this invMap to JSON.
         * @function toJSON
         * @memberof model.invMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        invMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return invMap;
    })();

    return model;
})();

module.exports = $root;
