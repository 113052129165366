import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@viewer/shared-module/shared.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MAT_SELECT_CONFIG, MatSelectModule } from "@angular/material/select";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HistoricComponent } from "@viewer/toc-items/historic-module/historic/historic.component";
import { HistoricDialogComponent } from "@viewer/toc-items/historic-module/historic-dialog/historic-dialog.component";
import { HistoricService } from "@viewer/core/toc-items/historic.service";
import { RouterModule } from "@angular/router";
import { HistoryListComponent } from "@viewer/toc-items/historic-module/history-list/history-list.component";
import { NavigationTreeComponent } from "@viewer/toc-items/historic-module/navigation-tree/navigation-tree.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatMenuModule,
    SharedModule,
    MatCardModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatToolbarModule,
    RouterModule,
    MatTabsModule,
    MatDialogModule
  ],
  providers: [
    HistoricService,
    { provide: MAT_SELECT_CONFIG, useValue: { hideSingleSelectionIndicator: true } }
  ],
  declarations: [
    HistoricComponent,
    HistoricDialogComponent,
    HistoryListComponent,
    NavigationTreeComponent
  ],
  exports: [HistoricComponent]
})
export class HistoricModule {}
