import { NgModule, APP_INITIALIZER, Injector } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { environment } from "@viewer-env/environment";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
  MatAutocompleteModule
} from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MAT_SELECT_CONFIG, MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";

import { AppRoutingModule } from "@viewer/app-routing.module";
import { AppComponent } from "@viewer/app.component";
import { CoreModule } from "@viewer/core/core.module";
import { HeaderComponent } from "@viewer/header/header.component";

import { LayoutComponent } from "@viewer/layout/layout.component";
import { MediaService } from "@viewer/media-module/media/media.service";
import { NotFoundComponent } from "@viewer/not-found/not-found.component";
import { PubReplicateResolver } from "@viewer/replication/resolver/pub-replicate.resolver";
import { PubAccessResolver } from "@viewer/pub/resolve/pub-access.resolver";
import { HomeResolver } from "@viewer/home/resolver/home.resolver";
import { SettingsComponent } from "@viewer/settings/settings.component";
import { TocComponent } from "@viewer/toc/toc.component";
import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { ContentProviderComponent } from "@viewer/content-provider/content-provider.component";
import { Title, HammerModule, BrowserModule } from "@angular/platform-browser";
import { HistorySwitchComponent } from "@viewer/history-switch/history-switch.component";
import { XSLService } from "@viewer/content-provider/xsl.service";
import { SharedModule } from "@viewer/shared-module/shared.module";
import { TitleService } from "@viewer/header/title.service";
import { ApplicModalComponent } from "@viewer/applic-modal/applic-modal.component";
import { PubReplicationProgressComponent } from "@viewer/replication/pub-replication-progress/pub-replication-progress.component";
import { PubReplicateService } from "@viewer/replication/service/pub-replicate.service";
import { PdfModule } from "@viewer/pdf/pdf.module";
import { EmptyModule } from "@viewer/core/empty/empty.module";
import { AuthGuard } from "@viewer/core/auth/auth.guard";
import { NoteCanDeactivateGuard } from "@viewer/toc-items/note-module/note/note-can-deactivate-guard.service";
import { NoteModule } from "@viewer/toc-items/note-module/note.module";
import { SettingsHomeComponent } from "@viewer/home/settings-home/settings-home.component";
import { ExtraDocComponent } from "@viewer/extra-doc/extra-doc.component";
import { AuthLoginComponent } from "@viewer/auth-login/auth-login.component";
import {
  ConfService,
  configurationServiceInitializerFactory
} from "@viewer/core/conf/conf.service";
import { BasepubUserService } from "@viewer/core/basepub/basepub-user.service";
import {
  basepubExternalServiceFactory,
  BasepubExternalService
} from "libs/basepub/basepub-external.service";
import { MultilinksService } from "@viewer/shared-module/multilinks/multilinks.service";
import { CmPreviewService } from "@viewer/shared-module/cm-preview/cm-preview.service";
import { IpcPreviewService } from "@viewer/shared-module/ipc-preview/ipc-preview.service";
import { SettingsFormComponent } from "@viewer/settings-form/settings-form.component";
import { RedirectComponent } from "@viewer/redirect/redirect.component";
import { AlsMsmSignatureComponent } from "@viewer/fulltext-module/als-msm-signature/als-msm-signature.component";
import { MessageService } from "@orion2/message-service/message.service";
import { PlayerComponent } from "@viewer/media-module/player/player.component";
import { SynchroModalComponent } from "@viewer/synchro-modal/synchro-modal.component";
import { TransfertTiDialogComponent } from "@viewer/transfert-ti-dialog/transfert-ti-dialog.component";
import { PubLangPipe } from "libs/pipe/pub-lang.pipe";
import { HistoricModule } from "@viewer/toc-items/historic-module/historic.module";
import { translateInitializerFactory } from "@viewer/core/lang/lang.service";
import { PymModalComponent } from "@viewer/pym-modal/pym-modal.component";
import { MatSliderModule } from "@angular/material/slider";
import { NotesButtonModule } from "@viewer/notes-button/notes-button.module";
import { CardPubContainerComponent } from "@viewer/shared-module/card-pub-container/card-pub-container.component";
import { LoadingModalComponent } from "@viewer/loading-modal/loading-modal.component";
import { DateAdapter, MatNativeDateModule, NativeDateAdapter } from "@angular/material/core";
import { LoginModalComponent } from "libs/login-modal/login-modal.component";
import { SbImpactingWarningModule } from "@viewer/sb-impacting-warning/sb-impacting-warning.module";
import { ParseRevisionPipeModule } from "@orion2/utils/ParseRevisionPipeModule";
import { SearchProductsComponent } from "@viewer/home/search-products/search-products.component";
import { SearchProductsService } from "@viewer/home/search-products/service/search-products.service";
import { ContractModalComponent } from "@viewer/contract-modal/contract-modal.component";
import { SearchContractsComponent } from "@viewer/home/search-contracts/search-contracts.component";
import { SearchContractsService } from "@viewer/home/search-contracts/service/search-contracts.service";
import { DatePipe } from "@angular/common";
import { PouchService, ViewerMessageService } from "@viewer/core";
import { FullHttpService } from "libs/http/fullHttp.service";
import { SynchroService } from "@viewer/core/synchro/synchro.service";
import { ConfirmDialogComponent } from "libs/components/confirm-dialog/confirm-dialog.component";
import { InformationDialogComponent } from "libs/components/information-dialog/information-dialog.component";
import { PubLeaveGuard } from "@viewer/core/guards/pub-leave.guard";
import { LastConsultedGuard } from "@viewer/core/guards/last-consulted.guard";
import { SafeDatePipe } from "libs/pipe/safe-date.pipe";
import { MaterialFileInputModule } from "ngx-material-file-input";
import { DownloadProductService } from "libs/download/download-product.service";
import { NewsModule } from "@viewer/news/news.module";
import { PubReplicationComponent } from "@viewer/replication/pub-replication/pub-replication.component";
import { PubDestroyModalComponent } from "@viewer/replication/pub-destroy-modal/pub-destroy-modal.component";
import { PubDestroyService } from "@viewer/replication/service/pub-destroy.service";
import { LayoutHomeComponent } from "@viewer/home/layout-home/layout-home.component";
import { ContractListComponent } from "@viewer/home/contract-list/contract-list.component";
import { HomeTabsComponent } from "@viewer/home/home-tabs/home-tabs.component";
import { LocalProductsComponent } from "@viewer/home/local-products/local-products.component";
import { OnlineProductsComponent } from "@viewer/home/online-products/online-products.component";
import { ContractsService } from "@viewer/home/service/contracts.service";
import { GroupProductsPipe } from "@viewer/home/pipe/group-products.pipe";
import { FilterInfosPipe } from "@viewer/home/pipe/filter-infos.pipe";
import { ProductsService, productsServiceFactory } from "@viewer/home/service/products.service";
import { FormatChipPipe } from "@viewer/home/pipe/format-chip.pipe";
import { HomeRedirectComponent } from "@viewer/home/home-redirect/home-redirect.component";
import { HomeRedirectResolver } from "@viewer/home/resolver/home-redirect.resolver";
import { DisclaimerComponent } from "@viewer/help-module/disclaimer/disclaimer.component";
import { httpLoaderFactory } from "@orion2/utils/functions.utils";
import { DegradedStateInterceptor } from "libs/http/degraded-state/degraded-state.interceptor";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    TocComponent,
    LayoutComponent,
    NotFoundComponent,
    SettingsComponent,
    ContentProviderComponent,
    HistorySwitchComponent,
    ApplicModalComponent,
    LoginModalComponent,
    SynchroModalComponent,
    LayoutHomeComponent,
    PubReplicationProgressComponent,
    ContractListComponent,
    LocalProductsComponent,
    OnlineProductsComponent,
    HomeTabsComponent,
    ExtraDocComponent,
    SettingsHomeComponent,
    RedirectComponent,
    AuthLoginComponent,
    SettingsFormComponent,
    AlsMsmSignatureComponent,
    PlayerComponent,
    TransfertTiDialogComponent,
    PymModalComponent,
    CardPubContainerComponent,
    LoadingModalComponent,
    SearchProductsComponent,
    SearchContractsComponent,
    ContractModalComponent,
    FilterInfosPipe,
    ConfirmDialogComponent,
    InformationDialogComponent,
    DisclaimerComponent,
    PubReplicationComponent,
    PubDestroyModalComponent,
    GroupProductsPipe,
    FormatChipPipe,
    HomeRedirectComponent,
    PubLangPipe
  ],
  imports: [
    NoteModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    MatDialogModule,
    MatToolbarModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatCardModule,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatBadgeModule,
    MatChipsModule,
    MatListModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    NewsModule,
    MatTabsModule,
    MatMenuModule,
    EmptyModule,
    MatRadioModule,
    HistoricModule,
    MaterialFileInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ParseRevisionPipeModule,
    AppRoutingModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.service_worker
    }),
    PdfModule,
    MatSliderModule,
    HammerModule,
    NotesButtonModule,
    SbImpactingWarningModule
  ],
  providers: [
    ConfService,
    {
      provide: APP_INITIALIZER,
      useFactory: configurationServiceInitializerFactory,
      deps: [ConfService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: translateInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    {
      provide: ProductsService,
      useFactory: productsServiceFactory,
      deps: [BasepubUserService, PouchService, ConfService]
    },
    ContractsService,
    MessageService,
    PubReplicateService,
    PubDestroyService,
    DownloadProductService,
    BasepubUserService,
    {
      provide: BasepubExternalService,
      useFactory: basepubExternalServiceFactory,
      deps: [ViewerMessageService, TranslateService, FullHttpService]
    },
    PubReplicateResolver,
    PubAccessResolver,
    HomeResolver,
    HomeRedirectResolver,
    MediaService,
    Title,
    MultilinksService,
    CmPreviewService,
    IpcPreviewService,
    XSLService,
    TitleService,
    AuthGuard,
    MultilinksService,
    NoteCanDeactivateGuard,
    PubLeaveGuard,
    LastConsultedGuard,
    SynchroService,
    { provide: DateAdapter, useClass: NativeDateAdapter },
    SearchProductsService,
    SearchContractsService,
    SafeDatePipe,
    DatePipe,
    PubLangPipe,
    { provide: HTTP_INTERCEPTORS, useClass: DegradedStateInterceptor, multi: true },
    { provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: { hideSingleSelectionIndicator: true } },
    { provide: MAT_SELECT_CONFIG, useValue: { hideSingleSelectionIndicator: true } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
