// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-dialog-actions {
  justify-content: center;
  margin-bottom: 20px;
  padding: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInB1Yi1kZXN0cm95LW1vZGFsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0UsdUJBQUE7RUFDQSxtQkFBQTtFQUNBLFVBQUE7QUFERiIsImZpbGUiOiJwdWItZGVzdHJveS1tb2RhbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgXCJsaWJzL3RoZW1lL29yaW9uLXRoZW1lXCI7XG5cbi5tYXQtbWRjLWRpYWxvZy1hY3Rpb25zIHtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIG1hcmdpbi1ib3R0b206IDIwcHg7XG4gIHBhZGRpbmc6IDA7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/replication/pub-destroy-modal/pub-destroy-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,mBAAA;EACA,UAAA;AADF;AACA,weAAwe","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n.mat-mdc-dialog-actions {\n  justify-content: center;\n  margin-bottom: 20px;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
