import { PouchService, Store } from "@viewer/core";
import { Injectable, OnDestroy } from "@angular/core";
import { MediaObject } from "@orion2/models/couch.models";
import { runInAction, reaction, IReactionDisposer } from "mobx";
import { SynchroInformation } from "@viewer/content-provider/synchro-dom-manipulator";
import { XSLService } from "@viewer/content-provider/xsl.service";
import { PubLangPipe } from "libs/pipe/pub-lang.pipe";
import { getMediaId, getThumbId, getMajorRevision } from "@orion2/utils/functions.utils";
import xpath, { SelectedValue } from "xpath";
import { Util } from "@orion2/utils/datamodule.utils";

@Injectable()
export class MediaService implements OnDestroy {
  private readonly welcomeMediaKey = "loap_welcome_png";

  private _currentDmcMap = new Map<string, Map<string, Promise<MediaObject>>>();
  private pubLangPipe: PubLangPipe;
  private duObjectDisposer: IReactionDisposer;

  private welcomeMedia: string;

  constructor(
    private pouchService: PouchService,
    private store: Store,
    private xslService: XSLService
  ) {
    this.pubLangPipe = new PubLangPipe();
    this.duObjectDisposer = reaction(
      () => this.store.duObject,
      () => {
        const dmc = this.store.duObject.dmc;
        const xml = this.store.duObject.xml;
        // we don't need to get thumbs on search, loap or toc items
        if (dmc && !dmc.match(/^preprint|^document|^sb|^superseded|^search$|^loap$/)) {
          this.findAllThumbs(dmc);
        }
        if (Util.isWDMs1000d(xml)) {
          this.setWDMS1000DNumberingMap(xml);
        } else {
          this.setNumberingMap();
        }
        // SPEC: For IPC S1000D 2D, we want to find all hotspots and set the ipcHotspotMap
        if (
          xml &&
          Util.isIpc(xml) &&
          this.store.isS1000D &&
          !this.store.ipcHotspotMap.has(this.store.currentDMC)
        ) {
          this.findAllHotspots();
        }
      },
      { name: "mediaService-findAllThumb", fireImmediately: true }
    );

    reaction(
      () => this.store.publicationID,
      () => {
        this.welcomeMedia = undefined;
      }
    );
  }

  /**
   * We store the medias of 10 dmc maximum.
   * If we navigate on more, we remove the first dmc medias
   */
  get cacheMap(): Map<string, Promise<MediaObject | Blob>> {
    if (!this.store.currentDMC) {
      throw new Error("This.store.currentDMC should be set before calling this function");
    }
    const currentCacheMap = this._currentDmcMap.get(this.store.currentDMC);
    if (!currentCacheMap) {
      const _cacheMap = new Map<string, Promise<MediaObject>>();
      this._currentDmcMap.set(this.store.currentDMC, _cacheMap);

      if (this._currentDmcMap.size > 10) {
        // I want to remove the first key in the map
        this._currentDmcMap.delete(Array.from(this._currentDmcMap.keys())[0]);
      }
      return _cacheMap;
    }

    return currentCacheMap;
  }

  ngOnDestroy(): void {
    this.duObjectDisposer();
  }

  /**
   * Return media data from DB
   *
   * @param idMedia
   * @returns
   * @memberof MediaService
   */
  getMedia(idMedia: string): Promise<MediaObject> {
    if (!this.cacheMap.get(idMedia)) {
      const media = this.pouchService.mediaCaller.get(idMedia).then((res: MediaObject) => {
        // if media are not found and it's a thumbs, we try to get in place the media svg corresponding
        if (!res && idMedia.includes("thumb__")) {
          return this.pouchService.mediaCaller.get(idMedia.split("thumb__")[1]);
        }

        // in case of deeplink or refresh on 3D icn, getMedia is called before getBlob
        // and we want to set cacheMap with a Promise<Blob>
        if (res?.FORMAT === "smg") {
          return this.pouchService.mediaCaller
            .getAttachmentsBlob(idMedia)
            .then((blob: Blob) => blob)
            .catch(() => undefined);
        }

        return res;
      });
      this.cacheMap.set(idMedia, media as Promise<MediaObject>);
    }
    return this.cacheMap.get(idMedia) as Promise<MediaObject>;
  }

  /**
   * Return media 3D blob from DB
   *
   * @param idMedia
   * @returns
   * @memberof MediaService
   */
  getBlob(idMedia: string): Promise<Blob> {
    if (!this.cacheMap.has(idMedia)) {
      const media = this.pouchService.mediaCaller
        .getAttachmentsBlob(idMedia)
        .then((res: Blob) => res)
        .catch(() => undefined);
      this.cacheMap.set(idMedia, media as Promise<Blob>);
    }
    return this.cacheMap.get(idMedia) as Promise<Blob>;
  }

  /**
   * Return media object from cache
   *
   * @param id
   * @returns
   * @memberof MediaService
   */
  getThumb(id: string): Promise<MediaObject> {
    const thumbId = getThumbId(this.store.isLegacyImport, this.store.currentDMC, id);
    return this.getMedia(thumbId);
  }

  /**
   * Call the DB to get all thumbnails and cache them
   *
   * @param dmc
   * @returns
   * @memberof MediaService
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  async findAllThumbs(dmc: string): Promise<MediaObject[]> {
    try {
      const flatIllusIDs = [].concat(...this.store.duObject.illusIDs);
      const flatThumbIDs = flatIllusIDs.map(id => "thumb__" + id);

      // In first we try to get all thumbs with one call to the database only on the first time on this DU
      if (!this._currentDmcMap.get(dmc)) {
        const res = await this.pouchService.mediaCaller.getThumbs(dmc, flatThumbIDs);
        res.forEach((media: MediaObject) => {
          if (media.data) {
            this.cacheMap.set(media._id, Promise.resolve(media));
          }
        });
      }

      // if some thumbs are missing on database, they are missing on cacheMap too
      // in this case, we call getThumb() and fallback on real media by calling getMedia

      flatIllusIDs.forEach((id: string) => {
        const thumbId = getThumbId(this.store.isLegacyImport, dmc, id);
        if (!this.cacheMap.get(thumbId)) {
          // we want to set cacheMap to real media
          this.getThumb(id);
        }
      });

      const thumbsId = Array.from(this.cacheMap.keys()).filter(mediaId =>
        mediaId.startsWith("thumb__")
      );
      const mediaThumbsProm = thumbsId.map(id => this.cacheMap.get(id));
      return Promise.all(mediaThumbsProm).then(thumbs => this.sortMedia(thumbs));
    } catch (e) {
      console.error(`Thumbs not found for ${dmc} `);
      runInAction(() => {
        this.store.mediaThumbs = [];
      });
    }
  }

  /**
   * Return the LOAP medias
   *
   * @returns
   * @memberof MediaService
   */
  public welcome(): Promise<string> {
    if (this.store.pubInfo.isPackagePDF) {
      return Promise.resolve<string>("");
    } else {
      return this.welcomeMedia
        ? Promise.resolve<string>(this.welcomeMedia)
        : this.getActualWelcome();
    }
  }

  /**
   * Synchronization video/step
   */
  public findAssociateSynchroInfo(currentTime: number): SynchroInformation | undefined {
    let correctTimeIndex;
    const timeIterator = Array.from(this.store.synchronizationInformation.keys());
    timeIterator.forEach(timeReferential => {
      if (+timeReferential <= currentTime) {
        correctTimeIndex = timeReferential;
      }
    });
    return this.store.synchronizationInformation.get(correctTimeIndex);
  }

  /**
   * Synchronization step/video
   */
  public getTimeForStep(stepId: string): number | undefined {
    if (this.store.synchronizationInformation) {
      const timeIterator = Array.from(this.store.synchronizationInformation.values());
      return +timeIterator.find((value: SynchroInformation) => value.id === stepId)?.frame || 0;
    }
    return 0;
  }

  /**
   * Find all hotspots in IPC S1000D 2D and create the ipcHotspotMap
   * We map curentDMC with an array (index is the hotspotId and value is the ICN)
   */
  public findAllHotspots(): Promise<void> {
    const mediaIds: string[] = this.store.duObject.illusIDs.flat();
    if (mediaIds) {
      if (!this.store.ipcHotspotMap.get(this.store.currentDMC)) {
        this.store.ipcHotspotMap.set(this.store.currentDMC, []);
      }

      return Promise.all(
        mediaIds.map((mediaId: string) =>
          this.getMedia(getMediaId(this.store.isLegacyImport, this.store.currentDMC, mediaId))
        )
      )
        .then((medias: MediaObject[]) => {
          medias
            .filter((media: MediaObject) => media.data)
            .forEach((media: MediaObject) => {
              // Create SVG from media and get all hotspots
              const svgString = atob(media.data);
              const svgDoc = new DOMParser().parseFromString(svgString, "text/html");

              const hotspots = svgDoc.querySelectorAll("#hotspotLayer g[apsname]");

              hotspots?.forEach((hotspot: Element) => {
                const apsname = hotspot.getAttribute("apsname");
                this.store.ipcHotspotMap.get(this.store.currentDMC)[apsname] = media.NOM_LOGIQUE;
                if (this.store.pendingHotspot === apsname) {
                  this.store.currentMediaId = media.NOM_LOGIQUE;
                }
              });
            });
        })
        .catch((e: Error) => {
          console.error("error createHotspotMap", e.toString());
        });
    }
  }

  /**
   * Set mediaNumberingMap and chipNumberingMap
   * We map each ICN with a numbering string
   * Media numbering string is like "Figure X/Y Sheet I/J"
   * Chip numbering string is like "X-Y"
   * We use the pub lang translation for Figure and Sheet
   */
  public setNumberingMap(): void {
    const illus = this.store.duObject.illusIDs;
    if (!illus) {
      return;
    }
    // i is figure index
    // j is sheet index
    for (let i = 0; i < illus.length; i++) {
      for (let j = 0; j < illus[i].length; j++) {
        const figureNbr = i + 1;
        const sheetNbr = j + 1;
        const totalFigures = illus.length;
        const totalSheets = illus[i].length;

        const lang = this.store.pubInfo.lang;
        const sheetNumberPerTotalSheetsText =
          totalSheets > 1
            ? ` ${this.pubLangPipe.translate("textDU.sheet", lang)} ${sheetNbr}/${totalSheets}`
            : "";
        const mediaNumbering = `${this.pubLangPipe.translate(
          "textDU.figure",
          lang
        )} ${figureNbr}/${totalFigures}${sheetNumberPerTotalSheetsText}`;

        const sheetNumberText =
          totalSheets > 1 ? ` ${this.pubLangPipe.translate("textDU.sheet", lang)} ${sheetNbr}` : "";
        const textNumbering = `${this.pubLangPipe.translate(
          "textDU.figure",
          lang
        )} ${figureNbr}${sheetNumberText}`;

        const chipNumbering = figureNbr + (totalSheets > 1 ? "-" + sheetNbr : "");

        this.store.mediaNumberingMap.set(illus[i][j], mediaNumbering);
        this.store.chipNumberingMap.set(illus[i][j], chipNumbering);
        this.store.textNumberingMap.set(illus[i][j], textNumbering);
      }
    }
  }

  public setWDMS1000DNumberingMap(xml: Document): void {
    const illus = this.store.duObject.illusIDs;
    if (!illus) {
      return;
    }
    for (const figure of illus) {
      for (const sheet of figure) {
        const idPath = `string(//graphic[@infoEntityIdent='${sheet}']/@id)`;
        const id = xpath.select(idPath, xml).toString();
        const mediaNumber = id.replace(/fig-(\d+)-gra-(\d+)/, "Fig. $1 Sheet $2");
        const thumbNumber = id.replace(/fig-(\d+)-gra-(\d+)/, "$1-$2");
        this.store.mediaNumberingMap.set(sheet, mediaNumber);
        this.store.chipNumberingMap.set(sheet, thumbNumber);
        this.store.textNumberingMap.set(sheet, mediaNumber);
      }
    }
  }

  public getFigureSheetTitles(xmlNodes: Document, isIpc: boolean): Promise<Map<string, string>[]> {
    // we want to have the list of figure attached to the duObject
    // all figure have one or many sheet represented by an unique id (ICN)
    // this list is used for get thumbs and for display numbering figure and numbering chip thumbs (media.service)
    // data exemple : illusIDs = [ [icn1], [icn2, icn3, icn4] ]
    // here we have 2 figures => figure1 have only one sheet (icn1) and figure2 have 3 sheets (icn2, icn3, icn4)

    // We have 3 differents rules for get figure ids
    // IPC ATA100 + S1000D => ENTITE_GRAPHIQUE/NOM_LOGIQUE
    // Fulltext ATA100 => GRAPHIC/SHEET/@GNBR
    // Fulltext S1000D => figure/graphic/@infoEntityIdent
    // figurePath, sheetsPath, idPath, titlePath work for ATA and S1000D datamodule
    let figurePath = isIpc
      ? "/dmodule//figure|/dmodule//FIGURE"
      : "/dmodule//figure|/dmodule//multimedia|/dmodule//GRAPHIC";
    let sheetsPath = isIpc
      ? "graphic|GRAPHIC"
      : "graphic|multimediaObject[@multimediaType!='3D']|SHEET";
    let idPath = isIpc ? "string(@infoEntityIdent|@BOARDNO)" : "string(@infoEntityIdent|@GNBR)";
    const titlePath = "./title|./TITLE";
    const figureTitlePath = "../title|../TITLE";

    let figureNodes = xpath.select(figurePath, xmlNodes);
    // some IPC datamodule are badly authored (S1000D) and we have no figure tags, in this case we are basing on ENTITE_GRAPHIQUE tags
    if (!figureNodes.length && isIpc) {
      figurePath = "/dmodule/ENTITE_GRAPHIQUE/FORMAT[text()!='smg']/..";
      sheetsPath = "NOM_LOGIQUE";
      idPath = "string(node())";
    }
    figureNodes = xpath.select(figurePath, xmlNodes);
    return Promise.all(
      figureNodes.map((figure: Node) => {
        const sheetNodes = xpath.select(sheetsPath, figure);
        return Promise.all(
          sheetNodes.map((sheet: Node) => {
            const id = xpath.select(idPath, sheet).toString();
            let childNode = xpath.select(titlePath, sheet, true) as Node;
            if (!childNode) {
              childNode = xpath.select(figureTitlePath, sheet, true) as Node;
            }
            return this.generateTitleLabel(childNode)
              .then((title: string) => title.replace(/\[Fig \d+\]/gi, ""))
              .then((title: string) => ({ id, title }));
          })
        ).then(sheets => new Map<string, string>(sheets.map(sheet => [sheet.id, sheet.title])));
      })
    ).then((figures: Map<string, string>[]) => figures.filter(sheets => sheets.size > 0));
  }

  private generateTitleLabel(titleNode: Node): Promise<string> {
    if (!titleNode) {
      return Promise.resolve("");
    }

    return Promise.all(
      xpath.select("child::node()", titleNode).map((childNode: Node) => {
        if (!childNode) {
          return Promise.resolve("");
        }

        if (childNode.nodeName === "#text") {
          return Promise.resolve(childNode.nodeValue);
        }

        if (childNode.nodeName === "inlineSignificantData") {
          return Promise.resolve(childNode.textContent);
        }

        return this.mdeffTitle(childNode).then(
          titlePart =>
            titlePart ||
            this.sbeffTitle(childNode) ||
            this.faultNodeTitle(childNode) ||
            this.specialPartTitle(childNode) ||
            this.unknownNode(childNode)
        );
      })
    ).then(titles => titles.join(""));
  }

  private mdeffTitle(childNode: Node): Promise<string> {
    const PRE_MDEFF_PREFIX_TEXT = "PRE";
    const POST_MDEFF_PREFIX_TEXT = "POST";
    const xpathSelection = `
      concat(
        substring(
          "[PRE_MDEFF_PREFIX_TEXT_LABEL] [MOD_LABEL] ",
          1,
          number(string(./MDEFF/@MDCOND)="${PRE_MDEFF_PREFIX_TEXT}") * string-length("[PRE_MDEFF_PREFIX_TEXT_LABEL] [MOD_LABEL] ")
        ),
        substring(
          "[POST_MDEFF_PREFIX_TEXT_LABEL] [MOD_LABEL] ",
          1,
          number(string(./MDEFF/@MDCOND)="${POST_MDEFF_PREFIX_TEXT}") * string-length("[POST_MDEFF_PREFIX_TEXT_LABEL] [MOD_LABEL] ")
        ),
        string(./MDEFF/@MDNBR)
      )
    `;
    return this.xslService
      .evaluateXPathWithTranslation(xpathSelection, childNode)
      .then((title: SelectedValue[]) => title.toString());
  }

  private sbeffTitle(childNode: Node): string {
    const SB_TEXT = "SB";
    const title = (
      xpath.select(
        `concat(string(./SBEFF/@SBCOND), " ${SB_TEXT} ", string(./SBEFF/@SBNBR))`,
        childNode,
        true
      ) as string
    ).trim();
    return title !== SB_TEXT ? title : "";
  }

  private faultNodeTitle(childNode: Node): string {
    return xpath.select("string(./@FCODE)", childNode, true) as string;
  }

  private specialPartTitle(childNode: Node): string {
    return childNode.nodeName === "SPECIALPART"
      ? xpath
          .select("./text()", childNode)
          .map((text: Node) => text.nodeValue)
          .join("")
      : "";
  }

  private unknownNode(childNode: Node): string {
    console.warn(`Unknown node name in generateTitleLabel() : ${childNode.nodeName}`);
    return "";
  }

  /**
   * Sort media
   */
  private sortMedia(thumbs): MediaObject[] {
    // SPEC: We want to mediaThumbs have the same order than this.store.duObject.illusIDs
    const mediaThumbs = [];
    this.store.duObject.illusIDs.forEach((figure: string[]) => {
      figure.forEach((mediaId: string) => {
        mediaThumbs.push(thumbs.find(thumb => thumb?.NOM_LOGIQUE === mediaId));
      });
    });
    runInAction(() => {
      this.store.mediaThumbs = mediaThumbs;
    });
    return mediaThumbs;
  }

  /**
   * Retrieves all available welcome image of the publication and try to find the most adapt one for the revision
   * if it don't exist, return default this.welcomeMedia value
   * @return {Promise<string>} A Promise that resolves to the actual welcome image.
   */
  private getActualWelcome(): Promise<string> {
    const majorRevision: string = getMajorRevision(
      this.store.pubInfo.revision,
      this.store.pubInfo.isPackagePDF
    );
    //Old id of welcome images was "loap_welcome_png"
    //New id of welcome images is "loap_welcome_{major_revision}"
    return this.pouchService.mediaCaller
      .getWelcome()
      .then((medias: MediaObject[]) => {
        const welcomeMediaData: string =
          medias.find((media: MediaObject) => media._id.endsWith(majorRevision))?.data ||
          medias.find((media: MediaObject) => media._id === this.welcomeMediaKey)?.data;
        this.welcomeMedia = `data:image/png;base64,${welcomeMediaData}`;
        return this.welcomeMedia;
      })
      .catch(() => Promise.resolve<string>(this.welcomeMedia));
  }
}
