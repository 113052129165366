import { PubDoc } from "@orion2/models/couch.models";
import { ParseRevisionPipe } from "libs/utils/src/ParseRevisionPipe";

/**
 * Get the publication revision in the format that should be displayed to the user.
 */
export function getDisplayedRevision(pub: PubDoc): string {
  if (pub.manualCode === "ORIO") {
    const parseRevision = new ParseRevisionPipe();
    return parseRevision.transform(pub.revision);
  } else {
    return pub.indiceVersion !== "0" ? `${pub.revNumber}.${pub.indiceVersion}` : pub.revNumber;
  }
}

/**
 * Test part.mpn to know if it NO NUMBER
 * The test is case insensitive and theseparator between the 2 words can be "-", "_" or " "
 *
 * @param mpn string to test
 * @returns true if the string tested matches with the regexp
 */
export function isNoNumber(mpn: string): boolean {
  return /^(no[-_\s]number$)/gi.test(mpn);
}

export function getDisplayTitle(pub: PubDoc): string {
  return pub.verbatimText || pub.packageId.split("-")[0].toUpperCase();
}

export function getSensitiveMessage(occurenceCode: string): string {
  switch (occurenceCode) {
    // Occurence Code "148126" = SA330 HC MK2 - MOd UK
    case "148126":
      return "UK OFFICIAL SENSITIVE";
    default:
      return undefined;
  }
}

// we check if we are on fullscreen view
// we use it to know if we should open the note drawer
export function isFullscreenView(url: string, view: string) {
  return (
    url.match(/fullscreen|stepbystep|\/ipc\/|\/pdf\//g) != null || view === "preprint_fullscreen"
  );
}
