import xpath from "xpath";

export const ARRAY_SEPARATOR = "~~";
export const OBJECT_SEPARATOR = "@@";
export const STRING_SEPARATOR = "^^";

export class Util {
  public static cleanXml(xml: xpath.SelectedValue[]) {
    const str = xml.toString();
    return str.replace(/(\r\n|\n|\r)/gm, " ").trim();
  }

  /**
   * Return true if document is S1000D Norme
   *
   * @param xmlDoc
   */
  public static checkS1000D(xmlDoc: Node): boolean {
    if (!xmlDoc) {
      return false;
    }
    // we redefine the select function of xpath to access the namespace xsi
    const select = xpath.useNamespaces({
      xsi: "http://www.w3.org/2001/XMLSchema-instance"
    });
    // The builder team added a new tag to check if a DM is an S1000D
    // This helps us if the verification process changes in the future, no changes will be necessary here
    const wp6IsS1000D = select("string(/dmodule/WP6Status/isS1000D)", xmlDoc).toString();
    if (wp6IsS1000D) {
      return wp6IsS1000D === "true";
    }
    const xsi = select("string(/dmodule/@xsi:noNamespaceSchemaLocation)", xmlDoc);
    // we check schedul.xsd for msm tasks because the field s1000d.org is not present
    // we should have http://www.s1000d.org/ on all S1000D xml document
    return !!(
      xsi.includes("schedul.xsd") ||
      xsi.includes("descript.xsd") ||
      xsi.includes("s1000d.org") ||
      xsi.includes("proced.xsd")
    );
  }

  public static checkVendors(xmlDoc: Document): boolean {
    if (!xmlDoc) {
      return false;
    }
    const modelIdentCode = xmlDoc.querySelector("dmIdent > dmCode")?.getAttribute("modelIdentCode");

    const systemDiffCode = xmlDoc.querySelector("dmIdent > dmCode")?.getAttribute("systemDiffCode");

    const enterpriseCode = xmlDoc.querySelector("originator")?.getAttribute("enterpriseCode");

    return (
      (systemDiffCode === "B" && enterpriseCode !== "F0210") ||
      (modelIdentCode !== "H160" && Util.checkS1000D(xmlDoc))
    );
  }

  /**
   * Return true if document is IPC S1000D Norme
   *
   * @param xmlDoc
   */
  public static checkPartsS1000D(xmlDoc: Document): boolean {
    if (!xmlDoc) {
      return false;
    }
    // We just need to check if tag illustratedPartsCatalog exists
    return !!xpath.select("boolean(/dmodule/content/illustratedPartsCatalog)", xmlDoc);
  }

  /**
   * Return true if document is MSM / ALS S1000D Norme
   *
   * @param xmlDoc
   */
  public static checkTasksS1000D(xmlDoc: Document): boolean {
    if (!xmlDoc) {
      return false;
    }
    // We just need to check if tag maintPlanning exists
    return !!xpath.select("boolean(/dmodule/content/maintPlanning)", xmlDoc);
  }

  // we check if wrngdata
  public static isWDMs1000d(xmlDoc: Document): boolean {
    if (!xmlDoc) {
      return false;
    }

    return !!xpath.select("boolean(/dmodule/content/wiringData)", xmlDoc);
  }

  /**
   * Special case : Verify if the DMC is S1000D and has exactly one illustration and its a PDF
   *
   * @param xmlDoc
   * @returns
   * @memberof XSLService
   */
  public static hasOnePdfAndS1000D(xmlDoc: Document): boolean {
    if (!xmlDoc) {
      return false;
    }
    // The builder team added a new tag to check if a DM is an S1000D
    // This helps us if the verification process changes in the future, no changes will be necessary here
    const graphics = xmlDoc.querySelectorAll("ENTITE_GRAPHIQUE");

    return (
      Util.checkS1000D(xmlDoc) &&
      graphics?.length === 1 &&
      graphics[0]?.querySelector("FORMAT")?.innerHTML === "pdf"
    );
  }

  /**
   * Use the xsd to definine if the given xsd is
   *
   * @param xsd
   */
  public static isIpc(xmlDoc: Document): boolean {
    return !!xpath.select(
      "boolean(/dmodule/content/illustratedPartsCatalog | /dmodule/content/IPC)",
      xmlDoc
    );
  }

  // we check if a task is present.
  public static isMSM(xmlDoc: Document): boolean {
    return !!xpath.select(
      "boolean(/dmodule/content/maintPlanning | /dmodule/content//MAINTASK)",
      xmlDoc
    );
  }

  // Use to define class mma-wrap and add padding on ol
  public static isMMA(xmlNodes: Document): boolean {
    const manualLabel = xmlNodes.evaluate(
      "dmodule/WP6Status/manualLabel",
      xmlNodes,
      undefined,
      XPathResult.STRING_TYPE,
      undefined
    );

    return manualLabel.stringValue === "MMA" ? true : false;
  }

  /**
   * Use XML to determine if it's a PDF DMC
   *
   * @param xmlNode
   * @returns
   * @memberof XSLService
   */
  public static isPdfManual(xmlNode: Document): boolean {
    return xmlNode?.querySelector("pubMedia")?.getAttribute("pubMediaType") === "PDF";
  }
}
