// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .loading-dialog-container {
  max-width: 100vw !important;
}

.loading-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 24px;
}
.loading-modal o-loading ::ng-deep .loading-wrapper {
  position: unset;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvYWRpbmctbW9kYWwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRSwyQkFBQTtBQURGOztBQUlBO0VBQ0UsbUJBQUE7RUFDQSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxhQUFBO0FBREY7QUFJSTtFQUNFLGVBQUE7QUFGTiIsImZpbGUiOiJsb2FkaW5nLW1vZGFsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcImxpYnMvdGhlbWUvb3Jpb24tdGhlbWVcIjtcblxuOjpuZy1kZWVwIC5sb2FkaW5nLWRpYWxvZy1jb250YWluZXIge1xuICBtYXgtd2lkdGg6IDEwMHZ3ICFpbXBvcnRhbnQ7XG59XG5cbi5sb2FkaW5nLW1vZGFsIHtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgcGFkZGluZzogMjRweDtcblxuICBvLWxvYWRpbmcge1xuICAgIDo6bmctZGVlcCAubG9hZGluZy13cmFwcGVyIHtcbiAgICAgIHBvc2l0aW9uOiB1bnNldDtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/loading-modal/loading-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,2BAAA;AADF;;AAIA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AADF;AAII;EACE,eAAA;AAFN;AACA,4wBAA4wB","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n::ng-deep .loading-dialog-container {\n  max-width: 100vw !important;\n}\n\n.loading-modal {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  padding: 24px;\n\n  o-loading {\n    ::ng-deep .loading-wrapper {\n      position: unset;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
