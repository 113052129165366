import { Store, NoteService, BookmarkService } from "@viewer/core";
import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { Router, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SettingsFormComponent } from "@viewer/settings-form/settings-form.component";
import { RoleService } from "@viewer/core/role/role.service";
import { PrintService } from "@viewer/core/print/print.service";
import { Subscription } from "rxjs";
import { SynchroModalComponent } from "@viewer/synchro-modal/synchro-modal.component";
import { reaction, IReactionDisposer } from "mobx";
import { PymModalComponent } from "@viewer/pym-modal/pym-modal.component";
import { InfoDialogComponent } from "@viewer/shared-module/info-dialog/info-dialog.component";
import { BookmarkEditComponent } from "@viewer/toc-items/bookmark-module/bookmark-edit/bookmark-edit.component";
import { MessageService } from "@orion2/message-service/message.service";
import { BookmarkTreeService } from "@viewer/toc-items/bookmark-module/bookmark-list/bookmark-tree.service";
import { isFullscreenView } from "@orion2/utils/front.utils";

@Component({
  selector: "o-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent implements OnInit, OnDestroy {
  @Input() settingsSidenav: MatSidenav;
  public isBookmarked: boolean;
  public canBeBookmarked: boolean;
  public canHaveNotes: boolean;
  public canBePym: boolean;
  public makeBookmarks: boolean;
  public makePrivateNote: boolean;
  public makePym: boolean;
  private userReactionDisposer: IReactionDisposer;
  private subscriptions = new Subscription();

  constructor(
    public store: Store,
    private dialog: MatDialog,
    private router: Router,
    private noteService: NoteService,
    private bookmarkService: BookmarkService,
    private bookmarkTreeService: BookmarkTreeService,
    private translate: TranslateService,
    private messageService: MessageService,
    private printService: PrintService,
    private cd: ChangeDetectorRef,
    private roleService: RoleService
  ) {}

  ngOnInit(): void {
    this.checkNavigation();

    /* get router events to check if this url can be bookmarkable or not */
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkNavigation();
      }
    });

    this.userReactionDisposer = reaction(
      () => this.store.user,
      () => {
        this.makeBookmarks = this.store.user.makeBookmark;
        this.makePrivateNote = this.store.user.makePrivateNote;
        this.makePym = this.store.user.makePym;
        this.cd.markForCheck();
      },
      { fireImmediately: true }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.userReactionDisposer) {
      this.userReactionDisposer();
    }
  }

  public close() {
    this.settingsSidenav.close();
  }

  /**
   * Opens the info popup
   */
  public openInfoDialog(): void {
    this.close();

    if (this.store.duStatus) {
      this.dialog.open(InfoDialogComponent, {
        panelClass: "info-dialog-container",
        data: { content: this.store.duStatus.outerHTML }
      });
    }
  }

  public openSettings() {
    this.settingsSidenav.close();
    this.dialog.open(SettingsFormComponent, {
      panelClass: "settings-dialog-container"
    });
  }

  /**
   * Close Kebab menu before creating a note
   *
   * @memberof SettingsComponent
   */
  public createNote(): void {
    if (isFullscreenView(this.router.url, this.store.view) || this.store.isEncapsulatedPdf) {
      this.store.noteDrawerOpen = true;
    }
    this.close();
    this.noteService.createNote();
  }

  public createBookmarks(): void {
    const dialog = this.dialog.open(BookmarkEditComponent, {
      data: {
        mode: "add"
      },
      panelClass: "bookmark-edit"
    });
    dialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.isBookmarked = true;
        this.messageService.success(this.translate.instant("tocItem.saved"));
      } else {
        this.messageService.warning(this.translate.instant("bookmarks.edit.canceled"));
      }
    });
  }

  public print(): void {
    this.settingsSidenav.close().then(() => {
      this.printService.printFinal();
    });
  }

  public synchronize(): void {
    this.dialog.open(SynchroModalComponent, {
      panelClass: "settings-dialog-container",
      disableClose: true
    });
  }

  public login(): Promise<void> {
    return this.roleService
      .login()
      .then(() => {
        this.cd.detectChanges();
      })
      .catch(error => console.error("Login error : ", error));
  }

  public logout(): Promise<boolean> {
    return this.roleService.logout().catch(error => {
      console.error("Logout error : ", error);
      return false;
    });
  }

  public openPymModal(): void {
    if (this.dialog.openDialogs.length === 0) {
      this.dialog.open(PymModalComponent, {
        panelClass: "pym-container",
        data: {
          source: [{ dmc: this.store.currentDMC }],
          filename: this.store.currentDMC
        }
      });
    }
  }

  private checkNavigation(): void {
    const url = this.router.url;
    this.canHaveNotes = /fulltext|pdf|ipc|wdm|front-matter/.test(url);
    this.canBePym = /fulltext|pdf|ipc/.test(url);
    this.canBeBookmarked = url.includes("/du/") && !url.includes("loap");
    if (this.canBeBookmarked) {
      this.bookmarkService.isBookmarked().then(res => {
        this.isBookmarked = res;
      });
    }
  }
}
