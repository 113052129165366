import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Params } from "@angular/router";
import { Store } from "@viewer/core";
import {
  MultiLinkMeta,
  TocInfoWithAnchor
} from "@viewer/shared-module/multilinks/multilinks.service";
import { OrionCardData } from "@viewer/shared-module/orion-card/orion-card.component";

@Component({
  selector: "o-multilinks",
  templateUrl: "./multilinks.component.html",
  styleUrls: ["./multilinks.component.scss"]
})
export class MultilinksComponent implements OnInit {
  links: TocInfoWithAnchor[] = [];

  constructor(
    public dialogRef: MatDialogRef<MultilinksComponent>,
    public store: Store,
    @Inject(MAT_DIALOG_DATA) public data: Pick<MultiLinkMeta, "links">
  ) {}

  ngOnInit() {
    this.links = this.data.links;
  }

  public close() {
    this.dialogRef.close();
  }

  public getOrionCardData(link: TocInfoWithAnchor): OrionCardData {
    return {
      dmc: link.metadata.dmc,
      title: link.metadata.shortTitle,
      subtitle: link.metadata.reference + this.getAdditionalRef(link.anchor),
      revisionMark: link.metadata.revision,
      versions: link.metadata.versions,
      applicabilityMD5: link.metadata.applicabilityMD5,
      showAdditionalInfos: true,
      forceSubTitle: true
    };
  }

  public getUrl(dmc: string): string {
    return `/pub/${this.store.publicationID}/${this.store.publicationRevision}/du/${dmc}`;
  }

  public getAdditionalRef(anchor: string): string {
    if (anchor?.startsWith("csn")) {
      return ` - ${anchor.split("-").pop()}`;
    }
    return "";
  }
}
