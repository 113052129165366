import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MediaSwitchComponent } from "@viewer/shared-module/media-switch/media-switch.component";
import { InfoDialogComponent } from "@viewer/shared-module/info-dialog/info-dialog.component";
import { MatBadgeModule } from "@angular/material/badge";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import { MAT_SELECT_CONFIG, MatSelectModule } from "@angular/material/select";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTableModule } from "@angular/material/table";
import { CmPreviewComponent } from "@viewer/shared-module/cm-preview/cm-preview.component";
import { CmCardComponent } from "@viewer/shared-module/cm-card/cm-card.component";
import { MultilinksComponent } from "@viewer/shared-module/multilinks/multilinks.component";
import { IpcPreviewComponent } from "@viewer/shared-module/ipc-preview/ipc-preview.component";
import { TaskCardComponent } from "@viewer/shared-module/task-card/task-card.component";
import { RevisionComponent } from "@viewer/shared-module/revision/revision.component";
import { CardPubComponent } from "@viewer/shared-module/card-pub/card-pub.component";
import { CardContractComponent } from "@viewer/shared-module/card-contract/card-contract.component";
import { StickyDirective } from "@viewer/shared-module/sticky.directive";
import { HistoricDirective } from "@viewer/shared-module/historic.directive";
import { CardComponent } from "@viewer/shared-module/card/card.component";
import { ConfirmDialogComponent } from "@viewer/shared-module/confirm-dialog/confirm-dialog.component";
import { SelectLangComponent } from "@viewer/shared-module/select-lang/select-lang.component";
import { SearchBarComponent } from "@viewer/shared-module/search-bar/search-bar.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormatResultPipeModule } from "@viewer/search-result-module/search-result/format-result-pipe/formatResult-pipe.module";
import { IpcInfoModalComponent } from "@viewer/ipc-module/ipc-info-modal/ipc-info-modal.component";
import { LoadingComponent } from "@viewer/loading/loading.component";
import { MmhComponent } from "@viewer/shared-module/info-dialog/mmh/mmh.component";
import { MmhService } from "@viewer/core/mmh/mmh.service";
import { ParseRevisionPipeModule } from "@orion2/utils/ParseRevisionPipeModule";
import { MatToolbarModule } from "@angular/material/toolbar";
import { SafeDatePipe } from "libs/pipe/safe-date.pipe";
import { VersionMappingPipe } from "@viewer/core/pipe/version-mapping.pipe";
import { BookmarkEditComponent } from "@viewer/toc-items/bookmark-module/bookmark-edit/bookmark-edit.component";
import { FolderEditComponent } from "@viewer/toc-items/bookmark-module/folder-edit/folder-edit.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { SafeHTMLPipe } from "libs/pipe/safe-html.pipe";
import { MatPaginationIntlCustom } from "@viewer/toc-items/toc-item-header/custom-mat-paginator";
import { OrionCardComponent } from "@viewer/shared-module/orion-card/orion-card.component";
import { BookmarkImportResultDialogComponent } from "@viewer/toc-items/bookmark-module/bookmark-import-result-dialog/bookmark-import-result-dialog.component";
import { PartCardComponent } from "@viewer/shared-module/part-card/part-card.component";

const components = [
  MediaSwitchComponent,
  InfoDialogComponent,
  MmhComponent,
  ConfirmDialogComponent,
  CmPreviewComponent,
  CmCardComponent,
  MultilinksComponent,
  IpcPreviewComponent,
  RevisionComponent,
  CardPubComponent,
  CardContractComponent,
  SelectLangComponent,
  SearchBarComponent,
  CardComponent,
  TaskCardComponent,
  IpcInfoModalComponent,
  LoadingComponent,
  BookmarkEditComponent,
  FolderEditComponent,
  OrionCardComponent,
  BookmarkImportResultDialogComponent,
  PartCardComponent
];

const pipes = [SafeDatePipe, VersionMappingPipe, SafeHTMLPipe];
const directives = [StickyDirective, HistoricDirective];

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatCardModule,
    RouterModule,
    MatListModule,
    MatProgressBarModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatSelectModule,
    MatBadgeModule,
    MatChipsModule,
    MatToolbarModule,
    FormsModule,
    MatExpansionModule,
    FormatResultPipeModule,
    TranslateModule.forChild(),
    ParseRevisionPipeModule
  ],
  declarations: [...components, ...pipes, ...directives],
  exports: [...components, ...pipes, ...directives, TranslateModule],
  providers: [
    MmhService,
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginationIntlCustom
    },
    { provide: MAT_SELECT_CONFIG, useValue: { hideSingleSelectionIndicator: true } }
  ]
})
export class SharedModule {}
