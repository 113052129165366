import xpath from "xpath";

import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";
import {
  Util,
  ARRAY_SEPARATOR,
  OBJECT_SEPARATOR,
  STRING_SEPARATOR
} from "@orion2/utils/datamodule.utils";

export class PreliminaryRqmts {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static extractLegacy(xmlNode: Node): string {
    // Not available for legacy
    return "";
  }

  public static extractH160(xmlNode: Node): string {
    const data: string[] = [];
    const zoneRefs = xpath.select(
      "..//preliminaryRqmts/productionMaintData/workAreaLocationGroup/zoneRef | zoneRef",
      xmlNode
    );
    const changed =
      xpath.select("string(..//preliminaryRqmts//@changeMark|zoneRef/@changeMark)", xmlNode)
        .length > 0;

    const zones: string[] = [];
    zoneRefs.forEach((zoneXml: Node) => {
      const zone = Util.cleanXml(xpath.select("string(@zoneNumber)", zoneXml));

      zones.push(zone);
    });
    data.push(zones.join(STRING_SEPARATOR) + OBJECT_SEPARATOR + changed);
    return data.join(ARRAY_SEPARATOR);
  }

  public static unserialize(data: string): TaskNamespace.PreliminaryRqmts {
    if (data !== undefined) {
      const val = data.split(OBJECT_SEPARATOR);
      return {
        zones: val[0].split(STRING_SEPARATOR),
        changed: val[1] === "true"
      };
    }
  }
}
