/* eslint-disable @typescript-eslint/no-explicit-any */
import PouchDB from "pouchdb";
import { DbStats, DbItem, PouchSaveResponse } from "@orion2/models/couch.models";
import { OrionDBsCore } from "@viewer/core/pouchdb/core/orionDbsCore";
import * as MemoryAdapter from "pouchdb-adapter-memory";
import { base64StringToBlobOrBuffer as b64StringToBluffer } from "pouchdb-binary-utils";
import { OrionDbCreator } from "@viewer/core/pouchdb/orionDbCreator";
import { isUrl, pouchAttachmentToBinary } from "@viewer/shared-module/helper.utils";
import { OakResponse } from "@orion2/auth/oak.response";
import { DatabaseConfObject, DbParams, DBConnexionType } from "@viewer/core/pouchdb/types";
import { MimeType } from "@orion2/models/enums";
import { StatusCodes } from "http-status-codes";

export interface CallbacksFnInterface {
  error: Function;
  denied: Function;
  change: Function;
  complete: Function;
}

PouchDB.plugin(MemoryAdapter["default"]);

/**
 * Represent remote and local PouchDb database
 * Handle synchronization from couchToPouch
 * Must be implement by all database (toc,link ect..)
 *
 * @export
 * @class OrionDBs
 */

const resolveMsg = ["sendAccessToken", "sendOak", "sendGetLastSequence", "sendSaveLastSequence"];
const rejectMsg = ["error"];
// Constant to force reset last_seq stored in localStorage
const LS_MIN_REV = "2.3.50";

export class OrionDBs {
  readonly totalCountKey = "_local/totalCount";
  readonly replicateStatusKey = "_local/replicationStatus";

  protected _remoteDB: PouchDB.Database;
  protected _localDB: PouchDB.Database;
  protected _dbName: string;
  protected _confObject: DatabaseConfObject;
  protected _instanceName: string;
  protected _replicationRef = undefined; // keep track of current replication
  protected revCache: Map<string, string>;
  protected _dbParams: DbParams;
  protected useWorker = false;

  protected callMap: Map<number, any>;
  protected id = 0;
  protected messageHandleBind: any;

  constructor(
    prefix: string,
    suffix: string,
    protected confObjectParam: DatabaseConfObject,
    dbParams: DbParams
  ) {
    if (!!!confObjectParam) {
      throw new Error("Conf object should be defined");
    }
    this._confObject = confObjectParam;
    this._dbName = suffix ? `${prefix}_${suffix}` : prefix;
    this._dbParams = dbParams || {
      remote: {},
      local: {},
      isOffline: false
    };

    this.useWorker = this._dbParams.useWorker;
    this.callMap = new Map<number, string>();

    this.messageHandleBind = this.handleMessage.bind(this);
    addEventListener("message", this.messageHandleBind, false);

    if (!(this._dbParams.remote === undefined || (this._dbParams.remote as any).fetch)) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _self = this;
      (this._dbParams.remote as any).fetch = (url, opts) => {
        // SPEC: PDF are stored in minio bucket accessible from basepub API (only for package pdf)
        // This feature should be disabled for dev and on premise viewer
        if (
          this._dbParams.isPackagePDF &&
          this._confObject.basepubExternalUrl &&
          /\/package-\d+_pdf.*_pdf\/.*\//gi.test(url)
        ) {
          url = url.replace(
            this._confObject.couchBaseUrl,
            `${this._confObject.basepubExternalUrl}/data`
          );
          opts.headers.set("content-type", MimeType.PDF);
        }

        if (!this._dbParams.useAuth) {
          return PouchDB.fetch(url, opts);
        } else {
          // F5 AH does not support the credentials option with the `include` parameter.
          // For package PDF, we call F5 for get the PDF in MinIo.
          opts.credentials = this._dbParams.isPackagePDF ? "same-origin" : "include";
          return this.getAccessToken()
            .then(this.getOak.bind(_self))
            .then((oakResponse: OakResponse) => {
              // in case the token is in fact an Error.
              if (oakResponse.code) {
                Promise.reject({
                  code: 401,
                  message: "Unauthorized"
                });
              }
              opts.headers.set("Authorization", `Bearer ${oakResponse.accessToken}`);
              opts.headers.set("oak", oakResponse.oak);
              return (PouchDB as any).fetch(url, opts);
            })
            .catch(err => {
              Promise.reject({
                code: 401,
                message: "Unauthorized : " + JSON.stringify(err)
              });
            });
        }
      };
    }

    // We add skip_setup because DB should already be created by either:
    // - Importer if this is a public DB
    // - UMS if this is a user related DB
    // Moreover, viewer doesn't have rights to create DB
    this._remoteDB = new PouchDB(`${this._confObject.couchBaseUrl}/${this._dbName}`, {
      ...this._dbParams.remote,
      skip_setup: true
    });
    this._localDB = this.createLocalDb(this._dbParams.local || {});
    this._instanceName = `${suffix}`;
    this.revCache = new Map<string, string>();
  }

  get dbName() {
    return this._dbName;
  }

  get remoteDB(): PouchDB.Database {
    return this._remoteDB;
  }

  get localDB(): PouchDB.Database {
    return this._localDB;
  }

  getAccessToken() {
    return this.postMessage("getAccessToken");
  }

  getOak() {
    return this.postMessage("getOak");
  }

  public updateOfflineStatus(isOffline: boolean) {
    this._dbParams.isOffline = isOffline;
  }

  public confObject() {
    return this._confObject;
  }

  public async localCount(): Promise<number> {
    const localInfo = await this.localDB.info();
    return localInfo.doc_count;
  }

  public async updateRemoteUri(uri: string) {
    this._confObject.couchBaseUrl = uri;
    this._remoteDB = new PouchDB(`${this._confObject.couchBaseUrl}/${this._dbName}`);
  }

  /**
   * Will load the toc in ram
   *
   * @memberof OrionDBs
   */
  public loadInMemoryStrategy(): Promise<DBConnexionType> {
    return this._loadInMemoryWithoutCache();
  }

  async removeReplicationFlag() {
    this.revCache.delete(this.replicateStatusKey);
    return OrionDBsCore.removeReplicationFlag(this.localDB);
  }

  /**
   * Gets the db name, db instance name, local count, remote count, local on remote percent.
   * Also saves remote count in localDb to access it even if the app is offline
   */
  public async dbInformation(): Promise<DbStats> {
    const localInfo = await this.localDB.info();
    const localCount = localInfo.doc_count;

    return {
      dbName: this._dbName,
      dbInstance: this._instanceName,
      remoteCount: 0,
      percent: 0,
      localCount
    } as DbStats;
  }

  /**
   * Update the document and store its ref in local db
   * Get the ref if not present in map
   *
   * @param doc
   * @returns
   */
  async setLocal(doc): Promise<DbItem> {
    try {
      if (!doc._rev) {
        // TODO DG warning getDocumentRevision can return null or
        // undefined. If we explicitly set _rev at undefined pouchdb
        // seems to always return a conflict or an error when puting the
        // doc into the db. Need a clear study of the case (comprising delete)
        const rev = await this.getDocumentRevision(doc._id);
        if (rev) {
          doc._rev = rev;
        }
      }
    } catch (e) {
      console.warn(doc._id + "does not exist yet");
    }

    return OrionDBsCore.save(this._localDB, doc)
      .then((response: PouchSaveResponse) => {
        this.revCache.set(response.id, response.rev);
        // Create DBItem object
        return {
          _id: response.id,
          _rev: response.rev
        };
      })
      .catch(e => {
        console.error(`Error while saving ${JSON.stringify(doc)}: ${e}`);
        return undefined;
      });
  }

  /**
   * Perform a synchronisation between remote and local, resolving conflicts
   *
   * @param ids     - The set of ids of document to synchronize
   * @param nbDocs  - (Optionnal) The number of document to syncronise, performs a live replication if given
   *
   * CAUTION : If (ids.length !== ndDocs) it could lead to unexpected behaviour
   */
  public sync(ids: string[]): Promise<PouchDB.Replication.ReplicationResultComplete<{}>> {
    return this.getBatchSize().then((batchSize: number) => {
      const params = {
        batch_size: batchSize
      };

      if (ids && ids.length > 0) {
        params["doc_ids"] = ids;
      }

      return new Promise((resolve, reject) => {
        this.localDB.replicate
          .to(this.remoteDB, { ...params, checkpoint: "source" })
          .then(() => {
            this.remoteDB.replicate
              .to(this.localDB, { ...params, checkpoint: "target" })
              .then(() => resolve(true));
          })
          .catch(err => {
            OrionDBsCore.replicationErrorHandler(err);
            reject(err);
          });
      })
        .then(() =>
          // Resolve all conflict
          // This way, we only get conflicted docs instead of every synced docs
          // Be careful, this only resolve conflicts on remote BD
          this.remoteDB.query("conflicts", {
            keys: ids || undefined,
            include_docs: false
          })
        )
        .then((res: PouchDB.Query.Response<{}>) =>
          // After resolving conflict we compacting the remote database in order remove useless document
          this.resolveConflict(res.rows)
        )
        .then((res: PouchDB.Replication.ReplicationResultComplete<{}>) => {
          // We can't updateLastSequences when doing partial sync meaning when ids is defined to an array
          const promise = ids
            ? Promise.resolve()
            : Promise.all([this.updateLastSequences("remote"), this.updateLastSequences("local")]);
          return promise.then(() => res);
        })
        .catch(err => {
          console.error(`Error during DB ${this._dbName} sync: ${err.message}`);
          return Promise.reject(err.message);
        });
    });
  }

  /**
   * Enable cache use
   *
   * @memberof OrionDBs
   */
  public enableCache(): void {
    this._confObject.useCache = true;
  }

  /**
   * Execute a pouchDB request on the given instance (local or remote)
   *
   * @param type
   * @param query
   * @param args
   */
  public doOnInstance(type: DBConnexionType, query: string, args: any): Promise<DbItem> {
    if (!Array.isArray(args)) {
      args = [args];
    }

    let prom: Promise<unknown>;
    switch (type) {
      case DBConnexionType.REMOTE:
        prom = this.remoteDB[query](...args);
        break;
      case DBConnexionType.BOTH:
        prom = Promise.all([
          this.remoteDB[query](...args).catch(_ => ({ rows: [] })),
          this.localDB[query](...args).catch(err => {
            // 404 error can occur when document isn't accessible without being unexpected
            if (err.status !== StatusCodes.NOT_FOUND) {
              console.error(
                `Unexpected error when call ${query} in localDb : ${err.status} - ${err.message}`
              );
            }
            return { rows: [] };
          })
        ]).then(res => [...res]);
        break;
      default:
        prom = this.localDB[query](...args);
    }

    return prom.then(OrionDBsCore.reshape).catch((err: PouchDB.Core.Error) => {
      if (err?.name !== "not_found") {
        this.log(console.error, err);
      }
    });
  }

  /**
   * Save the given value in pouchDb
   *
   * @param doc
   */
  public async save(doc): Promise<any> {
    if (this._confObject.useCache) {
      return OrionDBsCore.save(this.localDB, doc);
    }
    this.log(console.warn, "Save on remote do not exist yet, we save on local for the moment");
    return OrionDBsCore.save(this.localDB, doc);
  }

  public compact(): Promise<PouchDB.Core.Response> {
    return this.localDB.compact();
  }

  /**
   * Delete document in local Database
   *
   * @param id
   */
  public async deleteLocal(id: any): Promise<boolean> {
    const doc = await this.localDB.get(id);

    // Remove useless properties (we need just date for conflict management)
    const deleteDoc = {
      _id: doc._id,
      _rev: doc._rev,
      _deleted: true,
      lastUpdate: new Date()
    };

    if (doc) {
      return this.localDB
        .put(deleteDoc)
        .then((putRes: PouchDB.Core.Response) => {
          this.revCache.set(putRes.id, putRes.rev);
          return true;
        })
        .catch(e => {
          console.error("deleteLocal", e);
          return false;
        });
    } else {
      return false;
    }
  }

  /**
   * Disable cache use
   *
   * @memberof OrionDBs
   */
  public disabledCache(): void {
    this._confObject.useCache = false;
  }

  /**
   * return information about the local database
   */
  public info(): Promise<string> {
    return this.isReplicate().then(
      (isReplicate: boolean) =>
        `${this.dbName} with adapter : ${(this.localDB as any).adapter} isReplicate: ${isReplicate}`
    );
  }

  /**
   * Define how replication process works
   *
   * @private
   * @memberof OrionDBs
   * @param docs
   */
  public replicationStrategy(docs: any): Promise<void> {
    let idsParam = docs._id ? [docs._id] : docs.rows.map(row => row._id);
    idsParam = idsParam.filter(res => res !== undefined);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    return new Promise((resolve, reject) => {
      this._remoteDB.replicate
        .to(this.localDB, {
          doc_ids: idsParam
        })
        .on("error", err => {
          OrionDBsCore.replicationErrorHandler(err);
          reject();
        })
        .on("complete", () => {
          resolve();
        });
    });
  }

  /**
   * Do the request query with args
   *
   * @param query
   * @param args
   * @param [replicateAction]
   * @returns
   * @memberof OrionDBs
   */
  async do(query: string, args: any[], targetDb?: DBConnexionType): Promise<any> {
    this.checkDoParam(query, args);

    try {
      if (
        this._confObject.useCache ||
        // SPEC: When targetDb is loca we want to force the target to get local document
        // the dbParams.isOffline may be not setted in the store because the store.pubInfo is undefined
        (this._dbParams.isOffline && !targetDb) ||
        targetDb === DBConnexionType.LOCAL
      ) {
        return await this._callHandler(query, args);
      }

      // This is a hack to workaround a bug in pouchdb where
      // allDocs with option binary===true fails if one at least of the returned docs
      // has no attachment.
      let binary;
      if (args[0]["binary"]) {
        args[0]["binary"] = false;
        binary = true;
      }

      const docs = await this._remoteDB[query](...args);
      if (binary) {
        docs.rows.filter(r => r.doc).forEach(this.readAttachmentsAsBlobOrBuffer);
      }

      if (args[0]["binary"] === false) {
        args[0]["binary"] = true;
      }

      return OrionDBsCore.reshape(docs);
    } catch (e) {
      // error from remote
      console.warn("OrionDBs.do()", query, args, e);
      return null;
    }
  }

  /**
   * Get document by is id or object arg for allDocs methods
   *   exemple of object args
   *    const queryParam = {
   *    startkey: 'thumb__' + dmc,
   *    endkey: 'thumb__' + dmc + '\ufff0',
   *    include_docs: true
   *  }
   *
   * @param [args=[]]
   * @returns
   * @memberof OrionDBs
   */
  public get(args: string | any = [], targetDb?: DBConnexionType): Promise<any> {
    args = args instanceof Array ? args : [args];
    if (!args[0]) {
      // sync all the db
      return this.do("allDocs", [], targetDb);
    }
    return typeof args[0] === "string"
      ? this.do("get", args, targetDb)
      : this.do("allDocs", args, targetDb);
  }

  /**
   * Remove all base from local database
   *
   * @memberof OrionDBs
   */
  public emptyLocal(): Promise<boolean> {
    this.revCache = new Map<string, string>();
    return this._localDB
      .destroy()
      .then(() => {
        // On IOS, destroy() does not empty databases
        // Its SQL lite bug : https://github.com/xpbrew/cordova-sqlite-storage/issues/646
        // For IOS, we must force the purging of the bases
        if ((window as any).cordova) {
          // Create DB for SQL lite
          //  and db are different, localDB is Pouchdb Object and db are SQLlite Object
          const db = (window as any).sqlitePlugin.openDatabase({
            name: this.localDB.name,
            location: "default"
          });

          // "VACUUM force batabase cleaning"
          return new Promise<boolean>((resolve, reject) => {
            try {
              db.executeSql("VACUUM", [], () => {
                this._localDB = this.createLocalDb(this._dbParams.local || {});
                resolve(true);
              });
            } catch (error) {
              console.error(error);
              reject(false);
            }
          });
        } else {
          this._localDB = this.createLocalDb(this._dbParams.local || {});
          return true;
        }
      })
      .catch(err => {
        console.error(err);
        return false;
      });
  }

  public getAttachmentsBlob(id: string, targetDb?: DBConnexionType): Promise<Blob> {
    return this.get([id, { attachments: true, binary: true }], targetDb).then((document: DbItem) =>
      this.joinChunks(document)
    );
  }

  // TODO: can detect attachment in reshape
  public getAttachmentsBlobUrlList(param: any) {
    const defaultParam = {
      include_docs: true,
      attachments: true,
      binary: true
    };
    const pouchParam = Object.assign(defaultParam, param);
    return this.get(pouchParam)
      .then(document => {
        if (document !== null && document !== undefined) {
          document.rows = document.rows.filter(r => r && r._attachments);
          return Promise.all(
            document.rows.map((doc: DbItem) => {
              const blob: Blob = this.joinChunks(doc);
              // SPEC: Concatenating attachment chunks into 1 for handling convenience
              // so we first clear the _attachments object in order to replace it with the a
              // new object containing only one property "att.txt" which contains the
              // concatenated blobs (see this.joinChunks(blob))
              doc._attachments = {};
              return pouchAttachmentToBinary(blob).then(buffer => {
                const array = new Uint8Array(buffer as ArrayBuffer);
                doc._attachments["att.txt"] = {
                  data: array
                } as PouchDB.Core.FullAttachment;
                return doc;
              });
            })
          );
        }
      })
      .catch(e => {
        console.warn(e);
        return [];
      });
  }

  public close(): Promise<boolean> {
    removeEventListener("message", this.messageHandleBind);
    return Promise.all([
      this._localDB.close().then(() => true),
      this._remoteDB.close().then(() => true)
    ]).then(ret => ret[0] && ret[1]);
  }

  public bulk(args: DbItem[]): Promise<any> {
    return OrionDBsCore.bulk(this._localDB, args);
  }

  /**
   * Check if the user should synchronize his personal data with the remote database
   *
   * @memberof OrionDBs
   */
  public shouldSynchronize(): Promise<boolean> {
    // see https://gist.github.com/nolanlawson/44385bb80990077c30de for detailed explanations on how _changes works
    return Promise.all([this.compareLastSequences("remote"), this.compareLastSequences("local")])
      .then(([shouldSyncRemote, shouldSyncLocal]: boolean[]) => shouldSyncRemote || shouldSyncLocal)
      .catch(() => false);
  }

  public createIndex(index: PouchDB.Find.CreateIndexOptions): Promise<unknown> {
    return this.localDB.createIndex(index);
  }

  /**
   * Get all the toc items
   *
   * @returns
   * @memberof TocDBs
   */
  protected getAll(): Promise<any> {
    return this.get({ include_docs: true });
  }

  /**
   * Parse error response from pouchDB API to handle error
   *
   * @protected
   * @param request
   * @returns
   * @memberof OrionDBs
   */
  protected async handleError(request: Function): Promise<any> {
    try {
      return await request();
    } catch (e) {
      if (e.code === "ETIMEDOUT") {
        throw new Error("Timeout check db connexion");
      }
      throw e;
    }
  }

  protected replicateRemote(callbacks: CallbacksFnInterface, batchSize: number): void {
    this._replicationRef = OrionDBsCore.replicateReference(this.remoteDB, this.localDB, batchSize)
      .on("change", (change: PouchDB.Replication.ReplicationResult<{}>) =>
        callbacks.change({ ...change, dbName: this.dbName })
      )
      .on("denied", (denied: Object) => callbacks.denied({ ...denied, dbName: this.dbName }))
      .on("error", (error: Object) => callbacks.error({ ...error, dbName: this.dbName }))
      .on("complete", (info: PouchDB.Replication.ReplicationResultComplete<{}>) => {
        callbacks.complete({ ...info, dbName: this.dbName });

        if (info.status === "cancelled") {
          return info.status;
        }
        OrionDBsCore.replicationDone(this.localDB);
      });
  }

  protected cancelReplication() {
    if (this._replicationRef) {
      this._replicationRef.cancel();
      this._replicationRef = undefined;
    }
  }

  /**
   * Create local Db : use index db or use index db in electron
   *
   * @protected
   * @returns
   * @memberof OrionDBs
   */
  protected createLocalDb(dbParams: Object): PouchDB.Database<{}> {
    return OrionDbCreator.createDb(this.dbName, dbParams);
  }

  protected replicationInProgress(): boolean {
    return this._replicationRef ? true : false;
  }

  /**
   * Compute the optimized batch size and limit for replication
   *
   * @protected
   * @returns
   * @memberof OrionDBs
   */
  protected async _batchParam(): Promise<any> {
    return OrionDBsCore.batchParam(this.remoteDB);
  }

  /**
   * Handle unique request
   * 1. Do local
   * 2. if not found do remote
   * 3. if defined do replication
   *
   * @private
   * @param query
   * @param args
   * @returns
   * @memberof OrionDBs
   */
  protected async _callHandler(query: string, args: any[]): Promise<any> {
    try {
      const docs = await this._localDB[query](...args);

      if (OrionDBsCore.docsNotFound(docs)) {
        // eslint-disable-next-line no-throw-literal
        throw { status: StatusCodes.NOT_FOUND, multiple: true };
      }
      return OrionDBsCore.reshape(docs);
    } catch (err) {
      return this.notFoundInLocalHandler(err, query, args);
    }
  }

  /**
   * Call the remote db and replicate the data retrieved
   *
   * @param err
   * @param query
   * @param args
   * @returns
   */
  protected async notFoundInLocalHandler(err, query: string, args: any[]) {
    if (this._dbParams.isOffline) {
      throw err;
    }
    const isReplicate = await this.isReplicate();
    if (err.status === StatusCodes.NOT_FOUND && !isReplicate) {
      let binary;
      if (args[0] && args[0]["binary"]) {
        args[0]["binary"] = false;
        binary = true;
      }
      const docs = await this._remoteDB[query](...args);
      if (binary) {
        docs.rows.filter(r => r.doc).forEach(this.readAttachmentsAsBlobOrBuffer);
      }
      const response = OrionDBsCore.reshape(docs);
      if (OrionDBsCore.docsNotFound(response)) {
        // TODO: handle 1 doc in 4 that is not found
        throw new Error("Not found in remote");
      }
      this.replicationStrategy(docs);
      return response;
    }
    throw err;
  }

  private getBatchSize(): Promise<number> {
    return this.remoteDB
      .info()
      .then((pubInfo: PouchDB.Core.DatabaseInfo & { sizes: { file: number } }) => {
        const batchVolume = 4000000;
        const disk = pubInfo.sizes?.file;
        const dbs = pubInfo.doc_count;
        const batchSize = disk && dbs ? Math.floor(batchVolume / (disk / dbs)) : undefined;
        // SPEC: If the floor value = 0 we force the batch size to the minimum value
        return batchSize || 1;
      });
  }

  /**
   * Return true if the database is replicate
   */
  private isReplicate(): Promise<boolean> {
    return OrionDBsCore.isReplicate(this.localDB);
  }

  /**
   *
   * @param e
   */
  private getPayload(e: MessageEvent | CustomEvent): any {
    // Sets the payload depending on the kind of message received
    if (e instanceof MessageEvent) {
      // If message with PromiseWorker
      if (typeof e.data === "string") {
        // if e.data.match(expression) payload is undefined, meaning the message is not for us.
        if (isUrl(e.data)) {
          return undefined;
        }
        // e.data could be undefined this try catch will protect us is it's not a valid JSON
        try {
          const data = JSON.parse(e.data);
          return data[1].payload;
        } catch (_err: unknown) {
          return e.data;
        }
      }
      return e.data.payload;
    }

    if (e instanceof CustomEvent) {
      // If message with CustomEvent
      return e.detail.payload;
    }

    return undefined;
  }

  /**
   * Handle incoming messages and resolve according Promise
   *
   * @param event the incoming message.
   */
  private handleMessage(event: MessageEvent | CustomEvent) {
    const payload = this.getPayload(event);

    /**
     * If the message is not specifically addressed to this file.
     * Or, if the message is not addressed to this instance of OrionDbs.
     */
    if (!payload?.message?.startsWith("OrionDBs.") || payload?.db !== this.dbName) {
      return;
    }

    // If the message is addressed to this instance of OrionDbs.
    try {
      const message = payload.message.split(".");
      if (resolveMsg.includes(message[1])) {
        const promise = this.callMap.get(payload.id);
        this.callMap.delete(payload.id);
        return promise.resolve(payload.response);
      } else if (rejectMsg.includes(message[1])) {
        const promise = this.callMap.get(payload.id);
        this.callMap.delete(payload.id);
        return promise.reject(payload.response);
      } else {
        console.error("OrionDBs", "Message not handled", payload.message);
      }
    } catch (err) {
      console.error(
        `
            CallMap get,
            payloadid: ${payload.id},
            callmap entries: ${JSON.stringify(Array.from(this.callMap.entries()))},
            dbName: ${this.dbName},
            payloadid: ${JSON.stringify(this.callMap.get(payload.id))},
            callmap size: ${this.callMap.size}
            `
      );
    }
  }

  /**
   * Send a message to either DbProcessor or WorkerProcessorGateway depending on worker use or not
   *
   * @param message
   * @param params
   * @return Promise that will be resolved when receive a response
   */
  private postMessage(message: string, params?: unknown): Promise<any> {
    const id = this.id++;
    return new Promise((_resolve, _reject) => {
      this.callMap.set(id, { resolve: _resolve, reject: _reject });
      if (!this.useWorker) {
        const event = new CustomEvent("message", {
          detail: {
            message: "DbProcessor." + message,
            payload: {
              id,
              db: this.dbName,
              params
            }
          }
        });
        dispatchEvent(event);
      } else {
        postMessage({
          message: "WorkerProcessorGateway." + message,
          payload: {
            id,
            db: this.dbName,
            params
          },
          origin: "OrionDBs"
        });
      }
    });
  }

  /**
   * Check if log is enabled before displaying it
   *
   * @param fn
   * @param message
   */
  private log(fn, message: any) {
    if (this.confObject().displayQuery) {
      fn(message);
    }
  }

  /**
   * Load the toc in memery if the user doesn't want to store the data
   */
  private _loadInMemoryWithoutCache() {
    return this.loadInMemory(this.remoteDB).then(() => DBConnexionType.REMOTE);
  }

  private loadInMemory(sourceDb: any): Promise<PouchDB.Database<{}>> {
    const memoryDB = new PouchDB(this._dbName, { adapter: "memory" });
    return OrionDBsCore.replicate(sourceDb, memoryDB)
      .then(() => OrionDBsCore.replicationDone(memoryDB))
      .then(() => memoryDB);
  }

  /**
   * Function that retrieve a all remote doc with conflicts and resolve by latest date of doc
   *
   * @param docs
   */
  private resolveConflict(
    docs: PouchDB.Query.Response<{}>["rows"]
  ): Promise<PouchDB.Replication.ReplicationResultComplete<{}> | undefined> {
    if (docs.length === 0) {
      return Promise.resolve(undefined);
    }
    const docIdsWithConflict = docs.map(doc => ({ id: doc.id }));
    return (
      this.remoteDB
        .bulkGet({ docs: docIdsWithConflict })
        .then((res: any) => {
          const toBeUpdatedDocs = res.results.map(docsById => {
            const descSortedDocs = docsById.docs
              // we don't want to delete again doc.rev created to resolve a conflict
              .filter(doc => doc.ok && !doc.ok.conflict_resolution)
              // sorting in descending order meaning the first in the resulting array is
              // the most recent.
              .sort(
                (d1, d2) =>
                  new Date(d2.ok.lastUpdate).getTime() - new Date(d1.ok.lastUpdate).getTime()
              );
            // we keep the most recent doc
            const lastDoc = descSortedDocs[0].ok;
            // and remove the others
            descSortedDocs.shift();

            const docToBeDeleted = descSortedDocs.map(doc =>
              // Remove useless document
              ({
                _id: doc.ok._id,
                _rev: doc.ok._rev,
                _deleted: true,
                conflict_resolution: true
              })
            );

            // don't need to push the last doc if there's no document in conflict
            // to delete (the case is that the only conflicts left are _deleted_conflits
            // with the property conflic_resolution === true)
            if (docToBeDeleted.length === 0) {
              return { docToBeDeleted: [], lastDoc: undefined };
            }
            // This is the return object for each conflicting docId
            // this will be flatten later.
            return { docToBeDeleted, lastDoc };
          });

          // flatten the docs to be deleted
          const toBeDeletedDocs = toBeUpdatedDocs.map(doc => doc.docToBeDeleted).flat();

          // flatten the last docs ignoring those docs for which no deletion was necessary
          const lastDocs = toBeUpdatedDocs.reduce((acc, arr) => {
            if (arr.lastDoc) {
              return acc.concat(arr.lastDoc);
            }
            return acc;
          }, []);
          return { toBeDeletedDocs, lastDocs };
        })
        .then((toBeUpdatedDocs: { toBeDeletedDocs: any[]; lastDocs: any }) => {
          if (toBeUpdatedDocs.toBeDeletedDocs && toBeUpdatedDocs.toBeDeletedDocs.length === 0) {
            return Promise.resolve();
          }
          return (
            this.remoteDB
              .bulkDocs(toBeUpdatedDocs.toBeDeletedDocs)
              // .then(() => this.remoteDB.bulkDocs(toBeUpdatedDocs.lastDocs))
              .catch(e => {
                console.error("Error while resolving conflicts:", e);
                return Promise.resolve();
              })
          );
        })
        // The only thing left to do is to propagate the modifications
        // back to localDB. A replication is enough.
        .then(() => this.remoteDB.replicate.to(this.localDB, { checkpoint: "target" }))
    );
  }

  private updateLastSequences(dbType: "remote" | "local"): Promise<unknown> {
    // see https://gist.github.com/nolanlawson/44385bb80990077c30de for detailed explanations on how _changes works
    return this.getLastSequence(dbType).then((lastSequence: string) =>
      this[`${dbType}DB`]
        .changes({
          // For some reasons, "since" parameter requires a string in remote, but number in local
          since: (dbType === "remote" ? lastSequence : +lastSequence) || undefined
        })
        .then((changes: PouchDB.Core.ChangesResponse<{}>) => this.saveLastSequence(changes, dbType))
    );
  }

  private getLastSequence(dbType: "remote" | "local"): Promise<string> {
    return this.postMessage("getLastSequence", {
      key: `${this.dbName}__${dbType}__last_seq`
    }).then((lastSequence: string) => {
      if (!lastSequence) {
        return "";
      }
      // SPEC: Because sequence ids can contains __, cannot use split("__")
      const regex = new RegExp(`(${LS_MIN_REV})__(.*)`);
      const [_, prefix, lastSeq] = regex.exec(lastSequence);

      if (prefix !== LS_MIN_REV) {
        return "";
      }
      return lastSeq;
    });
  }

  private saveLastSequence(
    lastSeq: PouchDB.Core.ChangesResponse<{}>,
    dbType: "remote" | "local"
  ): Promise<unknown> {
    return this.postMessage("saveLastSequence", {
      key: `${this.dbName}__${dbType}__last_seq`,
      value: `${LS_MIN_REV}__${lastSeq.last_seq}`
    });
  }

  private compareLastSequences(dbType: "remote" | "local"): Promise<boolean> {
    return this.getLastSequence(dbType)
      .then((lastSavedSeq: string) =>
        this[`${dbType}DB`]
          .changes({
            // For some reasons, "since" parameter requires a string in remote, but number in local
            since: (dbType === "remote" ? lastSavedSeq : +lastSavedSeq) || undefined
          })
          .then(
            (lastChanges: PouchDB.Core.ChangesResponse<{}>) =>
              !lastSavedSeq ||
              lastChanges.last_seq.toString().split("-")[0] !== lastSavedSeq.split("-")[0]
          )
      )
      .catch(err => {
        console.error(err);
        return false;
      });
  }

  /**
   * Gets the last revision of a document
   *
   * @param key
   */
  private getDocumentRevision(key: string): Promise<string> {
    const cachedRev = this.revCache.get(key);
    if (cachedRev) {
      return Promise.resolve(cachedRev);
    }
    return this.getLocalRev(key);
  }

  /**
   * Get revision from object stored only in local DB
   *
   * @param key
   */
  private getLocalRev(key): Promise<string> {
    return this.localDB
      .get(key)
      .then(resp => {
        this.revCache.set(key, resp._rev);
        return resp._rev;
      })
      .catch((e: PouchDB.Core.Error) => {
        // SPEC: 404 is a normal error otherwise we displaying the error
        if (e.name !== "not_found") {
          console.error("getLocalRev", key, e);
        }
        return undefined;
      });
  }

  /**
   * Throw exeption if the param to do handler are not correct
   *
   * @param query
   * @param args
   */
  private checkDoParam(query: string, args: any[]) {
    if (!this.remoteDB[query] || !this.localDB[query]) {
      throw new Error(query + " function not found on pouchDB");
    }
    if (!args) {
      throw new Error("Args must be set for " + query);
    }
  }

  private readAttachmentsAsBlobOrBuffer(row) {
    const doc = row.doc || row.ok;
    const atts = doc._attachments;
    if (!atts) {
      return;
    }
    Object.keys(atts).forEach(filename => {
      const att = atts[filename];
      // TODO DG: doesn't work yet. pouch-binary utils should be external in webpack electron.
      // if (
      //   (navigator as any).userAgent.toLowerCase().indexOf(" electron/") >= 0
      // ) {
      //   att.data = binaryStringToArrayBuffer(att.data, att.content_type);
      // } else {
      att.data = b64StringToBluffer(att.data, att.content_type);
      // }
    });
  }

  /**
   * Function to join chunks into 1 Blob
   *
   * @param document
   * @private
   */
  private joinChunks(document: DbItem): Blob {
    // We return an empty blob if we have no attachment (for example a news with no content)
    if (!document._attachments) {
      return new Blob();
    }
    // doc._attachments is an Object, therefore it's not an Iterable
    const blobArray: Blob[] = Object.values(document._attachments).map(
      (fragment: PouchDB.Core.FullAttachment) =>
        (navigator as any).userAgent.toLowerCase().indexOf(" electron/") >= 0
          ? new Blob([new Uint8Array(fragment.data as Buffer)])
          : (fragment.data as Blob)
    );
    return new Blob(blobArray, { type: blobArray[0].type });
  }
}
