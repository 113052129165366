import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Event, NavigationEnd, Router, RouterEvent } from "@angular/router";
import { filter, Observable, map, startWith, shareReplay, Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ConfService } from "@viewer/core";
import { NewsUtilsService } from "@viewer/news/services/news-utils.service";
import { NewsModalComponent } from "@viewer/news/news-modal/news-modal.component";
import { News } from "@orion2/models/tocitem.models";
import { HomeRoute } from "@viewer/home/models";
import { TitleService } from "@viewer/header/title.service";
import { DegradedStateService } from "libs/http/degraded-state/degraded-state.service";

@Component({
  selector: "o-layout-home",
  templateUrl: "./layout-home.component.html",
  styleUrls: ["./layout-home.component.scss"]
})
export class LayoutHomeComponent implements OnInit, OnDestroy {
  @ViewChild("settingsSidenav") public settingsSidenav: MatSidenav;
  @ViewChild("searchSidenav") public searchSidenav: MatSidenav;
  public currentRoute: Observable<HomeRoute>;
  public homeRoute = HomeRoute;
  private subscription: Subscription;

  constructor(
    public confService: ConfService,
    public titleService: TitleService,
    private dialog: MatDialog,
    private router: Router,
    private newsUtilsService: NewsUtilsService,
    private degradedStateHandler: DegradedStateService
  ) {}

  public get badgeNumber(): Observable<number> {
    return this.newsUtilsService.applicationNews.pipe(
      map((newsList: News[]) => this.newsUtilsService.countUnreadNews(newsList))
    );
  }

  public get isDegradedState(): Observable<boolean> {
    return this.degradedStateHandler.degradedState.pipe(shareReplay());
  }

  ngOnInit() {
    this.currentRoute = this.getCurrentRoute();
    this.subscription = this.degradedStateHandler.degradedState
      .pipe(filter(isDegraded => !!isDegraded && this.searchSidenav.opened))
      .subscribe(() => this.searchSidenav.close());
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public scrollTop(): void {
    window.scroll(0, 0);
  }

  public openNewsModal(): void {
    this.dialog.open(NewsModalComponent, { panelClass: "news-modal" });
  }

  private getCurrentRoute(): Observable<HomeRoute> {
    return this.router.events.pipe(
      filter((event: Event | RouterEvent) => event instanceof NavigationEnd),
      startWith(this.router),
      map((event: RouterEvent) => event.url.match(/\/home(\/tabs)?\/[a-z-]+/)[0]),
      filter((route: string): route is HomeRoute =>
        Object.values(HomeRoute).includes(route as HomeRoute)
      )
    );
  }
}
