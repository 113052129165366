import xpath from "xpath";
import { ARRAY_SEPARATOR, OBJECT_SEPARATOR, Util } from "@orion2/utils/datamodule.utils";
import { PartNamespace } from "@orion2/ipc-formatter/types/IpcPart";
import { formatCSN, getStatus } from "@orion2/utils/functions.utils";
import { isNoNumber } from "@orion2/utils/front.utils";

export class IpcFormatter {
  public static xpathSelect = xpath.useNamespaces({
    xlink: "http://www.w3.org/1999/xlink"
  });

  public static serializeFromXML(
    partXml: Node,
    dmcXml: Node,
    csnId: string,
    csnStatus: string,
    indenture: string
  ): { partData: string[]; partApplicMD5: string } {
    if (partXml.nodeName === "ISN") {
      return this.extractLegacy(partXml, dmcXml, csnId, indenture);
    } else if (partXml.nodeName === "itemSeqNumber") {
      return this.extractS1000D(partXml, dmcXml, csnId, csnStatus, indenture);
    } else {
      throw new Error("Unkown part");
    }
  }

  public static unserialize(data: string[]): PartNamespace.IpcPart {
    return {
      csn: data[0],
      isn: data[1],
      parent: data[2],
      versions: data[3].split(ARRAY_SEPARATOR),
      status: getStatus(data[4]),
      label: data[5],
      qty: data[6],
      totalQty: data[7],
      airbusPN: this.unserializeEPN(data[8]),
      equivalentPartNb: this.unserializeEPN(data[9]),
      referredBy: this.unserializeRefer(data[10]),
      referTo: this.unserializeRefer(data[11]),
      nsn: data[12],
      mpn: data[13],
      mfc: data[14],
      applic: data[15],
      indenture: data[16],
      isProcurable: data[17] === "true"
    };
  }

  public static extractLegacy(
    isnXml: Node,
    dmXml: Node,
    csnId: string,
    indenture: string
  ): { partData: string[]; partApplicMD5: string } {
    const dmc = this.xpathSelect("string(dmodule/WP6Status/dmc)", dmXml).toString();
    const versions = xpath
      .select("string(dmodule/WP6Status/WP6ApplicList/applic/@version)", dmXml)
      .toString();
    const id = this.xpathSelect("string(@ID)", isnXml).toString();
    let status = this.xpathSelect("string(@CHANGE)", isnXml).toString();
    switch (status) {
      case "N":
        status = "new";
        break;
      case "R":
        status = "changed";
        break;
      case "U":
        status = "unchanged";
        break;
      case "D":
        status = "deleted";
        break;
      default:
        status = "";
        break;
    }
    const label = Util.cleanXml(this.xpathSelect("string(.//DFP)", isnXml));
    const qty = this.xpathSelect("string(QNA)", isnXml).toString();
    const totalqty = this.xpathSelect("string(TQY)", isnXml).toString();
    const airbusPN = this.xpathSelect("ECPNR", isnXml)
      .map((el: Node) => el.textContent)
      .join(ARRAY_SEPARATOR);
    const equivalentPartNb = this.xpathSelect("OPN|.//DFL", isnXml)
      .map((el: Node) => el.textContent)
      .filter(epn => epn != "NP")
      .join(ARRAY_SEPARATOR);
    const isNP = this.xpathSelect(".//DFL", isnXml)
      .map((el: Node) => el.textContent)
      .includes("NP");
    const referTo =
      this.xpathSelect("string(.//CSNREF/@REFCSN)", isnXml).toString() +
      OBJECT_SEPARATOR +
      this.xpathSelect("string(.//CSNREF/@xlink:href)", isnXml).toString();
    const nsn = this.xpathSelect("string(NSN/@NSN)", isnXml).toString();
    const mpn = this.xpathSelect("string(PNR)", isnXml).toString();
    const mfc = this.xpathSelect("string(MFC)", isnXml).toString();
    const applicability = this.xpathSelect("string(.//MOV/@MOV)", isnXml).toString();

    const isProcurable = !mpn || isNoNumber(mpn) || isNP ? "false" : "true";
    return {
      partData: [
        formatCSN(csnId),
        id,
        dmc,
        versions,
        status,
        label,
        qty,
        totalqty,
        airbusPN,
        equivalentPartNb,
        "", // referredBy,
        referTo,
        nsn,
        mpn,
        mfc,
        applicability,
        indenture,
        isProcurable
      ],
      partApplicMD5: ""
    };
  }

  public static extractS1000D(
    isnXml: Node,
    dmcXml: Node,
    csnId: string,
    csnStatus: string,
    indenture: string
  ): { partData: string[]; partApplicMD5: string } {
    const dmc = this.xpathSelect("string(dmodule/WP6Status/dmc)", dmcXml).toString();
    const versions = xpath
      .select("string(dmodule/WP6Status/WP6ApplicList/applic/@version)", dmcXml)
      .toString();
    let status = this.xpathSelect("string(@changeType)", isnXml).toString() || csnStatus;
    switch (status) {
      case "N":
      case "add":
        status = "new";
        break;
      case "R":
      case "modify":
        status = "changed";
        break;
      case "D":
      case "delete":
        status = "deleted";
        break;
      default:
        status = "unchanged";
        break;
    }
    const qty = this.xpathSelect("string(quantityPerNextHigherAssy)", isnXml).toString();
    const totalqty = this.xpathSelect("string(totalQuantity)", isnXml).toString();
    const nsn = this.xpathSelect("string(partSegment//natoStockNumber)", isnXml).toString();
    const label = Util.cleanXml(this.xpathSelect("string(partSegment//descrForPart)", isnXml));
    const mfc = this.xpathSelect("string(partRef/@manufacturerCodeValue)", isnXml).toString();
    const mpn = this.xpathSelect("string(partRef/@partNumberValue)", isnXml).toString();
    const id = this.xpathSelect("string(@itemSeqNumberValue)", isnXml).toString();

    const referredBy = this.xpathSelect(".//referTo[@refType='rft01']/catalogSeqNumberRef", isnXml)
      .map(
        (csnRef: Node) =>
          this.xpathSelect(
            "string(concat(@systemCode, '-', @subSystemCode, @subSubSystemCode, '-', @assyCode, '-', @figureNumber, '-', @item))",
            csnRef
          ).toString() +
          OBJECT_SEPARATOR +
          this.xpathSelect("string(@xlink:href)", csnRef).toString()
      )
      .join(ARRAY_SEPARATOR);
    const referTo = this.xpathSelect(".//referTo[@refType='rft02']/catalogSeqNumberRef", isnXml)
      .map(
        (csnRef: Node) =>
          this.xpathSelect(
            "string(concat(@systemCode, '-', @subSystemCode, @subSubSystemCode, '-', @assyCode, '-', @figureNumber, '-', @item))",
            csnRef
          ).toString() +
          OBJECT_SEPARATOR +
          this.xpathSelect("string(@xlink:href)", csnRef).toString()
      )
      .join(ARRAY_SEPARATOR);
    const equivalentPartNb = this.xpathSelect("partSegment//partRef", isnXml)
      .map(
        (partRef: Node) =>
          this.xpathSelect("string(@partNumberValue)", partRef).toString() +
          OBJECT_SEPARATOR +
          this.xpathSelect("string(@manufacturerCodeValue)", partRef).toString()
      )
      .join(ARRAY_SEPARATOR);
    const applicMD5 = this.xpathSelect("string(@applicability_md5)", isnXml).toString();
    const isProcurable =
      this.xpathSelect(
        "string(locationRcmdSegment/locationRcmd/sourceMaintRecoverability)",
        isnXml
      ).toString() === "XBZZZ"
        ? "false"
        : "true";

    return {
      partData: [
        formatCSN(csnId),
        id,
        dmc,
        versions,
        status,
        label,
        qty,
        totalqty,
        "", // airbusPN,
        equivalentPartNb,
        referredBy,
        referTo,
        nsn,
        mpn,
        mfc,
        "", //applicability
        indenture,
        isProcurable
      ],
      partApplicMD5: applicMD5
    };
  }

  private static unserializeRefer(data: string): PartNamespace.Refer[] {
    return !!data
      ? data
          ?.split(ARRAY_SEPARATOR)
          .map(el => el.split(OBJECT_SEPARATOR))
          .map(obj => ({ label: obj[0], link: obj[1] }))
      : [];
  }

  private static unserializeEPN(data: string): PartNamespace.EPN[] {
    return !!data
      ? data
          ?.split(ARRAY_SEPARATOR)
          .map(el => el.split(OBJECT_SEPARATOR))
          .map(obj => ({ mpn: obj[0], mfc: obj[1] }))
      : [];
  }
}
