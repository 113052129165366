/* eslint-disable @typescript-eslint/no-explicit-any */
import { CoreCaller } from "@viewer/core/pouchdb/core/coreCaller";
import { DBConnexionType } from "@viewer/core/pouchdb/types";
import { Injector } from "@angular/core";
import { DbSchema } from "libs/transfert/model/pubSchema";
import { DbItem, TocInfo } from "@orion2/models/couch.models";
import { ParamFindTocItem, TocItem, TocPublicType } from "@orion2/models/tocitem.models";
import { Design } from "libs/design/design";
import { TocItemUtil } from "@orion2/utils/toc-items.utils";

/**
 * Represent Toc database
 *
 * @export
 * @class TocCaller
 * @extends {CoreCaller}
 */
export class TocCaller extends CoreCaller {
  // The index emit doc.parent to go with actual Toc nodes
  protected indexDoc = Design.buildChildrenDesign();
  private cache: Map<string, Promise<any>>;

  constructor(processor: any, protected injector: Injector, dbSchema: DbSchema) {
    super(dbSchema, processor, injector);
    this.cache = new Map<string, Promise<any>>();
  }

  public getAttachmentsBlob(docId: string): Promise<Blob> {
    // SPEC: When we getting both databases dbParam.isOffline can be true, but the document is not stored in local database (for exemple news)
    return super.callFunction(
      "getAttachmentsBlob",
      [docId],
      this.store.isLoggedIn ? DBConnexionType.REMOTE : DBConnexionType.LOCAL
    );
  }

  public load(): Promise<void> {
    return this.callFunction<DBConnexionType>("loadInMemoryStrategy")
      .then(dbType => this.refreshIndex(dbType))
      .catch(e => {
        console.error(e);
      });
  }

  public ensureIndexIsBuilt(): Promise<any> {
    // We simply need to replicate it from remote (only if we are in online mode) if it's not found locally
    // then ensure it's built. If not found then create it.
    // Creation is needed for users' toc.

    let dbType = this.dbParams.isOffline ? DBConnexionType.LOCAL : DBConnexionType.REMOTE;
    dbType = this.getRealTarget(dbType);

    return this.doOnInstance(dbType, "get", ["_design/children"]).then(designChildren => {
      // If app's doc date is higher than DB doc date, it's an old design doc
      const isOldDesign: boolean =
        !designChildren ||
        new Date(this.indexDoc.lastUpdate).getTime() >
          new Date(designChildren.lastUpdate).getTime();
      const prom: Promise<unknown> = isOldDesign ? this.setLocal(this.indexDoc) : Promise.resolve();
      // Trigger sync only when user logged in or don't use authentication (dev)
      // If user doesn't have rights to write on remote, it won't update remote
      return prom
        .then(() => dbType !== DBConnexionType.LOCAL && this.sync(["_design/children"]))
        .then(() => this.refreshIndex());
    });
  }

  public refreshIndex(dbType = DBConnexionType.LOCAL, index = "children") {
    return this.doOnInstance(dbType, "query", [
      index,
      { include_docs: false, limit: 0, update_seq: true }
    ]);
  }

  // params is used to pass startkey, endkey etc.
  public children(key: string, params = { include_docs: true }): Promise<TocInfo[]> {
    const queryParam = {
      key,
      ...params
    };
    const cacheKey: string = JSON.stringify(["children", queryParam]);
    if (!this.cache.has(cacheKey)) {
      this.cache.set(cacheKey, this.do("query", ["children", queryParam]));
    }
    return this.cache.get(cacheKey).then(results => results?.rows || []);
  }

  // returns true if success, false otherwise
  public save(tocItem: TocInfo | TocItem): Promise<DbItem> {
    return this.setLocal(tocItem);
  }

  delete(tocItem: TocInfo | TocItem): Promise<any> {
    return this.deleteLocal(tocItem._id);
  }

  public getItemsOfType(
    type: string,
    target: DBConnexionType = DBConnexionType.LOCAL,
    parents?: string | string[]
  ): Promise<TocItem[]> {
    const pubInfo = this.processor.getPublicationInfo();
    // Get the real target for exemple we don't want to get remote data if the user isn't authenticated
    // For exemple for news or preprint
    target = this.getRealTarget(target);

    if (target === DBConnexionType.BOTH) {
      return Promise.all(
        [DBConnexionType.REMOTE, DBConnexionType.LOCAL].map((targetDb: DBConnexionType) =>
          this.findTocItems(targetDb, type, pubInfo.revision, parents)
        )
      ).then(([remoteTocItems, localTocItems]: [TocItem[], TocItem[]]) =>
        // Remove duplicate object with the same _id
        [...remoteTocItems, ...localTocItems].filter(
          (item: TocItem, index: number, array: TocItem[]) =>
            array.findIndex((item2: TocItem) => item2._id === item._id) === index
        )
      );
    } else {
      return this.findTocItems(target, type, pubInfo.revision, parents).then(
        (docs: TocItem[]) => docs || []
      );
    }
  }

  /**
   * Checks if the user should synchronize their personnal data.
   * It will check in remote if there is some missing data, or missing couchdb
   * revision and potential conflicts.
   *
   * @memberof TocCaller
   */
  public shouldSynchronize(): Promise<boolean> {
    // We want to check if we should synchronize only if the user is authenticated.
    const shouldDiff = this.confService.useAuth && this._checkShouldSynchroniseStoreData();

    return shouldDiff ? this.callFunction("shouldSynchronize") : Promise.resolve(false);
  }

  public getItemsForTarget(
    targetId: string,
    type: string,
    include_docs = true,
    target = DBConnexionType.LOCAL
  ): Promise<TocItem[]> {
    return this.doAllDocs(targetId, include_docs, target === DBConnexionType.LOCAL).then(
      (docs: { rows: TocItem[] }) => {
        // Get all document with the same type and get the latest document (by date) if there is the some duplicate document
        // In case the target in BOTH (for exemple for news)
        const docsFiltered = TocItemUtil.filter(
          docs?.rows,
          type,
          this.processor.publicationRevision
        );
        const map = new Map<string, TocItem>();
        docsFiltered.forEach((e: TocItem) => this.getLatestVersionOfDoc(e, map));
        return Array.from(map.values());
      }
    );
  }

  public get<TocInfo>(args: string | Object): Promise<TocInfo | TocInfo[]> {
    const cacheKey: string = JSON.stringify(["get", args]);
    if (!this.cache.has(cacheKey)) {
      this.cache.set(cacheKey, super.get<TocInfo | TocInfo[]>(args));
    }
    return this.cache.get(cacheKey);
  }

  protected init(): Promise<any> {
    return this.ensureIndexIsBuilt();
  }

  protected ensureFilterIndexIsBuilt(filterName: string): Promise<unknown> {
    // We simply need to replicate it from remote (only if we are in online mode) if it's not found locally
    // then ensure it's built. If not found then create it.
    // Creation is needed for users' toc.
    let dbType = this.dbParams.isOffline ? DBConnexionType.LOCAL : DBConnexionType.REMOTE;
    dbType = this.getRealTarget(dbType);

    return this.doOnInstance(dbType, "get", [filterName]).then(designFilter => {
      const prom = !designFilter ? this.addFilterIndex() : Promise.resolve();
      // Trigger sync only when user logged in or don't use authentication (dev)
      // If user doesn't have rights to write on remote, it won't update remote
      return prom.then(() => dbType !== DBConnexionType.LOCAL && this.sync([filterName]));
    });
  }

  protected addFilterIndex(): Promise<unknown> {
    return this.callFunction("createIndex", Design.indexFilterTypeMin());
  }

  /**
   * Get all TocItems for the given type.
   *
   * @private
   * @param {DBConnexionType} target
   * @param {string} type
   * @param {string} pubRev
   * @param {string | string[]} parents
   * @param {string} [bookmark=undefined]
   * @return {Promise<TocItem[]>}
   * @memberof TocCaller
   */
  private findTocItems(
    target: DBConnexionType,
    type: string,
    pubRev: string,
    parents: string | string[],
    bookmark: string = undefined,
    skip = 0
  ): Promise<TocItem[]> {
    const batch = 250;
    const params: ParamFindTocItem = {
      selector: {
        $and: [
          {
            type
          }
        ]
      },
      limit: batch
    };

    // SPEC: Toc Items on ORION TOC PUBLIC don't have any revisions
    if (pubRev) {
      params.selector.$and.push({
        $or: [
          {
            minRevision: {
              $lte: pubRev
            }
          },
          {
            minRevision: {
              $exists: false
            }
          }
        ]
      });
    }

    if (type !== TocPublicType.SB && type !== TocPublicType.DOCUMENT) {
      params.selector.$and.push({
        $or: [
          {
            maxRevision: {
              $gt: pubRev
            }
          },
          {
            maxRevision: {
              $exists: false
            }
          }
        ]
      });
    }

    if (bookmark) {
      params["bookmark"] = bookmark;
    } else {
      if (skip) {
        params["skip"] = skip;
      }
    }

    if (parents?.length > 0) {
      const [parentArr, additionalSelector] = Array.isArray(parents)
        ? [parents.filter(Boolean), {}]
        : [[parents], { _id: { $eq: parents } }];

      params.selector.$and.push({
        $or: [{ parents: { $in: parentArr } }, additionalSelector]
      });
    }

    return this.doOnInstance(target, "find", params).then(
      (res: { rows: TocItem[]; bookmark: string }) => {
        const tocItems = res?.rows || [];

        if (tocItems.length === batch) {
          // SPEC: We use bookmark or skip depending on the platform
          // bookmark property work only with HttpAdaptor, and return undefined on electron and cordova
          // in theses cases we use the skip param
          // https://github.com/pouchdb/pouchdb/issues/8497
          if (!res.bookmark) {
            skip += batch;
          }

          return this.findTocItems(target, type, pubRev, parents, res.bookmark, skip).then(
            (resProm: TocItem[]) => [...tocItems, ...resProm]
          );
        }

        return tocItems;
      }
    );
  }

  private doAllDocs(
    targetId: string,
    include_docs: boolean,
    offline: boolean = this.dbParams.isOffline
  ): Promise<any> {
    // We want to get in remote only if user is authenticated and if the database isn't in offline mode
    // If authent is disabled in conf and database isn't in offline mode we going to get children in remote too
    let target = offline ? DBConnexionType.LOCAL : DBConnexionType.BOTH;
    target = this.getRealTarget(target);

    return this.doOnInstance(target, "allDocs", [
      {
        startkey: targetId,
        endkey: targetId + "\ufff0",
        include_docs
      }
    ]);
  }

  private getLatestVersionOfDoc(doc: TocItem, map: Map<string, TocItem>): TocItem {
    const otherDoc = map.get(doc._id);
    if (otherDoc && new Date(otherDoc.date).getTime() > new Date(doc.date).getTime()) {
      return otherDoc;
    }
    map.set(doc._id, doc);
    return doc;
  }

  /**
   * Checks some data in the store to see if the user should synchronise.
   *
   * @returns true if the data in the store allows synchronisation, false otherwise.
   */
  private _checkShouldSynchroniseStoreData(): boolean {
    return (
      this.store.isLoggedIn &&
      this.store.userAutoSynchro &&
      this.store.syncSettings?.enable &&
      this.store.pubInfo?.capabilities?.sync
    );
  }
}
