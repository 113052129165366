import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  RendererFactory2,
  ElementRef
} from "@angular/core";
import { Store } from "@viewer/core/state/store";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import {
  infoFromLink,
  getOnclickAttribute,
  querySelectorAllToList
} from "@viewer/content-provider/dom-manipulator";
import {
  CmPreviewComponent,
  CmPreviewDataSource
} from "@viewer/shared-module/cm-preview/cm-preview.component";
import { CmData, CmPreviewService } from "@viewer/shared-module/cm-preview/cm-preview.service";
import { IpcPreviewComponent } from "@viewer/shared-module/ipc-preview/ipc-preview.component";
import { IpcPreviewService } from "@viewer/shared-module/ipc-preview/ipc-preview.service";
import { MultilinksComponent } from "@viewer/shared-module/multilinks/multilinks.component";
import {
  MultiLinkMeta,
  MultilinksService
} from "@viewer/shared-module/multilinks/multilinks.service";
import { AlsMsmSignatureComponent } from "@viewer/fulltext-module/als-msm-signature/als-msm-signature.component";
import { ComponentPortal, DomPortalOutlet } from "@angular/cdk/portal";
import { CardComponent } from "@viewer/shared-module/card/card.component";
import { runInAction } from "mobx";
import { MediaService } from "@viewer/media-module/media/media.service";
import { HistoricService } from "@viewer/core/toc-items/historic.service";
import markjs from "mark.js";
import { separatorRegex } from "@orion2/utils/constants.utils";
import xpath from "xpath";
import { base64ToUtf8 } from "@viewer/shared-module/helper.utils";
import { getSymbolId } from "@orion2/utils/functions.utils";
import { HistoryElem } from "@orion2/models/tocitem.models";

@Injectable()
export class BasicViewService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interval: any;
  public loading: boolean;
  links: Object[] = [];
  private _renderer;
  constructor(
    public store: Store,
    protected route: ActivatedRoute,
    protected router: Router,
    protected rendererFactory: RendererFactory2,
    protected dialog: MatDialog,
    private multilinkService: MultilinksService,
    private cmPreviewService: CmPreviewService,
    private ipcPreviewService: IpcPreviewService,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private mediaService: MediaService,
    private historicService: HistoricService
  ) {
    // renderer2 can be use direcly in service https://stackoverflow.com/questions/43070308/using-renderer-in-angular-4
    this._renderer = rendererFactory.createRenderer(null, null);
  }

  // keep content in store in case of view switch
  // append child will remove it
  // innerHtmll to prvent siwtching du keeping the same view
  public insertContent(target: ElementRef) {
    if (
      this.store.duObject &&
      this.store.duObject.data &&
      this.store.currentDMC === this.store.duObject.dmc
    ) {
      target.nativeElement.innerHTML = "";
      const clonedContent = this.store.duObject.data.cloneNode(true);
      target.nativeElement.appendChild(clonedContent);
      runInAction(() => {
        this.store.displayDone = true;
      });
    } else {
      console.warn("No data in du object, it's weird");
    }
  }

  /**
   * Event aren't clone so we need to recreate then
   *
   * @param clonedContent
   */
  public addMediaEvents(clonedContent: Element) {
    this.addMediaLinks(clonedContent);
    this.addProcThumbEvent(clonedContent);
    this.addMediaLinkTitle(clonedContent);
  }

  /**
   * Add all media link : span.FIGITEM_CLASS
   *
   * @param content
   * @param mediaToGraphicMap
   */
  // mediaToGraphicMap is used to deal with media LEGEND where the same LEGEND can be shared by several media
  // so the graphicId has a 1 to n relation with mediaId.
  public addMediaLinks(content: Element, mediaToGraphicMap = {}): void {
    Array.from(
      content.querySelectorAll(
        // in media LEGEND, links can be on element .legendRowId in which case hotspot-legend-id.length > 0 or
        // links can be on child elements in which case hotspot-legend-id.length = 0 and no click should be added on .legendRowId.
        "span.FIGITEM_CLASS, .wdmlist_table_class span.span_links[onclick*='displayGraphicElement'], .legendRowId:not([hotspot-legend-id = ''])"
      )
    ).forEach(elem => {
      elem.innerHTML = elem.innerHTML.trim();
      this._addMediaLink(elem, mediaToGraphicMap);
    });
  }

  /**
   * Add title to header media link
   *
   * @param clonedContent
   */
  // SPEC: Legacy : add figure title on each FIGITEM_CLASS element
  // which is a sibling of .TOC and whose HTMLText contains figure
  // SPEC: S1000D : add figure title on each span with internalreftargettype attribute equal to 'irtt01'
  // and whose HTMLText contains figure
  public addMediaLinkTitle(clonedContent: Element) {
    const headersFigureLink = this.store.isVendors
      ? Array.from(
          clonedContent.querySelectorAll(
            "span[internalreftargettype='irtt01'], span[internalreftargettype='irtt09']"
          )
        )
      : [];

    if (!this.store.isVendors) {
      const tocElements = clonedContent.querySelectorAll(".TOC");
      tocElements.forEach((element: Element) => {
        element.parentNode.querySelectorAll(".FIGITEM_CLASS").forEach((figItem: Element) => {
          headersFigureLink.push(figItem);
        });
      });
    }

    headersFigureLink.forEach((figureLink: Element) => {
      const id = this.store.isVendors
        ? figureLink.getAttribute("infoEntityIdent")
        : figureLink.getAttribute("id");
      if (id && figureLink.innerHTML.match(/figure|animation/gi)) {
        const figNbr = this.store.textNumberingMap.get(id);
        const title = this.store.figureTitles.get(id);
        figureLink.innerHTML = figNbr + (title ? ": " + title : "");
      }
    });
  }

  /**
   * Create redirection to media first view and remove old onclick handler
   *
   * @param element
   */
  public _addMediaLink(element: Element, mediaToGraphicMap = {}): void {
    const { functionName, imgId, hotspotId } = infoFromLink(element);
    const isOnLegend = element.closest("tr")?.classList.contains("legend-row");
    // This is to deal with media LEGEND hotspot links where
    // we want to highligh the whole line so when
    // .legend-row > .FIGITEM_CLASS we have to set attributes on the parent as well.
    // WARNING : in those cases, .legend-row[hotspot-legend-id] is "" and no onclick is added.
    // see addMediaLinks above.
    if (element && element.classList.contains("FIGITEM_CLASS")) {
      const tableRow = element.parentElement.parentElement;
      if (tableRow && tableRow.classList.contains("legend-row")) {
        tableRow.setAttribute("onclick", "");
        tableRow.setAttribute("hotspot_id", `${mediaToGraphicMap[imgId]}_${hotspotId}`);
      }
    }
    element.setAttribute("onclick", "");
    // for the moment, hotspot_id is only use by legend.component.
    element.setAttribute("hotspot_id", `${mediaToGraphicMap[imgId]}_${hotspotId}`);
    element.setAttribute("id", imgId);
    element.setAttribute("hotspot", hotspotId);
    this._renderer.listen(element, "click", _event => {
      this._setMediaLinks(functionName, imgId, hotspotId, isOnLegend);
    });
  }

  public addDuLinks(content: Element | Document): void {
    const tab = querySelectorAllToList(
      content.querySelectorAll(".span_links, span.AHX4_links_Enriched_class, .retroLinks-panel")
    );
    tab.map(this.addDuLink.bind(this));
  }

  /**
   * Create dynamic Card component
   *
   * @private
   * @param attributes
   * @param element
   * @memberof BasicViewService
   */
  showCard(element) {
    const titleDiv = element.querySelector(".retroLinks-title-div");
    const subtitle = element.querySelector(".retroLinks-subtitle");
    titleDiv.appendChild(subtitle);
    element.querySelectorAll(".retroLinksEnrichedClass").forEach(link => {
      const targets = getOnclickAttribute(link)[1].split("||");
      const data = {
        dmc: targets[0]
      };
      const cardPortal = new ComponentPortal(CardComponent);
      const portalHost = new DomPortalOutlet(element, this.resolver, this.appRef, this.injector);
      const compRef = portalHost.attach(cardPortal);
      const oCard: CardComponent = compRef.instance;
      oCard.cardData = data;
    });
    const firstUl = element.querySelector(".retroLinks-first-ul");
    element.removeChild(firstUl);
  }

  public goToTarget(attributes, element: Element) {
    const targets = attributes[1].split("||");
    element.setAttribute("onclick", "");
    element.setAttribute("href", attributes[1]);
    this._renderer.listen(element, "click", event => {
      // Should not break anything
      // Needed for CM Preview click on links and not trigger click on card
      event.preventDefault();
      event.stopImmediatePropagation();

      if (targets.length === 1) {
        const historic = {
          // Remove attributes for pre/post mod for example in order to get only the DMC
          // In future US we will pass all params in order to scroll to the good section
          dmc: attributes[1].split("##")[0]
        } as HistoryElem;
        // SPEC: history from DMC
        this.historicService.updateCurrentDMRoot(historic, this.store.currentDMC);
        return this.handleSimpleLink(attributes);
      }
      return this.handleMultiLink(targets);
    });
  }

  /**
   * Inject a new AlsMsmSignatureComponent into a given element
   *
   * @param element
   * @memberof BasicViewService
   */
  public createSignatureTable(element: Element) {
    const signaturePortal = new ComponentPortal(AlsMsmSignatureComponent);
    const signaturePortalHost = new DomPortalOutlet(
      element,
      this.resolver,
      this.appRef,
      this.injector
    );
    signaturePortalHost.attach(signaturePortal);
  }

  /**
   * add a column "Initial" on ALS / MSM tables for display it only on print
   * add some classes on header for display ajustements
   */
  public addInitialColumn(frag: Element) {
    const headers = frag.querySelectorAll(".sub_header_table_class");
    Array.from(headers).forEach(header => {
      Array.from(header.querySelectorAll("th")).forEach(th => {
        if (th.querySelector(".manufacturerPartNumberTableClass")) {
          th.classList.add("mpn_column");
        }
        if (th.innerText === "Initial") {
          th.classList.add("display_none");
        }
      });
      const msmHeaderDiv = document.createElement("div");
      const initialHeader = document.createElement("div");
      msmHeaderDiv.classList.add("msm_header");
      initialHeader.classList.add("initial_header");
      initialHeader.innerText = "Initial";
      (header.parentNode as Element).classList.add("with_initial");
      header.parentNode.appendChild(msmHeaderDiv);
      header.parentNode.appendChild(initialHeader);
      msmHeaderDiv.appendChild(header);
    });

    const tasks = frag.querySelectorAll(
      ".maintask_container_class, .maintask_dashed_container_class"
    );
    Array.from(tasks).forEach(task => {
      const msmTableDiv = document.createElement("div");
      msmTableDiv.classList.add("msm_table");
      const initialTable = document.createElement("div");
      initialTable.classList.add("initial_table");
      task.parentNode.appendChild(msmTableDiv);
      msmTableDiv.appendChild(task);
      msmTableDiv.appendChild(initialTable);
    });
  }

  createSymbol(symbolNode) {
    // symbolNode is a div tag used to locate the position of the symbol
    const alt = symbolNode.getAttribute("alt");
    const symbolId = getSymbolId(this.store.isLegacyImport, this.store.currentDMC, alt);
    this.getStepSymbol(symbolId).then(image => {
      if (!image) {
        return;
      }
      if (symbolNode.parentNode.classList.contains("AHX4_SPAN")) {
        // symbols in safety conditions
        image.classList.replace("symbol", "safetySymbol");
        // symbols are placed before span element in XXXX_CLASS_AHX4
        const selector =
          ".WARNING_CLASS_AHX4 > span," + ".CAUTION_CLASS_AHX4 > span," + ".NOTE_CLASS_AHX4 > span";

        const safetyNode = symbolNode.parentNode.parentNode.querySelector(selector);
        safetyNode.parentNode.insertBefore(image, safetyNode);
      } else {
        // symbols in a li tag or symbols in procedural steps
        // symbols in procedural steps are placed after last child of its parent
        if (symbolNode.parentNode.tagName === "LI" || symbolNode.parentNode.tagName === "TD") {
          symbolNode.parentNode.appendChild(image);
        } else {
          // SPEC: Symbol must be after the paragraph and before the figure.
          // So we have to insert it before the figure (if there is one) and after the paragraph (if there is no figure)
          const figContainer = symbolNode.parentNode.parentNode.querySelector(".fig-container");
          if (figContainer) {
            figContainer.parentNode.insertBefore(image, figContainer);
          } else {
            symbolNode.parentNode.parentNode.appendChild(image);
          }
        }
      }
      symbolNode.parentNode.removeChild(symbolNode);
    });
  }
  // Get image from database with symbol illustration id
  public getStepSymbol(symbolId: string): Promise<HTMLElement> {
    return this.mediaService.getMedia(symbolId).then(metadata => {
      if (!metadata.data) {
        return undefined;
      }
      const isSVG = metadata.FORMAT.toLowerCase() === "svg";
      const image = document.createElement(isSVG ? "div" : "img");
      image.setAttribute("alt", symbolId);
      image.setAttribute("class", "symbol");
      if (isSVG) {
        image.innerHTML = base64ToUtf8(metadata.data);
        return image;
      }
      // Symbol is image
      const source = "data:image/jpg;base64," + metadata.data;
      image.setAttribute("src", source);
      return image;
    });
  }

  /**
   * Replace first section with title "General" if none already exists
   */
  public setGeneralInfoTitle() {
    const sections = this.store.duObject.data.querySelectorAll(
      ".AHX4_proced_level_label_type1_class"
    );
    for (const section of sections) {
      if (section && section.innerHTML.length === 0) {
        section.innerHTML = "General";
        section.classList.add("general");
        break;
      }
    }
  }

  /**
   * Naviguates to another step
   *
   * @param stepId the step to naviguate
   * @param hotspotId
   */
  public navigateToStepByStep(stepId: string, hotspotId?: string) {
    this.router.navigate(["./fulltext/stepbystep", stepId], {
      relativeTo: this.route.firstChild.firstChild.firstChild,
      queryParams: {
        hotspotId: hotspotId || undefined
      },
      replaceUrl: true
    });
  }

  /**
   * Highlight words in searchInput in the fulltext element
   *
   * @param input
   * @param eleRef
   */
  public highlightSearchInput(eleRef: ElementRef, input = this.store.highlightKeyword) {
    if (input) {
      // SPEC: we use separatorRegex for do not highlight search separator character
      const words = input.split(separatorRegex);
      for (const word of words) {
        const regex = new RegExp(this.convertSheDmcToShortDmc(word), "gi");
        this.addMarkForAttributeMatch(eleRef, regex);
        new markjs(eleRef.nativeElement).markRegExp(regex);
      }
    }
    return input;
  }

  // Highlight all hotspots = hostpotId in the fulltext
  public highlightHotspot(hostpotId: string): void {
    const markedText = "marked-text";
    // build hostpotId with initials 0
    const hostpotIdCompleted = hostpotId.replace(
      /\d+/,
      hostpotId.replace(/\D/g, "").padStart(3, "0")
    );
    // const hostpotIdCompleted = hostpotId.padStart(3, "0");
    // reset hotspots and attributes highlighting
    this.removeClass(markedText);
    if (hostpotId) {
      const hotspotParam = this.store.isS1000D ? "internalhotspotid" : "hotspot";
      const selector = `.fulltext-content [${hotspotParam}`;
      // find with or with initials 0
      document
        .querySelectorAll(`${selector}='${hostpotId}'], ${selector}='${hostpotIdCompleted}']`)
        .forEach((element: Element) => {
          element.classList.add(markedText);
        });
    }
  }

  /**
   * Add <mark> on parent of any element that title or href attributes match the regex
   * That for highlight the element
   *
   * @param eleRef
   * @param regex
   */
  public addMarkForAttributeMatch(eleRef: ElementRef, regex: RegExp) {
    this.actionAttributeMatch(eleRef, regex, (element: Element) =>
      element.classList.add("marked-text")
    );
  }

  public actionAttributeMatch(
    eleRef: ElementRef,
    regex: RegExp,
    action: (element: Element) => void
  ) {
    const fulltextElements = eleRef.nativeElement.querySelectorAll("*[title],*[href]");
    fulltextElements.forEach((element: Element) => {
      if (
        element.getAttribute("title")?.match(regex) ||
        element.getAttribute("href")?.match(regex)
      ) {
        action(element);
      }
    });
  }

  public convertSheDmcToShortDmc(dmc: string): string {
    // SPEC: SHE is searching for whole DMC but XSL only displays shortDMC
    // SHE DMC is always like DMC-SHE060-XX-73-23-24-01A01-520A-A
    return dmc.startsWith("DMC-SHE") ? dmc.slice(14) : dmc;
  }

  /**
   * Remove a class from every Elements that contains it.
   *
   * @param className The class to be removed
   */
  public removeClass(className: string): void {
    document.querySelectorAll(`.${className}`).forEach(element => {
      element.classList.remove(className);
    });
  }

  // we want to create a div element with PARENT_INFORMATION class
  // this element must contain the XXXX_BEFORE_CLASS and his sibling (NOTE_CLASS / WARNING_CLASS or CAUTION_CLASS)
  // this is for legacy publication and for uncut a warning/caution/not in print
  // a xsl modification would be more appropriate but too risky
  addParentInformationClass() {
    const beforeClass = document.querySelectorAll(
      ".NOTE_BEFORE_CLASS , .WARNING_BEFORE_CLASS , .CAUTION_BEFORE_CLASS"
    );
    for (const element of Array.from(beforeClass)) {
      const newElement = document.createElement("div");
      newElement.setAttribute("class", "PARENT_INFORMATION");
      const nextSibling = element.nextElementSibling;
      if (nextSibling) {
        const parentInformation = nextSibling.insertAdjacentElement("afterend", newElement);
        parentInformation.insertBefore(element, null);
        parentInformation.insertBefore(nextSibling, null);
      }
    }
  }

  /**
   * Create a DM link hotspot map
   *
   * @param xmlDoc : S1000D xml document of current dmc
   */
  public createDmRefHotspotMap(xmlDoc: Document) {
    const map: string[] = [];
    // we get all hotspot with dmref in xml of current dmc
    const hotspotsWithDmrefs = xpath.select("/dmodule//hotspot/dmRef/..", xmlDoc);

    for (const hotspot of hotspotsWithDmrefs) {
      const hotspotId = xpath
        .select("string(@applicationStructureName)", hotspot as Node)
        .toString();
      const dmRef = (hotspot as Element).querySelector("dmRef").getAttribute("xlink:href");
      map[hotspotId] = dmRef;
    }
    this.store.hotspotDmRefId = map;
  }

  private _setMediaLinks(
    functionName: string,
    imgId: string,
    hotspotId: string,
    isOnLegend = false
  ): void {
    const redirectParam = {
      // change route building,
      // the basicViewService Instance is created a application start with the route url
      // So route.parent doesn't exist
      // Therefore we need to set the route with firstChild element
      relativeTo: this.route.firstChild.firstChild.firstChild,
      replaceUrl: true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    runInAction(() => {
      this.store.view = this.store.view === "default" ? "media" : this.store.view;
    });

    const route = ["fulltext", this.store.view, imgId];

    if (functionName.startsWith("displayGraphicElementAndHighlightingHotspot")) {
      // SPEC: We set hotspotFromSvg for scrolling on first occurence of the hotspotId when clicking on legend
      // We don't want to scroll when clicking on fulltext
      // look reactions in content-provider.ts
      runInAction(() => {
        if (isOnLegend) {
          this.store.hotspotFromSvg = hotspotId;
        } else {
          this.store.hotspotFromText = hotspotId;
        }
      });
      redirectParam.queryParams = { hotspotId };
      this.router.navigate(route, redirectParam);
    } else if (functionName.startsWith("displayGraphicElement")) {
      this.router.navigate(route, redirectParam);
    }
  }

  private addDuLink(element: Element): void {
    const classList = element.classList.value;
    const attributes = getOnclickAttribute(element);
    // for S1000D we want to inject shortDMC + title on display link
    // we get shortDMC by getting result and reference map on store
    let dmc: string = attributes && attributes[1];
    // if attributes is null we don't want to throw an exception
    const functionCalled = (attributes && attributes[0]) || "";
    // SPEC: we can have toc item ids in href
    if (dmc && !dmc.match(/^(preprint|document|sb)/)) {
      dmc = dmc.toUpperCase();
    }

    if (classList.includes("retroLinks-panel")) {
      this.showCard(element);
    }
    // fix case when dmc is not defined
    if (dmc && functionCalled.includes("gotoRefEx")) {
      // SPEC: deactivate links to a TOC node (match XXXX)
      if (dmc.match(/^(\d{4})/g)) {
        element.classList.remove(
          "span_links",
          "AHX4_links_Enriched_class",
          "retroLinksEnrichedClass"
        );
        return;
      }
      // if we have IPC link and multiple targets
      const targets = attributes[1].split("||").length;
      // SPEC the link will activate a preview popup if the link refers to an IPC (dmc.match(/-P_[A-Z]{2}-[A-Z]{2}##/g))
      // except if specified in the gotoRefExt function (see xslt) with a 3rd attribut set to false.
      const isTypeAttrFalse = attributes[3]?.match(/(false)/g);
      // SPEC: DMC is like DMC-AS350-B2-0000-21-51-12-02000-000F-P_EN-EN##21-51-12-020-365##00N
      if (dmc.match(/-P_[A-Z]{2}-[A-Z]{2}##/g) && targets > 1 && !isTypeAttrFalse) {
        return this._showPreview(attributes, element);
      }
      return this.goToTarget(attributes, element);
    }

    // External url : used in S1000D (for exemple SHE DM)
    if (functionCalled.includes("gotoExternal")) {
      const manual = attributes[1] || "";
      const ud = attributes[2] || "";
      const entrepriseCode = attributes[3] || "";
      return this.goToExternal(element, manual, ud, entrepriseCode);
    }

    if (functionCalled.includes("showPreview")) {
      this._showPreview(attributes, element);
    }
    if (functionCalled.includes("gotoRefInt")) {
      const attributesInt = element.getAttribute("onclick").split("'");
      return this.goToRefInt(attributesInt, element);
    }
  }

  private _showPreview(attributes, element) {
    element.setAttribute("onclick", "");
    element.setAttribute("href", attributes[1]);
    this._renderer.listen(element, "click", _event => {
      if (attributes[3] === "CM") {
        return this.handleCMPreview(attributes);
      }
      if (attributes[3] === "IPC") {
        return this.handleIPCPreview(attributes);
      }
    });
  }

  private handleCMPreview(attributes): Promise<void> {
    runInAction(() => {
      this.store.displayDone = false;
    });

    const targets = attributes[1].split("||");
    const promises: Promise<CmPreviewDataSource>[] = targets.map((target: string) => {
      const params = target.split("##");
      const [dmc, anchor] = params;
      return this.cmPreviewService
        .retrieveData(dmc, anchor)
        .then((metadata: CmData) => ({ metadata, dmc, anchor }));
    });

    return Promise.all(promises).then((datas: CmPreviewDataSource[]) => {
      this.dialog.open(CmPreviewComponent, {
        data: datas,
        width: "80vw",
        maxWidth: "600px"
      });
      runInAction(() => {
        this.store.displayDone = true;
      });
    });
  }

  private handleIPCPreview(attributes): Promise<void> {
    runInAction(() => {
      this.store.displayDone = false;
    });
    return this.ipcPreviewService.retrieveData(attributes).then(val => {
      this.dialog.open(IpcPreviewComponent, {
        data: val,
        width: "80%",
        maxHeight: "80%"
      });
      runInAction(() => {
        this.store.displayDone = true;
      });
    });
  }

  /**
   * Create an event click on the element in order to redirect to an external url
   * External url pattern is set in the pubInfo
   *
   * @private
   * @param element
   * @param manual
   * @param ud
   * @param entrepriseCode
   * @memberof BasicViewService
   */
  private goToExternal(element: Element, manual: string, ud: string, entrepriseCode: string): void {
    // Remove the onclick attribute
    element.setAttribute("onclick", "");

    const externalDocMetas = this.store.pubInfo.externalDoc;

    if (externalDocMetas && externalDocMetas[entrepriseCode]) {
      const meta = externalDocMetas[entrepriseCode];
      // In order to be generical we replace the needed attributes
      // ex: https://tools.safran-helicopter-engines.com/api/webietp/@refManual/@variant?ud=@ud&manual=@manual&lang=@lang
      // eslint-disable-next-line max-len
      // will be https://tools.safran-helicopter-engines.com/api/webietp/C_356_1A_460_9/arrano1_a?ud=DMC-SHE060-XX-79-34-00-01A01-340A-A&manual=MM&lang=en
      const url = meta.baseUrl
        .replace("@refManual", meta.refManual)
        .replace("@variant", meta.variant)
        .replace("@ud", ud)
        .replace("@manual", manual)
        .replace("@lang", meta.lang);

      this._renderer.listen(element, "click", () => {
        window.open(url, "_blank");
      });
    } else {
      // SPEC: When manual and entrepriseCode is not specified it may a direct external link url (ud)
      // We check if ud contain http or https to be sure
      if (!manual && !entrepriseCode && ud?.includes("http")) {
        this._renderer.listen(element, "click", () => {
          window.open(ud, "_blank");
        });
      } else {
        // Disable the link to be not clickable if not externalDoc found in pubInfo
        element.classList.remove(
          "span_links",
          "AHX4_links_Enriched_class",
          "retroLinksEnrichedClass"
        );
      }
    }
  }

  private handleSimpleLink(attributes) {
    const mediaCase = attributes[1].split("##");
    const dmc = mediaCase[0];
    let scrollTarget = null;
    if (mediaCase[1]) {
      scrollTarget = mediaCase[1];
    }
    // this is for links to IPC S1000D
    if (this.store.pubInfo.partS1000D && mediaCase[2]) {
      scrollTarget = mediaCase[2];
    }
    // change route building,
    // the basicViewService Instance is created a application start with the route url
    // So route.parent doesn't exist
    // Therefore we need to set the route with firstChild element
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {
      relativeTo: this.route.firstChild.firstChild
    };
    if (scrollTarget && scrollTarget !== "ShowIllustration") {
      params.queryParams = { scroll: scrollTarget };
    }
    // Case if navigate in current page. Reset the currentDMC for activated reaction in content-provider.
    if (this.store.currentDMC === dmc) {
      runInAction(() => {
        this.store.currentDMC = undefined;
      });
      runInAction(() => {
        this.store.currentDMC = dmc;
      });
    }
    this.router.navigate(["du", dmc], params);
  }

  private handleMultiLink(targets): Promise<void> {
    runInAction(() => {
      this.store.displayDone = false;
    });
    return this.multilinkService.retrieveData(targets).then((val: MultiLinkMeta) => {
      if (val.applicableDUs.length === 1 && !this.store.showNotApplicable) {
        const dmc = val.applicableDUs[0];
        const scroll = val.links?.find(link => link.metadata.dmc === dmc)?.anchor;
        this.router.navigate(
          ["pub", this.store.publicationID, this.store.publicationRevision, "du", dmc],
          {
            queryParams: {
              scroll
            }
          }
        );
      } else {
        this.dialog.open(MultilinksComponent, {
          data: { links: val.links }
        });
        runInAction(() => {
          this.store.displayDone = true;
        });
      }
    });
  }

  private goToRefInt(attributes, element: Element): void {
    element.setAttribute("onclick", "");

    this._renderer.listen(element, "click", _event => {
      const scrollTarget = document.getElementById(attributes[1]);

      // This entire section of code get headers size, compute a y coordinate and then scroll by this y coordinate
      const headersHeight = this.getHeadersHeight();

      // scroll to target element while taking into account the multiple headers height
      const scrollY = scrollTarget.getBoundingClientRect().top - headersHeight;
      document.defaultView.scrollBy(0, scrollY);

      const previousTarget = document.getElementById(
        this.route.snapshot.queryParamMap.get("scroll")
      );
      if (previousTarget) {
        previousTarget.classList.remove("highlight");
      }

      scrollTarget.classList.add("highlight");
      this.router.navigate([], {
        queryParams: { scroll: attributes[1] },
        relativeTo: this.route
      });
    });
  }

  /**
   * Compute the cumulated height of every headers that might be on the page
   */
  private getHeadersHeight(): number {
    const headerSelectors = [
      "#header",
      ".header_wrapper",
      "o-preprint .header-preprint",
      ".header__not-applicable"
    ];

    return headerSelectors.reduce((currentHeight: number, selector: string) => {
      const header = document.querySelector(selector);
      const headerHeight = header ? header.getBoundingClientRect().height : 0;
      return currentHeight + headerHeight;
    }, 0);
  }

  private addProcThumbEvent(clonedContent) {
    const thumbs = clonedContent.querySelectorAll(
      ".AHX4_card_procedural_step > .content-procedural-step > img"
    );

    for (const thumb of thumbs) {
      const stepId = thumb.parentNode.parentNode.getAttribute("id");
      this._renderer.listen(thumb, "click", _event => {
        this.navigateToStepByStep(stepId);
      });
    }
  }
}
