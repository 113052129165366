export const legendXSL = new DOMParser().parseFromString(
  `<?xml version="1.0" encoding="UTF-8"?><xsl:stylesheet xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsl="http://www.w3.org/1999/XSL/Transform" version="2.0">
	<xsl:output doctype-public="doctype html" encoding="iso-8859-1" indent="yes" method="html"/>
	<xsl:key match="dmodule/ENTITE_GRAPHIQUE" name="getEntiteGraphique" use="NOM_LOGIQUE"/>
	<xsl:param name="LEGEND_ROW_ID">legendRowId</xsl:param>
	<xsl:param name="LEGEND_TITLE_SPAN_ID">legendTitleSpanId</xsl:param>
	<xsl:param name="AHX4_REF_CLASS">AHX4_ref_class</xsl:param>
	<xsl:param name="BTN_TOOL">AHX4_ref_tools</xsl:param>

	<xsl:template match="dmodule">
		<xsl:if test="not(dmodule/content/IPC)">
			<xsl:apply-templates select="//LEGEND | //legend"/>
		</xsl:if>
	</xsl:template>

	<xsl:template match="LEGEND | legend">
		<xsl:variable name="graphicKey">
			<xsl:value-of select="ancestor::GRAPHIC/@KEY | ancestor::figure/@id"/>
		</xsl:variable>
		<xsl:element name="div">
			<xsl:attribute name="id">
   				<xsl:value-of select="$graphicKey"/>
   			</xsl:attribute>
			<xsl:attribute name="class">
   				<xsl:value-of select="$LEGEND_DIV_CLASS"/>
   			</xsl:attribute>
			<xsl:element name="span">
				<xsl:attribute name="id">
   		    		<xsl:value-of select="$LEGEND_TITLE_SPAN_ID"/>
   		    	</xsl:attribute>
				<xsl:value-of select="$LEGEND_TITLE_LABEL"/>
			</xsl:element>
			<xsl:apply-templates/>
		</xsl:element>
	</xsl:template>

	<!-- Translate element DEFLIST to table -->
	<xsl:template match="DEFLIST | definitionList" name="translateDEFLIST">
		<xsl:element name="table">
			<xsl:call-template name="applyCssClass">
				<xsl:with-param name="expectedClass" select="$DEFLIST_TABLE_CLASS"/>
				<xsl:with-param name="changeMark" select="@CHANGEMARK | @changemark"/>
			</xsl:call-template>
			<xsl:apply-templates/>
		</xsl:element>
	</xsl:template>


	<!-- Translate element DEFITEM to table row -->
	<xsl:template match="DEFITEM | definitionListItem" name="translateDEFITEM">
		<xsl:variable name="positionX">
			<xsl:value-of select="count(preceding-sibling::DEFITEM | preceding-sibling::definitionListItem) + 1"/>
		</xsl:variable>
		<xsl:variable name="graphicKey">
			<xsl:value-of select="ancestor::GRAPHIC/@KEY | ancestor::figure/@id"/>
		</xsl:variable>
		<xsl:variable name="hotspot-legend-id">
			<xsl:value-of select="TERM/@ID"/>
		</xsl:variable>
		<xsl:variable name="apsName">
			<xsl:value-of select="descendant::FIGITEM/@ITEMNBR | listItemTerm/text()"/>
		</xsl:variable>
		<xsl:element name="tr">
			<xsl:attribute name="id">
				<xsl:value-of select="concat($LEGEND_ROW_ID, '_', $graphicKey, '_', $positionX)"/>
			</xsl:attribute>
			<xsl:attribute name="refApsname">
				<xsl:value-of select="concat($LEGEND_ROW_ID, '_', $graphicKey, '_')"/>
				<xsl:choose>
					<xsl:when test="normalize-space($apsName) != ''">
						<xsl:value-of select="$apsName"/>
					</xsl:when>
					<xsl:otherwise>
						<xsl:value-of select="$positionX"/>
					</xsl:otherwise>
				</xsl:choose>
			</xsl:attribute>
			<xsl:attribute name="hotspot-legend-id">
				<xsl:value-of select="$hotspot-legend-id"/>
			</xsl:attribute>
			<xsl:call-template name="applyCssClass">
				<xsl:with-param name="expectedClass" select="concat($LEGEND_ROW_ID,' legend-row')"/>
				<xsl:with-param name="changeMark" select="@CHANGEMARK | @changemark"/>
			</xsl:call-template>
			<xsl:if test="descendant::GRPHCREF/@REFID ">
				<xsl:variable name="hotspotTarget" select="//HOTSPOT[GRPHCREF/@REFID=current()/TERM/@ID]"/>
				<xsl:variable name="hotspotSheetParent" select="$hotspotTarget/ancestor::SHEET"/>
				<xsl:variable name="entite" select="//ENTITE_GRAPHIQUE[NOM_LOGIQUE=$hotspotSheetParent/@GNBR]"/>
				<xsl:variable name="hotspot" select="$hotspotTarget/@APSNAME"/>
				<xsl:variable name="wdmId" select="descendant::GRPHCREF/@REFID"/>

				<xsl:attribute name="onclick">
					<xsl:call-template name="displayFinalGraphicElement">
						<xsl:with-param name="entite" select="$entite"/>
						<xsl:with-param name="hotspot" select="$hotspot"/>
					</xsl:call-template>
					;
					<xsl:value-of select="concat('legendToWdmLinks(',$SINGLE_QUOTATION_MARK,$hotspot-legend-id,$SINGLE_QUOTATION_MARK,',',$SINGLE_QUOTATION_MARK,$wdmId,$SINGLE_QUOTATION_MARK,$CLOSING_PARENTHESIS)"/>
				</xsl:attribute>
			</xsl:if>
			<xsl:apply-templates/>
		</xsl:element>
	</xsl:template>

	<!-- Translate element TERM of DEFLIST to table data -->
	<xsl:template match="TERM" name="translateTERM">
		<xsl:element name="td">
			<xsl:choose>
				<xsl:when test="parent::node()/descendant::GRPHCREF and starts-with(@ID,'FWD')">

					<xsl:attribute name="class">
							<xsl:value-of select="$SPAN_LINKS_CLASS"/>
						</xsl:attribute>
					<xsl:value-of select="translate(PARA/text(),$TITLE_SEPARATOR,$BREAK_LINE)"/>

				</xsl:when>
				<xsl:otherwise>
					<xsl:call-template name="applyCssClass">
						<xsl:with-param name="expectedClass" select="$SPACE"/>
						<xsl:with-param name="changeMark" select="@CHANGEMARK | @changemark"/>
					</xsl:call-template>
					<xsl:apply-templates/>
				</xsl:otherwise>
			</xsl:choose>
		</xsl:element>
	</xsl:template>

	<!-- Translate element listItemTerm to span hotspot link into a td -->
	<xsl:template match="listItemTerm" name="translatelistItemTerm">
		<xsl:element name="td">
      <xsl:apply-templates/>
		</xsl:element>
	</xsl:template>

	<!-- Translate internalRef to a span text value -->
	<xsl:template match="internalRef">
		<xsl:variable name="varTargetType" select="./@internalRefTargetType"/>
		<xsl:variable name="varInternalRefId" select="./@internalRefId"/>
		<xsl:element name="span">
      <xsl:attribute name="internalRefTargetType">
        <xsl:value-of select="$varTargetType"/>
      </xsl:attribute>
      <xsl:attribute name="internalRefId">
        <xsl:value-of select="$varInternalRefId"/>
      </xsl:attribute>
      <xsl:attribute name="class">
        <xsl:value-of select="$AHX4_REF_CLASS"/>
      </xsl:attribute>
      <xsl:choose>
        <xsl:when test="$varTargetType='irtt04' or $varTargetType='irtt05' or $varTargetType='irtt06'">
          <xsl:call-template name="applyCssClass">
            <xsl:with-param name="expectedClass" select="$SPAN_LINKS_CLASS"/>
            <xsl:with-param name="changeMark" select="@CHANGEMARK | @changemark"/>
          </xsl:call-template>
          <xsl:attribute name="onclick">
						<xsl:value-of select="concat('gotoRefInt(',$SINGLE_QUOTATION_MARK,$varInternalRefId,$SINGLE_QUOTATION_MARK,$CLOSING_PARENTHESIS)"/>
					</xsl:attribute>
          <xsl:variable name="name" select="//*[@id = $varInternalRefId]/name"/>
          <xsl:variable name="manufacturerCode" select="//*[@id = $varInternalRefId]/identNumber/manufacturerCode"/>
          <xsl:variable name="labelFromAttribute"
            select="
            //*[@id = $varInternalRefId]/supplyRef/@supplyNumber |
            //*[@id = $varInternalRefId]/toolRef/@toolNumber |
            //*[@id = $varInternalRefId]/partRef/@partNumberValue"
            />
          <xsl:variable name="labelFromPartNumber" select="//*[@id = $varInternalRefId]/identNumber/partAndSerialNumber/partNumber"/>
          <xsl:variable name="labelFromLink"
            select="
            //*[@id = $varInternalRefId]/supplyRef/@xlink:title |
            //*[@id = $varInternalRefId]/toolRef/@xlink:title |
            //*[@id = $varInternalRefId]/partRef/@xlink:title"
            />
          <xsl:variable name="labelPriorized">
            <xsl:choose>
              <xsl:when test="$labelFromAttribute != ''">
                <xsl:value-of select="$labelFromAttribute"/>
              </xsl:when>
              <xsl:when test="$labelFromPartNumber != ''">
                <xsl:value-of select="$labelFromPartNumber"/>
              </xsl:when>
              <xsl:otherwise>
                 <xsl:value-of select="$labelFromLink"/>
              </xsl:otherwise>
            </xsl:choose>
          </xsl:variable>

          <xsl:choose>
            <xsl:when test="$manufacturerCode = 'FCOTS' or $manufacturerCode = 'LOCAL'">
              <xsl:value-of select="$name"/>
            </xsl:when>
            <xsl:otherwise>
              <xsl:value-of select="concat($name, $SPACE, '(',$labelPriorized,')')"/>
            </xsl:otherwise>
          </xsl:choose>

				</xsl:when>
        <xsl:when test="$varTargetType='irtt11'">
          <xsl:call-template name="applyCssClass">
          <xsl:with-param name="expectedClass" select="$SPACE"/>
          <xsl:with-param name="changeMark" select="@CHANGEMARK | @changemark"/>
        </xsl:call-template>
        <xsl:variable name="icn" select="ancestor::figure//hotspot[@id=$varInternalRefId]/../@infoEntityIdent"/>
        <xsl:variable name="entiteGraphique" select="key('getEntiteGraphique',$icn)"/>
        <xsl:variable name="hotspotID" select="substring-after(translate(ancestor::figure//hotspot[@id=$varInternalRefId]/@applicationStructureIdent, $LOWER_CASE_LETTER, $UPPER_CASE_LETTER),'HOT')"/>
          <xsl:if test="$entiteGraphique and $hotspotID">
            <xsl:call-template name="applyCssClass">
              <xsl:with-param name="expectedClass" select="$FIGITEM_CLASS" />
              <xsl:with-param name="changeMark" select="@CHANGEMARK | @changemark" />
            </xsl:call-template>
            <xsl:attribute name="onClick">
              <xsl:value-of select="concat('displayGraphicElementAndHighlightingHotspot(',$SINGLE_QUOTATION_MARK,$entiteGraphique/NOM_PHYSIQUE,$SINGLE_QUOTATION_MARK,$COMMA,$SINGLE_QUOTATION_MARK,$hotspotID,$SINGLE_QUOTATION_MARK,$COMMA,$SINGLE_QUOTATION_MARK,$entiteGraphique/NOM_PHYSIQUE,$SINGLE_QUOTATION_MARK,$COMMA,$SINGLE_QUOTATION_MARK,$entiteGraphique/FORMAT,$SINGLE_QUOTATION_MARK,$CLOSING_PARENTHESIS)"/>
            </xsl:attribute>
          </xsl:if>
          <xsl:value-of select="concat('(',.,')')"/>
        </xsl:when>
      </xsl:choose>
		</xsl:element>
	</xsl:template>

	<!-- Tanslate symbol to a div -->
	<xsl:template match="symbol">
		<xsl:variable name="infoEntityIdentVar" select="@infoEntityIdent"/>
		<xsl:variable name="entiteSymbolVar" select="//ENTITE_SYMBOLE[NOM_LOGIQUE=$infoEntityIdentVar]"/>
		<xsl:element name="div">
			<xsl:attribute name="class">symbolPosition</xsl:attribute>
			<xsl:attribute name="alt">
				<xsl:value-of select="@infoEntityIdent"/>
			</xsl:attribute>
		</xsl:element>
	</xsl:template>

	<!-- Translate element DEF of DEFLIST to table data -->
	<xsl:template match="DEF | listItemDefinition" name="translateDEF">
		<xsl:element name="td">
			<xsl:call-template name="applyCssClass">
				<xsl:with-param name="expectedClass" select="$SPACE"/>
				<xsl:with-param name="changeMark" select="@CHANGEMARK | @changemark"/>
			</xsl:call-template>
			<xsl:apply-templates/>
		</xsl:element>
	</xsl:template>

	<!-- Translate element SPECIALPART of DEFLIST to table data -->
	<xsl:template match="SPECIALPART" name="translateSPECIALPART">
		<xsl:element name="span">
			<xsl:attribute name="class">
					<xsl:value-of select="concat('SPECIALPART_', @TYPE,'_CLASS')" />
			</xsl:attribute>
			<xsl:value-of select="concat(., $SPACE)"/>
			<xsl:element name="sup">
				<xsl:choose>
					<xsl:when test="@TYPE='CRITICAL'">
						<xsl:value-of select="$EXPOSANT_CRITICAL" />
					</xsl:when>
					<xsl:otherwise>
						<xsl:value-of select="$EXPOSANT_IMPORTANT" />
					</xsl:otherwise>
				</xsl:choose>
			</xsl:element>
		</xsl:element>
	</xsl:template>

	<xsl:template match="FAULT">
		<xsl:element name="span">
			<xsl:attribute name="class">
				<xsl:value-of select="'fault'" />
			</xsl:attribute>
			<xsl:value-of select="./@FCODE" />
			<xsl:apply-templates/>
		</xsl:element>
	</xsl:template>

	<!-- Translate element EFFECT/MDEFF of DEFLIST to table data -->
	<xsl:template match="EFFECT" name="translateEFFECT">
		<xsl:apply-templates select="MDEFF | SBEFF"/>
	</xsl:template>

	<xsl:template match="MDEFF | SBEFF" name="translateMDEFF">
		<xsl:choose>
			<xsl:when test="not((name(../..)='PARA' or name(../..)='TITLE') and ((normalize-space(../preceding-sibling::text()[position() = 1]) != '' and position()= 1)     or (normalize-space(../following-sibling::text()[position() = 1]) != '' and position()=last())))">
				<xsl:choose>
					<xsl:when test="name() = 'MDEFF'">
						<xsl:call-template name="mdeffContent">
							<xsl:with-param name="tag" select="'display:inline-block;'"/>
						</xsl:call-template>
					</xsl:when>
					<xsl:otherwise>
						<xsl:call-template name="sbeffContent">
							<xsl:with-param name="tag" select="'display:inline-block;'"/>
						</xsl:call-template>
					</xsl:otherwise>
				</xsl:choose>
			</xsl:when>
			<xsl:otherwise>
				<xsl:choose>
					<xsl:when test="name() = 'MDEFF'">
						<xsl:call-template name="mdeffContent">
							<xsl:with-param name="tag" select="''"/>
						</xsl:call-template>
					</xsl:when>
					<xsl:otherwise>
						<xsl:call-template name="sbeffContent">
							<xsl:with-param name="tag" select="''"/>
						</xsl:call-template>
					</xsl:otherwise>
				</xsl:choose>
			</xsl:otherwise>
		</xsl:choose>
	</xsl:template>

	<xsl:template name="mdeffContent">
		<xsl:param name="tag"/>
		<xsl:variable name="cssClass">
			<xsl:choose>
				<xsl:when test="@xlink:href">
					<xsl:value-of select="concat($SPAN_LINKS_CLASS, $SPACE, 'no_margin')"/>
				</xsl:when>
				<xsl:otherwise>
					<xsl:value-of select="'no_margin'"/>
				</xsl:otherwise>
			</xsl:choose>
		</xsl:variable>
		<xsl:element name="span"> <!-- {$tag} -->
			<xsl:attribute name="title"><xsl:value-of select="translate(./@xlink:title,$TITLE_SEPARATOR,$BREAK_LINE)"/> </xsl:attribute>
			<xsl:attribute name="style"><xsl:value-of select="$tag"/></xsl:attribute>
			<xsl:call-template name="applyCssClass">
				<xsl:with-param name="expectedClass" select="$cssClass"/>
				<xsl:with-param name="changeMark" select="@CHANGEMARK | @changemark"/>
			</xsl:call-template>
			<xsl:if test="./@xlink:href">
				<xsl:attribute name="onclick">
					<xsl:call-template name="generateRefExtWithTitle">
						<xsl:with-param name="href" select="./@xlink:href"/>
						<xsl:with-param name="title" select="./@xlink:title"/>
					</xsl:call-template>
				</xsl:attribute>
			</xsl:if>
			<xsl:variable name="prefixMDEFF">
				<xsl:choose>
					<xsl:when test="@MDCOND='PRE'">
						<xsl:value-of select="$PRE_MDEFF_PREFIX_TEXT"/>
					</xsl:when>
					<xsl:when test="@MDCOND='POST'">
						<xsl:value-of select="$POST_MDEFF_PREFIX_TEXT"/>
					</xsl:when>
					<xsl:otherwise>
						<xsl:value-of select="@MDCOND"/>
					</xsl:otherwise>
				</xsl:choose>
			</xsl:variable>
			<xsl:value-of select="concat($prefixMDEFF, $SPACE,$MOD,$SPACE,@MDNBR)"/>
		</xsl:element>
		<xsl:value-of select="$SPACE"/>
	</xsl:template>

	<xsl:template name="sbeffContent">
		<xsl:param name="tag"/>
		<xsl:variable name="cssClass">
			<xsl:choose>
				<xsl:when test="@xlink:href">
					<xsl:value-of select="concat($SPAN_LINKS_CLASS, $SPACE, 'no_margin')"/>
				</xsl:when>
				<xsl:otherwise>
					<xsl:value-of select="'no_margin'"/>
				</xsl:otherwise>
			</xsl:choose>
		</xsl:variable>
		<xsl:element name="span">
			<xsl:attribute name="title"><xsl:value-of select="translate(./@xlink:title,$TITLE_SEPARATOR,$BREAK_LINE)"/> </xsl:attribute>
			<xsl:attribute name="style"><xsl:value-of select="$tag"/></xsl:attribute>
			<xsl:call-template name="applyCssClass">
				<xsl:with-param name="expectedClass" select="$cssClass"/>
				<xsl:with-param name="changeMark" select="@CHANGEMARK | @changemark"/>
			</xsl:call-template>
			<xsl:if test="./@xlink:href">
				<xsl:attribute name="onclick">
					<xsl:call-template name="generateRefExtWithTitle">
						<xsl:with-param name="href" select="./@xlink:href"/>
						<xsl:with-param name="title" select="./@xlink:title"/>
					</xsl:call-template>
				</xsl:attribute>
			</xsl:if>
			<xsl:value-of select="concat(@SBCOND,$SPACE,'SB',$SPACE,@SBNBR, $SPACE)"/>
		</xsl:element>
	</xsl:template>

	<!-- Translate element CON of DEFLIST to table data -->
	<xsl:template match="CON" name="translateCON">
		<xsl:call-template name="translateLegendLink">
			<xsl:with-param name="nodeName" select="./CONNAME" />
			<xsl:with-param name="nodeNbr" select="./CONNBR" />
		</xsl:call-template>
	</xsl:template>

	<!-- Translate element TED of DEFLIST to table data -->
	<xsl:template match="TED" name="translateTED">
		<xsl:call-template name="translateLegendLink">
			<xsl:with-param name="nodeName" select="./TOOLNAME" />
			<xsl:with-param name="nodeNbr" select="./TOOLNBR" />
		</xsl:call-template>
	</xsl:template>

	<!-- Translate element STD of DEFLIST to table data -->
	<xsl:template match="STD" name="translateSTD">
		<xsl:call-template name="translateLegendLink">
			<xsl:with-param name="nodeName" select="./STDNAME" />
			<xsl:with-param name="nodeNbr" select="./STDNBR" />
		</xsl:call-template>
	</xsl:template>

		<xsl:param name="typeMan">
		<xsl:call-template name="getTypeManual">
			<xsl:with-param name="itemLocationCode" select="child::*/identAndStatusSection/dmAddress/dmIdent/dmCode/@itemLocationCode"/>
		</xsl:call-template>
	</xsl:param>

	<xsl:template name="getTypeManual">
		<xsl:param name="itemLocationCode"/>
		<xsl:choose>
			<xsl:when test="$itemLocationCode='M'">
				<xsl:value-of select="'AMM'"/>
			</xsl:when>
			<xsl:when test="$itemLocationCode='Z'">
				<xsl:value-of select="'MMA'"/>
			</xsl:when>
			<xsl:when test="$itemLocationCode='D'">
				<xsl:value-of select="'SDS'"/>
			</xsl:when>
			<xsl:when test="$itemLocationCode='P'">
				<xsl:value-of select="'IPC'"/>
			</xsl:when>
			<xsl:when test="$itemLocationCode='C'">
				<xsl:value-of select="'MTC'"/>
			</xsl:when>
			<xsl:when test="$itemLocationCode='F'">
				<xsl:value-of select="'FIM'"/>
			</xsl:when>
		</xsl:choose>
	</xsl:template>

	<xsl:template match="REFEXT | legend//dmRef | legend//externalPubCode" name="translateRefExt">
		<xsl:call-template name="applyRefExt">
			<xsl:with-param name="elem" select="'span'"/>
		</xsl:call-template>
	</xsl:template>

	<xsl:template name="applyRefExt">
	 	<xsl:param name="elem"/>

		<xsl:variable name="cssClass">
			<xsl:choose>
				<xsl:when test="@xlink:href and ancestor::THEAD/ROW/ENTRY">
					<xsl:value-of select="$SPAN_LINKS_ENTRY_CLASS"/>
				</xsl:when>
				<xsl:when test="@xlink:href and ancestor::PRETOPIC and not(ancestor::CAUTION or ancestor::WARNING or ancestor::NOTE)">
					<xsl:value-of select="$SPAN_LINKS_PRETOPIC_CLASS"/>
				</xsl:when>
				<xsl:when test="not(@xlink:href) and ancestor::PRETOPIC and not(ancestor::CAUTION or ancestor::WARNING or ancestor::NOTE)">
					<xsl:value-of select="$SPAN_LINKS_PRETOPIC_NOT_ENRICHED_CLASS"/>
				</xsl:when>
				<xsl:when test="@xlink:href">
					<xsl:value-of select="$SPAN_LINKS_CLASS"/>
				</xsl:when>
				<xsl:otherwise>
					<xsl:value-of select="$SPACE"/>
				</xsl:otherwise>
			</xsl:choose>
		</xsl:variable>
		 <xsl:element name="{$elem}">
			<xsl:attribute name="title"><xsl:value-of select="translate(./@xlink:title,$TITLE_SEPARATOR,$BREAK_LINE)"/> </xsl:attribute>
			<xsl:call-template name="applyCssClass">
				<xsl:with-param name="expectedClass" select="$cssClass"/>
				<xsl:with-param name="changeMark" select="@CHANGEMARK | @changemark"/>
			</xsl:call-template>
			<xsl:if test="./@xlink:href">
				<xsl:attribute name="onclick">
					<xsl:call-template name="generateRefExtWithTitle">
							<xsl:with-param name="href" select="./@xlink:href"/>
							<xsl:with-param name="title" select="./@xlink:title"/>
					</xsl:call-template>
				</xsl:attribute>
			</xsl:if>
			<xsl:call-template name="getRef">
				<xsl:with-param name="isInt" select="false()"/>
				<xsl:with-param name="ref" select="."/>
			</xsl:call-template>
      <xsl:if test="name(.)='dmRef' and ./@shortDMC">
        <xsl:value-of select="./@shortDMC"/>
      </xsl:if>
      <xsl:if test="name(.)='externalPubCode'">
        <xsl:value-of select="."/>
      </xsl:if>
		</xsl:element>
		<xsl:if test="ancestor::PRETOPIC and string-length(string(.)) &gt; 0">
			<xsl:element name="{$elem}">
				<xsl:attribute name="class">
                    <xsl:value-of select="$REF_DESC_CLASS"/>
                  </xsl:attribute>
				<xsl:apply-templates/>
			</xsl:element>
		</xsl:if>
	</xsl:template>



	<xsl:template match="REFINT" name="translateRefInt">
		<xsl:call-template name="applyRefInt">
			<xsl:with-param name="elem" select="'span'"/>
		</xsl:call-template>
	</xsl:template>

	<xsl:template name="applyRefInt">
		<xsl:param name="elem"/>

		<xsl:variable name="cssClass">
			<xsl:choose>
				<xsl:when test="@xlink:href and ancestor::PRETOPIC">
					<xsl:value-of select="$SPAN_LINKS_PRETOPIC_CLASS"/>
				</xsl:when>
				<xsl:when test="@xlink:href and ancestor::THEAD/ROW/ENTRY">
					<xsl:value-of select="$SPAN_LINKS_ENTRY_CLASS"/>
				</xsl:when>
				<xsl:when test="not(@xlink:href) and ancestor::PRETOPIC">
					<xsl:value-of select="$SPAN_LINKS_PRETOPIC_NOT_ENRICHED_CLASS"/>
				</xsl:when>
				<xsl:when test="@xlink:href and not(ancestor::PRETOPIC)">
					<xsl:value-of select="$SPAN_LINKS_CLASS"/>
				</xsl:when>
				<xsl:otherwise>
					<xsl:value-of select="$SPACE"/>
				</xsl:otherwise>
			</xsl:choose>
		</xsl:variable>

		<xsl:element name="{$elem}">
			<xsl:attribute name="title"><xsl:value-of select="translate(./@xlink:title,$TITLE_SEPARATOR,$BREAK_LINE)"/> </xsl:attribute>
			<xsl:call-template name="applyCssClass">
				<xsl:with-param name="expectedClass" select="$cssClass"/>
				<xsl:with-param name="changeMark" select="@CHANGEMARK | @changemark"/>
			</xsl:call-template>
			<xsl:choose>
				<xsl:when test="translate(@REFTYPE,$LOWER_CASE_LETTER,$UPPER_CASE_LETTER)='TABLE'">
					<xsl:attribute name="onclick">
						<xsl:value-of select="concat('gotoRefInt(',$SINGLE_QUOTATION_MARK,@REFID,$SINGLE_QUOTATION_MARK,$CLOSING_PARENTHESIS)"/>
					</xsl:attribute>
					<xsl:attribute name="class">
						<xsl:value-of select="$SPAN_LINKS_CLASS"/>
					</xsl:attribute>
				</xsl:when>
				<xsl:otherwise>
					<xsl:if test="./@xlink:href">
						<xsl:attribute name="onclick">
							<xsl:call-template name="generateRefExtWithTitle">
								<xsl:with-param name="href" select="./@xlink:href"/>
								<xsl:with-param name="title" select="./@xlink:title"/>
							</xsl:call-template>
						</xsl:attribute>
					</xsl:if>
				</xsl:otherwise>
			</xsl:choose>
			<xsl:choose>
				<xsl:when test="translate(@REFTYPE,$LOWER_CASE_LETTER,$UPPER_CASE_LETTER)='TABLE'">
					<xsl:variable name="refid">
						<xsl:value-of select="@REFID"/>
					</xsl:variable>
					<xsl:choose>
						<xsl:when test="./@xlink:href">
							<xsl:attribute name="title"><xsl:value-of select="translate(./@xlink:title,$TITLE_SEPARATOR,$BREAK_LINE)"/> </xsl:attribute>
						</xsl:when>
						<xsl:when test="ancestor::dmodule/descendant::*/TABLE[@ID=$refid]/TITLE != ''">
							<xsl:attribute name="title">
								<xsl:value-of select="ancestor::dmodule/descendant::*/TABLE[@ID=$refid]/TITLE"/>
							</xsl:attribute>
						</xsl:when>
					</xsl:choose>
					<xsl:choose>
						<xsl:when test="$refid != ''">
							<xsl:variable name="tableNumber">
								<xsl:call-template name="extractTableNumber">
									<xsl:with-param name="id" select="$refid"/>
								</xsl:call-template>
							</xsl:variable>
							<xsl:value-of select="concat($TABLE_TEXT, $SPACE, $tableNumber)"/>
						</xsl:when>
						<xsl:otherwise>
							<xsl:value-of select="$REFINT_DEFAULT_TABLE_TITLE"/>
						</xsl:otherwise>
					</xsl:choose>
				</xsl:when>
				<xsl:otherwise>
					<xsl:call-template name="getRef">
						<xsl:with-param name="isInt" select="true()"/>
						<xsl:with-param name="ref" select="."/>
					</xsl:call-template>
				</xsl:otherwise>
			</xsl:choose>
		</xsl:element>
		<xsl:if test="ancestor::PRETOPIC and string-length(string(.)) &gt; 0">
			<xsl:element name="{$elem}">
				<xsl:attribute name="class">
					<xsl:value-of select="$REF_DESC_CLASS"/>
				</xsl:attribute>
				<xsl:apply-templates/>
			</xsl:element>
		</xsl:if>
	</xsl:template>

	<xsl:template name="extractTableNumber">
		<xsl:param name="id"/>
		<xsl:if test="string-length($id)= 17">
			<xsl:value-of select="substring($id,13,2)"/>
		</xsl:if>
		<xsl:if test="string-length($id) = 18">
			<xsl:value-of select="substring($id,14,2)"/>
		</xsl:if>
		<xsl:if test="string-length($id) = 19">
			<xsl:value-of select="substring($id,15,2)"/>
		</xsl:if>
	</xsl:template>



	<xsl:template name="getRef">
		<xsl:param name="isInt"/>
		<xsl:param name="ref"/>
		<xsl:variable name="refId">
			<xsl:choose>
				<xsl:when test="translate($ref/@REFTYPE,$LOWER_CASE_LETTER,$UPPER_CASE_LETTER)='TABLE' or translate($ref/@REFTYPE,$LOWER_CASE_LETTER,$UPPER_CASE_LETTER)='SUBJECT'">
					<xsl:value-of select="substring($ref/@REFID,3,string-length($ref/@REFID))"/>
				</xsl:when>
				<xsl:otherwise>
					<xsl:value-of select="substring($ref/@REFID,2,string-length($ref/@REFID))"/>
				</xsl:otherwise>
			</xsl:choose>
		</xsl:variable>
		<xsl:variable name="refPrefix">
			<xsl:choose>
				<xsl:when test="translate($ref/@REFTYPE,$LOWER_CASE_LETTER,$UPPER_CASE_LETTER)='TABLE' or translate($ref/@REFTYPE,$LOWER_CASE_LETTER,$UPPER_CASE_LETTER)='SUBJECT'">
					<xsl:value-of select="substring($ref/@REFID,1,2)"/>
				</xsl:when>
				<xsl:otherwise>
					<xsl:value-of select="substring($ref/@REFID,1,1)"/>
				</xsl:otherwise>
			</xsl:choose>
		</xsl:variable>
		<xsl:choose>
			<xsl:when test="boolean($isInt)">
				<xsl:choose>
					<xsl:when test="$typeMan='MTC'and translate($ref/@REFTYPE,$LOWER_CASE_LETTER,$UPPER_CASE_LETTER)='PAGESET'">
						<xsl:value-of select="concat($typeMan,$SPACE,substring($refId,1,2),'-',substring($ref/@REFID,4,2),'-',substring($ref/@REFID,6,2),'-',number(substring($ref/@REFID,8,4)))"/>
					</xsl:when>
					<xsl:when test="translate($ref/@REFTYPE,$LOWER_CASE_LETTER,$UPPER_CASE_LETTER)='PAGESET'">
						<xsl:choose>
							<xsl:when test="string-length($refId) = 10">
								<xsl:value-of select="concat($typeMan,$SPACE,substring($refId,1,2),'-',substring($refId,3,2),'-',substring($refId,5,2),',',substring($refId,9,2))"/>
							</xsl:when>
							<xsl:otherwise>
								<xsl:value-of select="concat($typeMan,$SPACE,substring($refId,1,2),'-',substring($refId,3,2),'-',substring($refId,5,2),',',substring($refId,7,2))"/>
							</xsl:otherwise>
						</xsl:choose>
					</xsl:when>
					<xsl:when test="translate($ref/@REFTYPE,$LOWER_CASE_LETTER,$UPPER_CASE_LETTER)='SUBJECT'">
						<xsl:variable name="TOPICPrefixSUBJECT">
							<xsl:if test="ancestor::TOPIC">
								<xsl:value-of select="'subject '"/>
							</xsl:if>
						</xsl:variable>
						<xsl:choose>
							<xsl:when test="$refPrefix = 'SJ' and string-length($ref/@REFID) = 2 + 6">
								<xsl:value-of select="concat($TOPICPrefixSUBJECT,substring($refId,1,2),'-',substring($ref/@REFID,5,2),'-',substring($ref/@REFID,7,2))"/>
							</xsl:when>
							<xsl:otherwise>
								<xsl:value-of select="concat($TOPICPrefixSUBJECT,$ref/@REFID)"/>
							</xsl:otherwise>
						</xsl:choose>
					</xsl:when>
					<xsl:when test="translate($ref/@REFTYPE,$LOWER_CASE_LETTER,$UPPER_CASE_LETTER)='SECTION'">
						<xsl:variable name="TOPICPrefixSECTION">
							<xsl:if test="ancestor::TOPIC">
								<xsl:value-of select="'section '"/>
							</xsl:if>
						</xsl:variable>
						<xsl:choose>
							<xsl:when test="$refPrefix = 'S' and string-length($ref/@REFID) = 1 + 4">
								<xsl:value-of select="concat($TOPICPrefixSECTION,substring($refId,1,2),'-',substring($ref/@REFID,4,2))"/>
							</xsl:when>
							<xsl:when test="translate($ref/@REFID,$LOWER_CASE_LETTER,$UPPER_CASE_LETTER)='INTRO'">
								<xsl:value-of select="$REFINT_INTRO_TEXT"/>
							</xsl:when>
							<xsl:otherwise>
								<xsl:value-of select="concat($TOPICPrefixSECTION,$ref/@REFID)"/>
							</xsl:otherwise>
						</xsl:choose>
					</xsl:when>
					<xsl:when test="translate($ref/@REFTYPE,$LOWER_CASE_LETTER,$UPPER_CASE_LETTER)='CHAPTER'">
						<xsl:variable name="TOPICPrefixCHAPTER">
							<xsl:if test="ancestor::TOPIC">
								<xsl:value-of select="'chapter '"/>
							</xsl:if>
						</xsl:variable>
						<xsl:choose>
							<xsl:when test="$refPrefix = 'C' and string-length($ref/@REFID) = 1 + 2">
								<xsl:value-of select="concat($TOPICPrefixCHAPTER,$refId)"/>
							</xsl:when>
							<xsl:otherwise>
								<xsl:value-of select="concat($TOPICPrefixCHAPTER,$ref/@REFID)"/>
							</xsl:otherwise>
						</xsl:choose>
					</xsl:when>
					<xsl:otherwise>
						<xsl:value-of select="concat($typeMan,$SPACE,substring($refId,1,2),'-',substring($ref/@REFID,4,2),'-',substring($ref/@REFID,6,2),',',number(substring($ref/@REFID,8,2)),'-',number(substring($ref/@REFID,10,3)))"/>
					</xsl:otherwise>
				</xsl:choose>
			</xsl:when>
			<xsl:otherwise>
				<xsl:value-of select="concat($ref/@REFMAN,$SPACE,$ref/@REFLOC)"/>
			</xsl:otherwise>
		</xsl:choose>
	</xsl:template>

	<xsl:template name="translateLegendLink">
		<xsl:param name="nodeName" />
		<xsl:param name="nodeNbr" />
		<xsl:element name="span">
			<xsl:value-of select="concat($nodeName,$SPACE2)" />
		</xsl:element>
		<xsl:element name="span">
			<xsl:if test="$nodeNbr/@xlink:href">
				<xsl:attribute name="onclick">
					<xsl:call-template name="generateRefExtWithTitle">
						<xsl:with-param name="href" select="$nodeNbr/@xlink:href" />
						<xsl:with-param name="title" select="$nodeNbr/@xlink:title" />
					</xsl:call-template>
				</xsl:attribute>
				<xsl:attribute name="title">
					<xsl:value-of
					select="translate($nodeNbr/@xlink:title,$TITLE_SEPARATOR,$BREAK_LINE)"></xsl:value-of>
				</xsl:attribute>
				<xsl:attribute name="class">
					<xsl:value-of select="$SPAN_LINKS_CLASS" />
				</xsl:attribute>
			</xsl:if>
			<xsl:value-of select="$nodeNbr" />
		</xsl:element>
	</xsl:template>


<!-- Translate FIGITEM element , -->
	<xsl:template match="FIGITEM" name="translateFIGITEM">
		<xsl:element name="span">
			<xsl:if test="//HOTSPOT[@ID=current()/XREF/@XREFID] or //GRAPHIC[@KEY=current()/@GRPHNBR]">
				<xsl:choose>
					<xsl:when test="ancestor::THEAD/ROW/ENTRY">
						<xsl:call-template name="applyCssClass">
							<xsl:with-param name="expectedClass" select="$FIGITEM_HEAD_CLASS" />
							<xsl:with-param name="changeMark" select="@CHANGEMARK | @changemark" />
						</xsl:call-template>
					</xsl:when>
					<xsl:otherwise>
						<xsl:call-template name="applyCssClass">
							<xsl:with-param name="expectedClass" select="$FIGITEM_CLASS" />
							<xsl:with-param name="changeMark" select="@CHANGEMARK | @changemark" />
						</xsl:call-template>
					</xsl:otherwise>
				</xsl:choose>
				<xsl:variable name="sheetNbr">
					<xsl:choose>
						<xsl:when test="./@SHEETNBR">
							<xsl:value-of select="./@SHEETNBR" />
						</xsl:when>
						<xsl:otherwise>
							<xsl:value-of select="'*'" />
						</xsl:otherwise>
					</xsl:choose>
				</xsl:variable>
				<xsl:attribute name="onClick">
				  	<xsl:choose>
						<xsl:when test="child::XREF">
							<xsl:call-template name="generateFIGITEMLink">
								<xsl:with-param name="xrefId" select="child::XREF[1]/@XREFID" />
							</xsl:call-template>
						</xsl:when>
						<xsl:otherwise>
							<xsl:call-template name="displayGraphicElement">
								<xsl:with-param name="graphicKey" select="./@GRPHNBR" />
								<xsl:with-param name="sheetNbr" select="$sheetNbr" />
								<xsl:with-param name="hotspot" select="'0'" />
							</xsl:call-template>
					   </xsl:otherwise>
					</xsl:choose>
				</xsl:attribute>
			</xsl:if>
			<xsl:choose>
				<xsl:when test="//identAndStatusSection//@modelIdentCode ='BK117' or //identAndStatusSection//@modelIdentCode ='EC145' or //identAndStatusSection//@modelIdentCode ='EC135'">
					<xsl:value-of select="@ITEMNBR" />
				</xsl:when>
				<xsl:otherwise>
					<xsl:value-of select="concat($OPENING_PARENTHESIS,@ITEMNBR,$CLOSING_PARENTHESIS)" />
				</xsl:otherwise>
			</xsl:choose>
			<xsl:apply-templates />
		</xsl:element>
	</xsl:template>

	<xsl:template name="generateFIGITEMLink">
		<xsl:param name="xrefId"/>
		<xsl:variable name="sheet" select="//GRAPHIC/SHEET[HOTSPOT[@ID=$xrefId]]"/>
		<xsl:variable name="entiteGraphique" select="key('getEntiteGraphique',$sheet/@GNBR )"/>
		<xsl:value-of select="concat('displayGraphicElementAndHighlightingHotspot(',$SINGLE_QUOTATION_MARK,$entiteGraphique/NOM_PHYSIQUE,$SINGLE_QUOTATION_MARK,$COMMA,$SINGLE_QUOTATION_MARK,$sheet[1]/HOTSPOT[@ID=$xrefId]/@APSNAME,$SINGLE_QUOTATION_MARK,$COMMA,$SINGLE_QUOTATION_MARK,$entiteGraphique/NOM_PHYSIQUE,$SINGLE_QUOTATION_MARK,$COMMA,$SINGLE_QUOTATION_MARK,$entiteGraphique/FORMAT,$SINGLE_QUOTATION_MARK,$CLOSING_PARENTHESIS)">
		</xsl:value-of>
	</xsl:template>

<xsl:key match="dmodule/ENTITE_GRAPHIQUE" name="getEntiteGraphique" use="NOM_LOGIQUE"/>

	<xsl:template name="getSheetGNBR">
		<xsl:param name="graphicKey"/>
		<xsl:param name="sheetNbr"/>
		<xsl:choose>
			<xsl:when test="starts-with($graphicKey,'F')">
				<xsl:value-of select="//GRAPHIC/SHEET[@KEY=$graphicKey]/@GNBR"/>
			</xsl:when>
			<xsl:when test="$sheetNbr='*'">
				<xsl:value-of select="//GRAPHIC[@KEY=$graphicKey]/SHEET[position()=1]/@GNBR"/>
			</xsl:when>
			<xsl:otherwise>
				<xsl:value-of select="//GRAPHIC[@KEY=$graphicKey]/SHEET[number(@SHEETNBR)=number($sheetNbr)]/@GNBR"/>
			</xsl:otherwise>
		</xsl:choose>
	</xsl:template>

	<xsl:template name="displayGraphicElement">
		<xsl:param name="graphicKey"/>
		<xsl:param name="sheetNbr"/>
		<xsl:param name="hotspot"/>
		<xsl:variable name="sheetGNBR">
			<xsl:call-template name="getSheetGNBR">
				<xsl:with-param name="graphicKey" select="$graphicKey"/>
				<xsl:with-param name="sheetNbr" select="$sheetNbr"/>
			</xsl:call-template>
		</xsl:variable>
		<xsl:variable name="entite" select="key('getEntiteGraphique', $sheetGNBR)"/>
		<xsl:call-template name="displayFinalGraphicElement">
			<xsl:with-param name="entite" select="$entite"/>
			<xsl:with-param name="hotspot" select="$hotspot"/>
		</xsl:call-template>
	</xsl:template>

	<xsl:template name="displayFinalGraphicElement">
		<xsl:param name="entite"/>
		<xsl:param name="hotspot"/>
		<xsl:choose>
			<xsl:when test="number($hotspot)=0">
				<xsl:value-of select="concat('displayGraphicElement(',$SINGLE_QUOTATION_MARK,$entite/NOM_PHYSIQUE,$SINGLE_QUOTATION_MARK,$COMMA,$SINGLE_QUOTATION_MARK,$entite/FORMAT,$SINGLE_QUOTATION_MARK,$CLOSING_PARENTHESIS)">
				</xsl:value-of>
			</xsl:when>
			<xsl:otherwise>
				<xsl:value-of select="concat('displayGraphicElementAndHighlightingHotspot(',$SINGLE_QUOTATION_MARK,$entite/NOM_PHYSIQUE,$SINGLE_QUOTATION_MARK,$COMMA,$SINGLE_QUOTATION_MARK,$hotspot,$SINGLE_QUOTATION_MARK,$COMMA,$SINGLE_QUOTATION_MARK,$entite/NOM_LOGIQUE,$SINGLE_QUOTATION_MARK,$COMMA,$SINGLE_QUOTATION_MARK,$entite/FORMAT,$SINGLE_QUOTATION_MARK,$CLOSING_PARENTHESIS)">
				</xsl:value-of>
			</xsl:otherwise>
		</xsl:choose>
	</xsl:template>

	<!-- is attribut change mark = 1 add to class style change_marck-css_class -->
	<xsl:template name="applyCssClass">
		<xsl:param name="expectedClass"/>
		<xsl:param name="changeMark"/>
		<xsl:variable name="isChangeMark">
			<xsl:call-template name="verifiesChangeMark">
				<xsl:with-param name="changeMark">
					<xsl:value-of select="$changeMark"/>
				</xsl:with-param>
			</xsl:call-template>
		</xsl:variable>
		<xsl:choose>

			<xsl:when test="$isChangeMark='true' and $expectedClass!=$SPACE ">
				<xsl:attribute name="class">
				<xsl:value-of select="concat($expectedClass,$SPACE,$CHANGE_MARK_CSS_CLASS)"/>
			</xsl:attribute>
			</xsl:when>

			<xsl:when test="$isChangeMark='true' and $expectedClass=$SPACE">
				<xsl:attribute name="class">
				<xsl:value-of select="$CHANGE_MARK_CSS_CLASS"/>
				</xsl:attribute>
			</xsl:when>

			<xsl:when test="$isChangeMark='false' and $expectedClass!=$SPACE">
				<xsl:attribute name="class">
				<xsl:value-of select="$expectedClass"/>
				</xsl:attribute>
			</xsl:when>
			<xsl:otherwise/>

		</xsl:choose>
	</xsl:template>

		<xsl:template name="verifiesChangeMark">
		<xsl:param name="changeMark"/>
		<xsl:choose>
			<xsl:when test="$changeMark and $changeMark='1'">
				<xsl:value-of select="'true'"/>
			</xsl:when>
			<xsl:otherwise>
				<xsl:value-of select="'false'"/>
			</xsl:otherwise>
		</xsl:choose>
	</xsl:template>



	<xsl:template name="generateRefExt">
		<xsl:param name="href" />
		<xsl:variable name="escapedHref">
			<xsl:call-template name="escapeQuot">
				<xsl:with-param name="text" select="$href" />
			</xsl:call-template>
		</xsl:variable>
		<xsl:value-of
			select="concat('gotoRefExt(',$DOUBLE_QUOTATION_MARK,$escapedHref,$DOUBLE_QUOTATION_MARK,$CLOSING_PARENTHESIS)" />
	</xsl:template>

	<xsl:template name="generateRefExtWithTitle">
		<xsl:param name="href" />
		<xsl:param name="title" />
		<xsl:variable name="escapedHref">
			<xsl:call-template name="escapeQuot">
				<xsl:with-param name="text" select="$href" />
			</xsl:call-template>
		</xsl:variable>
		<xsl:variable name="escapedTitle">
			<xsl:call-template name="escapeQuot">
				<xsl:with-param name="text" select="$title" />
			</xsl:call-template>
		</xsl:variable>
		<xsl:value-of
    select="concat('gotoRefExt(',$DOUBLE_QUOTATION_MARK,$escapedHref,$DOUBLE_QUOTATION_MARK,$COMMA,$DOUBLE_QUOTATION_MARK,$escapedTitle,$DOUBLE_QUOTATION_MARK,$CLOSING_PARENTHESIS)" />
    </xsl:template>

	<xsl:template name="escapeQuot">
		<xsl:param name="text" />
		<xsl:choose>
			<xsl:when test="contains($text, $DOUBLE_QUOTATION_MARK)">
				<xsl:variable name="bufferBefore"
					select="substring-before($text, $DOUBLE_QUOTATION_MARK)" />
				<xsl:variable name="newBuffer"
					select="substring-after($text, $DOUBLE_QUOTATION_MARK)" />
				<xsl:value-of select="$bufferBefore" />
				<xsl:text>\"</xsl:text>
				<xsl:call-template name="escapeQuot">
					<xsl:with-param name="text" select="$newBuffer" />
				</xsl:call-template>
			</xsl:when>
			<xsl:otherwise>
				<xsl:value-of select="$text" />
			</xsl:otherwise>
		</xsl:choose>
	</xsl:template>

	<xsl:param name="PATH_SPECIAL_CHAR" select="'./resources/images/special_char/'" />
	<xsl:param name="GIF_EXTENSION" select="'.gif'"/>
	<!-- *********************************************************************** -->
	<!-- * RULES FOR specialsymb * -->
	<!-- *********************************************************************** -->
	<xsl:template name="TranslateSpecialSymb" match="specialsymb">
		<xsl:call-template name="getSymboleHexadecimalValue">
			<xsl:with-param name="name" select="./@name" />
		</xsl:call-template>
	</xsl:template>
	<!-- *********************************************************************** -->
	<!-- * RULES FOR specialchar * -->
	<!-- *********************************************************************** -->
	<xsl:template match="specialchar | SPECIALCHAR " name="TranslateSpecialChar">
  		<xsl:call-template name="getSpecialCharHexadecimalValue">
			<xsl:with-param name="name" select="./@name | ./@NAME"/>
		</xsl:call-template>
	</xsl:template>

  <xsl:template name="getSpecialCharHexadecimalValue">
		<xsl:param name="name"/>
		<xsl:choose>
    	<xsl:when test="$name='aleph'">
				<xsl:value-of select="'א'"/>
			</xsl:when>
      <xsl:when test="$name='and'">
				<xsl:value-of select="'∧'"/>
			</xsl:when>
      <xsl:when test="$name='ang90'">
				<xsl:value-of select="'∟'"/>
			</xsl:when>
      <xsl:when test="$name='angsph'">
				<xsl:value-of select="'∢'"/>
			</xsl:when>
      <xsl:when test="$name='angst'">
				<xsl:value-of select="'Å'"/>
			</xsl:when>
			<xsl:when test="$name='ap'">
				<xsl:value-of select="'≈'"/>
			</xsl:when>
      <xsl:when test="$name='becaus'">
				<xsl:value-of select="'∵'"/>
			</xsl:when>
			<xsl:when test="$name='bernou'">
				<xsl:value-of select="'ℬ'"/>
			</xsl:when>
      <xsl:when test="$name='blank'">
				<xsl:value-of select="'⎵'"/>
			</xsl:when>
      <xsl:when test="$name='blk12'">
				<xsl:value-of select="'▒'"/>
			</xsl:when>
      <xsl:when test="$name='blk14'">
				<xsl:value-of select="'░'"/>
			</xsl:when>
      <xsl:when test="$name='blk34'">
				<xsl:value-of select="'▓'"/>
			</xsl:when>
      <xsl:when test="$name='block'">
				<xsl:value-of select="'█'"/>
			</xsl:when>
      <xsl:when test="$name='bottom'">
				<xsl:value-of select="'⏊'"/>
			</xsl:when>
      <xsl:when test="$name='cap'">
				<xsl:value-of select="'∩'"/>
			</xsl:when>
      <xsl:when test="$name='caret'">
				<xsl:value-of select="' ⁁'"/>
			</xsl:when>
			<xsl:when test="$name='check'">
				<xsl:value-of select="'✓'"/>
			</xsl:when>
			<xsl:when test="$name='cir'">
				<xsl:value-of select="'○'"/>
			</xsl:when>
      <xsl:when test="$name='clubs'">
				<xsl:value-of select="'♣'"/>
			</xsl:when>
      <xsl:when test="$name='compfn'">
				<xsl:value-of select="'∘'"/>
			</xsl:when>
			<xsl:when test="$name='cong'">
				<xsl:value-of select="'≅'"/>
			</xsl:when>
      <xsl:when test="$name='conint'">
				<xsl:value-of select="'∮'"/>
			</xsl:when>
      <xsl:when test="$name='copysr'">
				<xsl:value-of select="'℗'"/>
			</xsl:when>
      <xsl:when test="$name='cross'">
				<xsl:value-of select="'✗'"/>
			</xsl:when>
      <xsl:when test="$name='cup'">
				<xsl:value-of select="'∪'"/>
			</xsl:when>
			<xsl:when test="$name='darr'">
				<xsl:value-of select="'↓'"/>
			</xsl:when>
      <xsl:when test="$name='diams'">
				<xsl:value-of select="'♦'"/>
			</xsl:when>
      <xsl:when test="$name='dlcrop'">
				<xsl:value-of select="'⌍'"/>
			</xsl:when>
      <xsl:when test="$name='DotDot'">
				<xsl:value-of select="'&#8412;'"/>
			</xsl:when>
      <xsl:when test="$name='drcrop'">
				<xsl:value-of select="'⌌'"/>
			</xsl:when>
      <xsl:when test="$name='dtri'">
				<xsl:value-of select="'▽'"/>
			</xsl:when>
      <xsl:when test="$name='dtrif'">
				<xsl:value-of select="'▼'"/>
			</xsl:when>
      <xsl:when test="$name='equiv'">
				<xsl:value-of select="'≡'"/>
			</xsl:when>
      <xsl:when test="$name='exist'">
				<xsl:value-of select="'∃'"/>
			</xsl:when>
			<xsl:when test="$name='female'">
				<xsl:value-of select="'♀'"/>
			</xsl:when>
			<xsl:when test="$name='fflig'">
				<xsl:value-of select="'ﬀ'"/>
			</xsl:when>
			<xsl:when test="$name='ffllig'">
				<xsl:value-of select="'ﬄ'"/>
			</xsl:when>
      <xsl:when test="$name='filig'">
				<xsl:value-of select="'ﬁ'"/>
			</xsl:when>
      <xsl:when test="$name='fjlig'">
				<xsl:value-of select="'ｆ'"/>
			</xsl:when>
			<xsl:when test="$name='flat'">
				<xsl:value-of select="'♭'"/>
			</xsl:when>
			<xsl:when test="$name='fllig'">
				<xsl:value-of select="'ﬂ'"/>
			</xsl:when>
			<xsl:when test="$name='fnof'">
				<xsl:value-of select="'ƒ'"/>
			</xsl:when>
			<xsl:when test="$name='forall'">
				<xsl:value-of select="'∀'"/>
			</xsl:when>
			<xsl:when test="$name='frac13'">
				<xsl:value-of select="'⅓'"/>
			</xsl:when>
			<xsl:when test="$name='frac15'">
				<xsl:value-of select="'⅕'"/>
			</xsl:when>
			<xsl:when test="$name='frac16'">
				<xsl:value-of select="'⅙'"/>
			</xsl:when>
			<xsl:when test="$name='frac18'">
				<xsl:value-of select="'⅛'"/>
			</xsl:when>
			<xsl:when test="$name='frac23'">
				<xsl:value-of select="'⅔'"/>
			</xsl:when>
			<xsl:when test="$name='frac25'">
				<xsl:value-of select="'⅖'"/>
			</xsl:when>
			<xsl:when test="$name='frac35'">
				<xsl:value-of select="'⅗'"/>
			</xsl:when>
			<xsl:when test="$name='frac38'">
				<xsl:value-of select="'⅜'"/>
			</xsl:when>
			<xsl:when test="$name='frac45'">
				<xsl:value-of select="'⅘'"/>
			</xsl:when>
			<xsl:when test="$name='frac56'">
				<xsl:value-of select="'⅚'"/>
			</xsl:when>
			<xsl:when test="$name='frac58'">
				<xsl:value-of select="'⅝'"/>
			</xsl:when>
			<xsl:when test="$name='frac78'">
				<xsl:value-of select="'⅞'"/>
			</xsl:when>
			<xsl:when test="$name='ge'">
				<xsl:value-of select="'≥'"/>
			</xsl:when>
      <xsl:when test="$name='hamilt'">
				<xsl:value-of select="'ℋ'"/>
			</xsl:when>
      <xsl:when test="$name='hearts'">
				<xsl:value-of select="'♥'"/>
			</xsl:when>
      <xsl:when test="$name='horbar'">
				<xsl:value-of select="'―'"/>
			</xsl:when>
      <xsl:when test="$name='hybull'">
				<xsl:value-of select="'⁃'"/>
			</xsl:when>
			<xsl:when test="$name='iff'">
				<xsl:value-of select="'⇔'"/>
			</xsl:when>
      <xsl:when test="$name='incare'">
				<xsl:value-of select="'℅'"/>
			</xsl:when>
			<xsl:when test="$name='infin'">
				<xsl:value-of select="'∞'"/>
			</xsl:when>
			<xsl:when test="$name='int'">
				<xsl:value-of select="'∫'"/>
			</xsl:when>
			<xsl:when test="$name='isin'">
				<xsl:value-of select="'∈'"/>
			</xsl:when>
      <xsl:when test="$name='lagran'">
				<xsl:value-of select="'ℒ'"/>
			</xsl:when>
      <xsl:when test="$name='lang'">
				<xsl:value-of select="'⟨'"/>
			</xsl:when>
      <xsl:when test="$name='larr'">
				<xsl:value-of select="'←'"/>
			</xsl:when>
      <xsl:when test="$name='le'">
				<xsl:value-of select="'≤'"/>
			</xsl:when>
      <xsl:when test="$name='lhblk'">
				<xsl:value-of select="'▄'"/>
			</xsl:when>
      <xsl:when test="$name='lowast'">
				<xsl:value-of select="'∗'"/>
			</xsl:when>
			<xsl:when test="$name='loz'">
				<xsl:value-of select="'◇'"/>
			</xsl:when>
      <xsl:when test="$name='lozf'">
				<xsl:value-of select="'◆'"/>
			</xsl:when>
      <xsl:when test="$name='ltri'">
				<xsl:value-of select="'◁'"/>
			</xsl:when>
      <xsl:when test="$name='ltrif'">
				<xsl:value-of select="'◀'"/>
			</xsl:when>
			<xsl:when test="$name='male'">
				<xsl:value-of select="'♂'"/>
			</xsl:when>
      <xsl:when test="$name='malt'">
				<xsl:value-of select="'✠'"/>
			</xsl:when>
      <xsl:when test="$name='marker'">
				<xsl:value-of select="'▮'"/>
			</xsl:when>
      <xsl:when test="$name='mlarr'">
				<xsl:value-of select="'⇐'"/>
			</xsl:when>
			<xsl:when test="$name='mnplus'">
				<xsl:value-of select="'∓'"/>
			</xsl:when>
      TODO: mprime.gif
      <xsl:when test="$name='mrarr'">
				<xsl:value-of select="'⇒'"/>
			</xsl:when>
      <xsl:when test="$name='nabla'">
				<xsl:value-of select="'∇'"/>
			</xsl:when>
      <xsl:when test="$name='natur'">
				<xsl:value-of select="'♮'"/>
			</xsl:when>
			<xsl:when test="$name='ne'">
				<xsl:value-of select="'≠'"/>
			</xsl:when>
      <xsl:when test="$name='ni'">
				<xsl:value-of select="'∋'"/>
			</xsl:when>
      <xsl:when test="$name='nldr'">
				<xsl:value-of select="'‥'"/>
			</xsl:when>
      <xsl:when test="$name='notin'">
				<xsl:value-of select="'∉'"/>
			</xsl:when>
      <xsl:when test="$name='ohm'">
				<xsl:value-of select="'Ω'"/>
			</xsl:when>
      <xsl:when test="$name='or'">
				<xsl:value-of select="'∨'"/>
			</xsl:when>
      TODO: order.gif
      <xsl:when test="$name='par'">
				<xsl:value-of select="'∥'"/>
			</xsl:when>
      <xsl:when test="$name='part'">
				<xsl:value-of select="'∂'"/>
			</xsl:when>
      <xsl:when test="$name='perp'">
				<xsl:value-of select="'⟂'"/>
			</xsl:when>
      TODO: phmmat.gif
      <xsl:when test="$name='phone'">
				<xsl:value-of select="'☎'"/>
			</xsl:when>
      TODO: Prime.gif
      <xsl:when test="$name='prop'">
				<xsl:value-of select="'∝'"/>
			</xsl:when>
      <xsl:when test="$name='radic'">
				<xsl:value-of select="'⎷'"/>
			</xsl:when>
      <xsl:when test="$name='rang'">
				<xsl:value-of select="'⟩'"/>
			</xsl:when>
			<xsl:when test="$name='rarr'">
				<xsl:value-of select="'→'"/>
			</xsl:when>
      <xsl:when test="$name='rect'">
				<xsl:value-of select="'▭'"/>
			</xsl:when>
      <xsl:when test="$name='rtri'">
				<xsl:value-of select="'▷'"/>
			</xsl:when>
      <xsl:when test="$name='rtrif'">
				<xsl:value-of select="'▶'"/>
			</xsl:when>
      <xsl:when test="$name='rx'">
				<xsl:value-of select="'℞'"/>
			</xsl:when>
      <xsl:when test="$name='sext'">
				<xsl:value-of select="'✶'"/>
			</xsl:when>
      <xsl:when test="$name='sharp'">
				<xsl:value-of select="'♯'"/>
			</xsl:when>
      <xsl:when test="$name='sim'">
				<xsl:value-of select="'~'"/>
			</xsl:when>
      <xsl:when test="$name='sime'">
				<xsl:value-of select="'≃'"/>
			</xsl:when>
      <xsl:when test="$name='spades'">
				<xsl:value-of select="'♠'"/>
			</xsl:when>
      <xsl:when test="$name='squ'">
				<xsl:value-of select="'□'"/>
			</xsl:when>
      <xsl:when test="$name='square'">
				<xsl:value-of select="'□'"/>
			</xsl:when>
      <xsl:when test="$name='squf'">
				<xsl:value-of select="'■'"/>
			</xsl:when>
      <xsl:when test="$name='star'">
				<xsl:value-of select="'☆'"/>
			</xsl:when>
      <xsl:when test="$name='starf'">
				<xsl:value-of select="'★'"/>
			</xsl:when>
      <xsl:when test="$name='sub'">
				<xsl:value-of select="'⊂'"/>
			</xsl:when>
      <xsl:when test="$name='sube'">
				<xsl:value-of select="'⫅'"/>
			</xsl:when>
      <xsl:when test="$name='sup'">
				<xsl:value-of select="'⊃'"/>
			</xsl:when>
      <xsl:when test="$name='supe'">
				<xsl:value-of select="'⫆'"/>
			</xsl:when>
      <xsl:when test="$name='target'">
				<xsl:value-of select="'⌖'"/>
			</xsl:when>
      TODO: tdot.gif
      <xsl:when test="$name='telrec'">
				<xsl:value-of select="'⌕'"/>
			</xsl:when>
      TODO: there4.gif
      <xsl:when test="$name='tprime'">
				<xsl:value-of select="'‴'"/>
			</xsl:when>
			<xsl:when test="$name='uarr'">
				<xsl:value-of select="'↑'"/>
			</xsl:when>
      <xsl:when test="$name='uhblk'">
				<xsl:value-of select="'▀'"/>
			</xsl:when>
      <xsl:when test="$name='ulcrop'">
				<xsl:value-of select="'⌏'"/>
			</xsl:when>
      <xsl:when test="$name='urcrop'">
				<xsl:value-of select="'⌎'"/>
			</xsl:when>
			<xsl:when test="$name='utri'">
				<xsl:value-of select="'△'"/>
			</xsl:when>
      <xsl:when test="$name='utrif'">
				<xsl:value-of select="'▲'"/>
			</xsl:when>
      <xsl:when test="$name='vellip'">
				<xsl:value-of select="'⋮'"/>
			</xsl:when>
      TODO: Verbar.gif
      <xsl:when test="$name='wedgeq'">
				<xsl:value-of select="'≙'"/>
			</xsl:when>
      <xsl:otherwise>
      	<xsl:element name="img">
			    <xsl:attribute name="src">
			      <xsl:value-of select="concat($PATH_SPECIAL_CHAR, $name, $GIF_EXTENSION)"/>
		      </xsl:attribute>
		    </xsl:element>
      </xsl:otherwise>
		</xsl:choose>
	</xsl:template>


	<xsl:template name="getSymboleHexadecimalValue">
		<xsl:param name="name" />
		<xsl:choose>
			<xsl:when test="$name='a'">
				<xsl:value-of select="'&#x03B1;'" />
			</xsl:when>
			<xsl:when test="$name='A'">
				<xsl:value-of select="'&#x0391;'" />
			</xsl:when>
			<xsl:when test="$name='b'">
				<xsl:value-of select="'&#x03B2;'" />
			</xsl:when>
			<xsl:when test="$name='B'">
				<xsl:value-of select="'&#x0392;'" />
			</xsl:when>
			<xsl:when test="$name='c'">
				<xsl:value-of select="'&#x03C7;'" />
			</xsl:when>
			<xsl:when test="$name='C'">
				<xsl:value-of select="'&#x03A7;'" />
			</xsl:when>
			<xsl:when test="$name='d'">
				<xsl:value-of select="'&#x03B4;'" />
			</xsl:when>
			<xsl:when test="$name='D'">
				<xsl:value-of select="'&#x0394;'" />
			</xsl:when>
			<xsl:when test="$name='e'">
				<xsl:value-of select="'&#x03B5;'" />
			</xsl:when>
			<xsl:when test="$name='E'">
				<xsl:value-of select="'&#x0395;'" />
			</xsl:when>
			<xsl:when test="$name='h'">
				<xsl:value-of select="'&#x03B7;'" />
			</xsl:when>
			<xsl:when test="$name='H'">
				<xsl:value-of select="'&#x0397;'" />
			</xsl:when>
			<xsl:when test="$name='g'">
				<xsl:value-of select="'&#x03B3;'" />
			</xsl:when>
			<xsl:when test="$name='G'">
				<xsl:value-of select="'&#x0393;'" />
			</xsl:when>
			<xsl:when test="$name='i'">
				<xsl:value-of select="'&#x03B9;'" />
			</xsl:when>
			<xsl:when test="$name='I'">
				<xsl:value-of select="'&#x0399;'" />
			</xsl:when>
			<xsl:when test="$name='k'">
				<xsl:value-of select="'&#x03BA;'" />
			</xsl:when>
			<xsl:when test="$name='K'">
				<xsl:value-of select="'&#x039A;'" />
			</xsl:when>
			<xsl:when test="$name='l'">
				<xsl:value-of select="'&#x03BB;'" />
			</xsl:when>
			<xsl:when test="$name='L'">
				<xsl:value-of select="'&#x039B;'" />
			</xsl:when>
			<xsl:when test="$name='m'">
				<xsl:value-of select="'&#x03BC;'" />
			</xsl:when>
			<xsl:when test="$name='M'">
				<xsl:value-of select="'&#x039C;'" />
			</xsl:when>
			<xsl:when test="$name='n'">
				<xsl:value-of select="'&#x03BD;'" />
			</xsl:when>
			<xsl:when test="$name='N'">
				<xsl:value-of select="'&#x039D;'" />
			</xsl:when>
			<xsl:when test="$name='o'">
				<xsl:value-of select="'&#x03A9;'" />
			</xsl:when>
			<xsl:when test="$name='O'">
				<xsl:value-of select="'&#x2126;'" />
			</xsl:when>
			<xsl:when test="$name='f'">
				<xsl:value-of select="'&#x03C6;'" />
			</xsl:when>
			<xsl:when test="$name='F'">
				<xsl:value-of select="'&#x03A6;'" />
			</xsl:when>
			<xsl:when test="$name='p'">
				<xsl:value-of select="'&#x03C0;'" />
			</xsl:when>
			<xsl:when test="$name='P'">
				<xsl:value-of select="'&#x03A0;'" />
			</xsl:when>
			<xsl:when test="$name='v'">
				<xsl:value-of select="'&#x03D6;'" />
			</xsl:when>
			<xsl:when test="$name='V'">
				<xsl:value-of select="'&#x03D6;'" />
			</xsl:when>
			<xsl:when test="$name='y'">
				<xsl:value-of select="'&#x03C8;'" />
			</xsl:when>
			<xsl:when test="$name='Y'">
				<xsl:value-of select="'&#x03A8;'" />
			</xsl:when>
			<xsl:when test="$name='r'">
				<xsl:value-of select="'&#x03C1;'" />
			</xsl:when>
			<xsl:when test="$name='R'">
				<xsl:value-of select="'&#x03A1;'" />
			</xsl:when>
			<xsl:when test="$name='s'">
				<xsl:value-of select="'&#x03C3;'" />
			</xsl:when>
			<xsl:when test="$name='S'">
				<xsl:value-of select="'&#x03A3;'" />
			</xsl:when>
			<xsl:when test="$name='v'">
				<xsl:value-of select="'&#x03C2;'" />
			</xsl:when>
			<xsl:when test="$name='V'">
				<xsl:value-of select="'&#x03C2;'" />
			</xsl:when>
			<xsl:when test="$name='t'">
				<xsl:value-of select="'&#x03C4;'" />
			</xsl:when>
			<xsl:when test="$name='T'">
				<xsl:value-of select="'&#x03A4;'" />
			</xsl:when>
			<xsl:when test="$name='q'">
				<xsl:value-of select="'&#x03B8;'" />
			</xsl:when>
			<xsl:when test="$name='Q'">
				<xsl:value-of select="'&#x0398;'" />
			</xsl:when>
			<xsl:when test="$name='j'">
				<xsl:value-of select="'&#x03D1;'" />
			</xsl:when>
			<xsl:when test="$name='J'">
				<xsl:value-of select="'&#x03D1;'" />
			</xsl:when>
			<xsl:when test="$name='u'">
				<xsl:value-of select="'&#x03C5;'" />
			</xsl:when>
			<xsl:when test="$name='U'">
				<xsl:value-of select="'&#x03A5;'" />
			</xsl:when>
			<xsl:when test="$name='w'">
				<xsl:value-of select="'&#x03c9;'" />
			</xsl:when>
			<xsl:when test="$name='W'">
				<xsl:value-of select="'&#x03a9;'" />
			</xsl:when>
			<xsl:when test="$name='x'">
				<xsl:value-of select="'&#x03BE;'" />
			</xsl:when>
			<xsl:when test="$name='X'">
				<xsl:value-of select="'&#x039E;'" />
			</xsl:when>
			<xsl:when test="$name='z'">
				<xsl:value-of select="'&#x03B6;'" />
			</xsl:when>
			<xsl:when test="$name='Z'">
				<xsl:value-of select="'&#x0396;'" />
			</xsl:when>
		</xsl:choose>
	</xsl:template>

<xsl:param name="CON_NBR_CLASS">CON_NBR_CLASS</xsl:param>
<xsl:param name="CON_NAME_CLASS">CON_NAME_CLASS</xsl:param>

<xsl:param name="CSN_CLASS">CSN_CLASS</xsl:param>

<xsl:param name="SPECIALPART_IMPORTANT_CLASS">SPECIALPART_IMPORTANT_CLASS</xsl:param>
<xsl:param name="SPECIALPART_CRITICAL_CLASS">SPECIALPART_CRITICAL_CLASS</xsl:param>

<xsl:param name="TED_TOOLNBR_CLASS">TED_TOOLNBR_CLASS</xsl:param>
<xsl:param name="TED_TOOLNAME_CLASS">TED_TOOLNAME_CLASS</xsl:param>

<xsl:param name="NO_MARGIN">no_margin</xsl:param>

<xsl:param name="STD_NBR_CLASS">STD_NBR_CLASS</xsl:param>
<xsl:param name="STD_NAME_CLASS">STD_NAME_CLASS</xsl:param>

<xsl:param name="NOTE_CLASS">NOTE_CLASS</xsl:param>
<xsl:param name="NOTE_BEFORE_CLASS">NOTE_BEFORE_CLASS</xsl:param>
<xsl:param name="CAUTION_CLASS">CAUTION_CLASS</xsl:param>
<xsl:param name="CAUTION_BEFORE_CLASS">CAUTION_BEFORE_CLASS</xsl:param>
<xsl:param name="WARNING_CLASS">WARNING_CLASS</xsl:param>
<xsl:param name="WARNING_BEFORE_CLASS">WARNING_BEFORE_CLASS</xsl:param>
<xsl:param name="FIGITEM_CLASS">FIGITEM_CLASS</xsl:param>
<xsl:param name="FIGITEM_HEAD_CLASS">FIGITEM_HEAD_CLASS</xsl:param>

<xsl:param name="DEFLIST_TABLE_CLASS" select="'DEFLIST_TABLE_CLASS'"/>

<xsl:param name="LEGEND_SELECTED_ITEM">legend_selected_item</xsl:param>
<xsl:param name="LEGEND_ITEM">legend_item</xsl:param>
<xsl:param name="LEGEND_DIV_CLASS">legend_div_class</xsl:param>

<xsl:param name="CAROUSEL_THUMB_CLASS">carousel_thumb</xsl:param>
<xsl:param name="CAROUSEL_LAST_THUMB_CLASS">last_carousel_thumb</xsl:param>
<xsl:param name="TITLE_OF_TABLE">title_of_table</xsl:param>
<xsl:param name="HIDDEN_CLASS">du_title_hidden</xsl:param>
<xsl:param name="TITLE_ID">title_id</xsl:param>
<xsl:param name="STATUS_HIDDEN_CLASS">status_hidden</xsl:param>
<xsl:param name="STATUS_DISPLAYED_CLASS">status_displayed</xsl:param>
<xsl:param name="STATUS_DIV_CLASS">duStatusDiv</xsl:param>
<xsl:param name="STATUS_TITLE_CLASS">titleInsideStatus</xsl:param>
<xsl:param name="STATUS_TABLE_CLASS">tableInsideStatus</xsl:param>
<xsl:param name="STATUS_TABLE_APPLIC_CLASS">tableInsideApplic</xsl:param>
<xsl:param name="PARENTHESIS_CLASS" select="'parenthese'"/>
<xsl:param name="TITLE_DU_SHORT_DMC" select="'title_du_short_dmc'"/>
<xsl:param name="TITLE_DU_REVISION_ICON" select="'title_du_revision_icon'"/>
<!-- constants of IPC DU -->
<xsl:param name="VIEW_IPC_DETAIL_NO_DATA" select="'ipc_table_no_data_class'"/>
<xsl:param name="TABLE_IPC_CLASS" select="'ipc_table_class'"/>
<xsl:param name="IPC_INDENTATION_CLASS" select="'ipc_indentation_font'"/>
<xsl:param name="IPC_INDENTATION_STAR_CLASS" select="'ipc_indentation_star_font'"/>
<xsl:param name="IPC_CSN_LINK" select="'ipc_csn_link'"/>
<xsl:param name="IPC_ITEM_NIL" select="'ipc_item_nil'"/>
<xsl:param name="VIEW_IPC_DETAIL_HEADER_TITLE_CLASS" select="'csn_popup_header_title'"/>
<xsl:param name="VIEW_IPC_TABLE_CLASS" select="'ipc_table_class'"/>
<xsl:param name="VIEW_IPC_DETAIL_HEADER_DIV_CLASS" select="'csn_popup_header'"/>
<xsl:param name="VIEW_IPC_DETAIL_HEADER_DESIGNATION_DIV_CLASS" select="'csn_popup_header_div_designation'"/>
<xsl:param name="VIEW_IPC_DETAIL_HEADER_TABLE_CLASS" select="'csn_popup_header_table'"/>
<xsl:param name="VIEW_IPC_DETAIL_HEADER_ICON_ENTRY_CLASS" select="'csn_popup_header_icon_entry'"/>
<xsl:param name="VIEW_IPC_DETAIL_HEADER_ITEM_TITLE_CLASS" select="'csn_popup_header_item_title'"/>
<xsl:param name="VIEW_IPC_DETAIL_HEADER_ITEM_LINK_CLASS" select="'csn_popup_header_item_link'"/>
<xsl:param name="VIEW_IPC_DETAIL_BODY_ITEM_CLASS" select="'item_class'"/>
<xsl:param name="VIEW_IPC_DETAIL_ISN_BLOCK_CLASS" select="'isn_block_class'"/>
<xsl:param name="VIEW_IPC_DETAIL_APPLIC_SEPARATOR_CLASS" select="'csn_applic_separator'"/>
<xsl:param name="VIEW_IPC_CHANGEMARK" select="'changemarkBackground'"/>
<xsl:param name="TR_LIKE_THEAD" select="'tr_like_th_class'"/>
<xsl:param name="TD_DISABLED" select="'td_disabled_class'"/>


<xsl:param name="VIEW_IPC_DETAIL_BODY_CLASS" select="'csn_popup_body_table'"/>
<xsl:param name="SEARCH_BANNER">banner</xsl:param>
<xsl:param name="SEARCH_TITLE_CLASS">SEARCH_TITLE_CLASS</xsl:param>
<xsl:param name="SEARCH_LINKS_CLASS">SEARCH_LINKS_CLASS</xsl:param>
<xsl:param name="SEARCH_QUERY_CLASS">SEARCH_QUERY_CLASS</xsl:param>
<xsl:param name="SEARCH_ITEMS_ATTRIBUTES_CLASS">SEARCH_ITEMS_ATTRIBUTES_CLASS</xsl:param>
<xsl:param name="SEARCH_ITEMS_HIGHLIGHT_CLASS">SEARCH_ITEMS_HIGHLIGHT_CLASS</xsl:param>
<xsl:param name="SEARCH_NBR_RESULTS_CLASS">SEARCH_QUERY_CLASS</xsl:param>

<xsl:param name="GENERAL_TABLE_CLASS" select="'odd_table_class'"/>
<xsl:param name="ISN_TABLE_CLASS" select="'isn_table_class'"/>
<xsl:param name="ISN_APPLIC_TABLE_CLASS" select="'isn_applic_table_class'"/>
<xsl:param name="STATUS_TABLE_LABEL_CLASS" select="'status_table_label_class'"/>
<xsl:param name="STATUS_TABLE_TITLE_CLASS" select="'status_table_title_class'"/>
<xsl:param name="STATUS_TABLE_VALUE_CLASS" select="'status_table_value_class'"/>
<xsl:param name="STATUS_APPLICABILITY_ODD_TR" select="'status_applicability_odd_tr'"/>
<xsl:param name="STATUS_APPLICABILITY_TR" select="'status_applicability_tr'"/>
<xsl:param name="STATUS_APPLICABILITIES_SHOW_HIDE" select="'applicabilities_show_hide'"/>
<xsl:param name="STATUS_APPLICABILITIES_DISPLAYED" select="'applicabilities_displayed'"/>
<xsl:param name="STATUS_APPLICABILITIES_HIDDEN" select="'applicabilities_hidden'"/>


<xsl:param name="WELCOME_PAGE_IMG_CLASS">WELCOME_PAGE_IMG_CLASS</xsl:param>
<xsl:param name="LOAP_TABLE_CONTAINER_CLASS">LOAP_TABLE_CONTAINER_CLASS</xsl:param>

<xsl:param name="TITLE_GRAPHIC_WDM">titleGraphicWDM</xsl:param>
<xsl:param name="SHEET_GRAPHIC_WDM">sheetGraphicWDM</xsl:param>

<!-- Links -->
<xsl:param name="SPAN_LINKS_CLASS">span_links</xsl:param>
<xsl:param name="SPAN_LINKS_PRETOPIC_CLASS">span_links_pretopic</xsl:param>
<xsl:param name="SPAN_LINKS_PRETOPIC_NOT_ENRICHED_CLASS">span_links_pretopic_not_Enriched</xsl:param>
<xsl:param name="LINK_HIDDEN_CLASS">link_hidden</xsl:param>
<xsl:param name="LINK_DISPLAYED_CLASS">link_displayed</xsl:param>
<xsl:param name="LINK_APPLICABILITY_ODD_TR" select="'link_applicability_odd_tr'"/>
<xsl:param name="LINK_APPLICABILITY_TR" select="'link_applicability_tr'"/>
<xsl:param name="LINK_TABLE_VALUE_CLASS" select="'link_table_value_class'"/>
<xsl:param name="REF_DESC_CLASS" select="'ref_desc_class'"/>
<xsl:param name="SPAN_LINKS_ENTRY_CLASS">span_links_entry</xsl:param>


<!-- hlt -->
<xsl:param name="TABLE_HLT_CLASS">odd_table_class</xsl:param>
<xsl:param name="TABLE_LOEDU_CLASS">odd_table_class</xsl:param>
<xsl:param name="TABLE_HLT_ISSUEDATE_CLASS">hltIssueDateTable</xsl:param>
<xsl:param name="WP6APPLICLIST_SERIALNO">wp6ApplicList_SerialNo</xsl:param>
<xsl:param name="NO_WRAP_CLASS">noWrapClass</xsl:param>
<xsl:param name="LEFT_ALIGN_CLASS">leftAlign</xsl:param>
<xsl:param name="DATE_WIDTH_CLASS">loeduDateWidth</xsl:param>
<xsl:param name="DMC_WIDTH_CLASS">loeduDmcWidth</xsl:param>
<xsl:param name="TITLE_WIDTH_CLASS">loeduTitleWidth</xsl:param>
<xsl:param name="APPLIC_WIDTH_CLASS">applicWidth</xsl:param>
<xsl:param name="APPLICABILITY_VERSION_LABEL_CLASS">applicVersionClass</xsl:param>
<xsl:param name="APPLICABILITY_MODEL_LABEL_CLASS">applicModelClass</xsl:param>

<xsl:param name="CHANGE_MARK_CSS_CLASS">changeMarkClass</xsl:param>
<xsl:param name="TITLE_DU_PART_CONTENT_DU">titleDuContentDuTitle</xsl:param>
<xsl:param name="TITLE_DU_PART_CONTENT_STATUS">titleDuContentStatusInfoName</xsl:param>

<!-- MSM -->
<xsl:param name="SUBCH_TITLE_CLASS">subch_title_class</xsl:param>
<xsl:param name="SUBUD_TITLE_CLASS">subud_title_class</xsl:param>
<xsl:param name="IDENTASK_CLASS">identask_class</xsl:param>
<xsl:param name="TASK_TITLE_CLASS">task_title_class</xsl:param>
<xsl:param name="MAINLIMI_PNR_TABLE_CLASS">mainlimi_pnr_table_class</xsl:param>
<xsl:param name="MAINLIMI_REFDOC_TABLE_CLASS">mainlimi_refdoc_table_class</xsl:param>
<xsl:param name="NEXT_PERF_TABLE_CLASS">next_perf_table_class</xsl:param>
<xsl:param name="NEXT_PERF_ELEMENT_TABLE_CLASS">next_perf_element_table_class</xsl:param>
<xsl:param name="PNRLIST_TABLE_CLASS">pnrlist_table_class</xsl:param>
<xsl:param name="MAINTASK_CONTAINER_CLASS">maintask_container_class</xsl:param>
<xsl:param name="REFDOC_LOC_CLASS">refdoc_loc_class</xsl:param>
<xsl:param name="REFDOC_TABLE_CLASS">refdoc_table_class</xsl:param>

<xsl:param name="SUBUD_HEADER_TABLE_CLASS">sub_header_table_class</xsl:param>
<xsl:param name="SUBUD_HEADER_SUBCH">sub_header_subch</xsl:param>

<xsl:param name="SUBUD_HEADER_TABLE_PNR_DESC_CLASS">sub_header_table_prn_desc</xsl:param>
<xsl:param name="SUBUD_HEADER_TABLE_PNR_MPN_CLASS">sub_header_table_prn_mpn</xsl:param>
<xsl:param name="SUBUD_HEADER_TABLE_PNR_LIMIT_CLASS">sub_header_table_prn_limit</xsl:param>
<xsl:param name="SUBUD_HEADER_TABLE_PNR_INTERVAL_CLASS">sub_header_table_prn_interval</xsl:param>
<xsl:param name="SUBUD_HEADER_TABLE_PNR_MARGIN_CLASS">sub_header_table_prn_margin</xsl:param>

<xsl:param name="SUBUD_HEADER_TABLE_REFDOC_DESC_CLASS">sub_header_table_refdoc_desc</xsl:param>
<xsl:param name="SUBUD_HEADER_TABLE_REFDOC_DOC_CLASS">sub_header_table_refdoc_doc</xsl:param>
<xsl:param name="SUBUD_HEADER_TABLE_REFDOC_INTERVAL_CLASS">sub_header_table_refdoc_interval</xsl:param>
<xsl:param name="SUBUD_HEADER_TABLE_REFDOC_MARGIN_CLASS">sub_header_table_refdoc_margin</xsl:param>
<xsl:param name="SREFMAN_CLASS">srefman_class</xsl:param>
<xsl:param name="SREFLOC_CLASS">srefloc_class</xsl:param>

<xsl:param name="MSM_INTERV_TD_CLASS">msm_interv_td_class</xsl:param>
<xsl:param name="MSM_MARGIN_TD_CLASS">msm_margin_td_class</xsl:param>
<xsl:param name="MSM_LIMIT_TD_CLASS">msm_limit_td_class</xsl:param>

<xsl:param name="GLOSSARY_TABLE_CLASS">glossary_table_class</xsl:param>
<xsl:param name="MSM_TITLE_CLASS">msm_title_class</xsl:param>
<xsl:param name="MSM_SUB_TITLE_CLASS">msm_sub_title_class</xsl:param>
<xsl:param name="GLOSSARY_TITLE_CLASS">glossary_title_class</xsl:param>
<xsl:param name="CMG_NAVIGATION_DIV_CLASS">cgm_navigation_to_disable</xsl:param>

<xsl:param name="CHILD_EVEN_CLASS">child_even</xsl:param>
<xsl:param name="CHILD_ODD_CLASS">child_odd</xsl:param>
<xsl:param name="PARENT_EVEN_CLASS">parent_even</xsl:param>
<xsl:param name="PARENT_ODD_CLASS">parent_odd</xsl:param>

<!-- WDM LIST -->
<xsl:param name="TABLE_WDMLIST_CLASS">wdmlist_table_class</xsl:param>

<!-- VENDORS LIST -->
<xsl:param name="TABLE_VENDORSLIST_CLASS">vendorslist_table_class</xsl:param>


<xsl:param name="SINGLE_QUOTATION_MARK">'</xsl:param>
<xsl:param name="DOUBLE_QUOTATION_MARK" select="'&quot;'"/>
<xsl:param name="SPACE" select="' '"/>
<xsl:param name="SPACE2" select="' '"/>
<xsl:param name="TWO_POINTS"> : </xsl:param>
<xsl:param name="CGM_EXTENSION" select="'cgm'"/>
<xsl:param name="JPEG_EXTENSION" select="'jpg'"/>
<xsl:param name="TIF_EXTENSION" select="'tif'"/>
<xsl:param name="CGM_FORMAT" select="'cgm'"/>
<xsl:param name="JPEG_FORMAT1" select="'jpg'"/>
<xsl:param name="JPEG_FORMAT2" select="'jpeg'"/>
<xsl:param name="TIF_FORMAT1" select="'tif'"/>
<xsl:param name="TIF_FORMAT2" select="'tiff'"/>
<xsl:param name="TIF_FORMAT3" select="'ccitt4'"/>
<xsl:param name="COMMA" select="&quot;,&quot;"/>
<xsl:param name="COMMA_SPACE" select="&quot;, &quot;"/>
<xsl:param name="LINKS_SEPARATOR" select="&quot;|&quot;"/>
<xsl:param name="IPC_LINKS_SEPARATOR" select="&quot;&gt;&quot;"/>
<xsl:param name="SLASH_SEPARATOR" select="&quot;/&quot;"/>
<xsl:param name="OPENING_PARENTHESIS" select="&quot;(&quot;"/>
<xsl:param name="CLOSING_PARENTHESIS" select="&quot;)&quot;"/>
<xsl:param name="OPENING_BRACKET" select="&quot;[&quot;"/>
<xsl:param name="CLOSING_BRACKET" select="&quot;]&quot;"/>
<xsl:param name="EXPOSANT_CRITICAL" select="&quot;CP&quot;"/>
<xsl:param name="EXPOSANT_IMPORTANT" select="&quot;IP&quot;"/>
<xsl:param name="ALIGN_CENTER" select="&quot;CENTER&quot;"/>
<xsl:param name="UNDERSCORE_SEPARATOR" select="&quot; - &quot;"/>
<xsl:param name="DOT_SEPARATOR" select="&quot;.&quot;"/>
<xsl:param name="DATE_SEPARATOR" select="&quot;.&quot;"/>
<xsl:param name="THUMBNAIL_PATH" select="&quot;./graphics/thumbnails/&quot;"/>
<xsl:param name="ILUSTRATION_PRINT_PATH" select="&quot;./graphics/screens/&quot;"/>
<xsl:param name="GRAPHIC_FOLDER_PATH" select="&quot;./graphics/&quot;"/>
<xsl:param name="ONE" select="'1'"/>
<xsl:param name="BREAK_LINE" select="'&#10;'"/>
<xsl:param name="RETURN_LINE" select="'\r'"/>
<xsl:param name="TITLE_SEPARATOR" select="'||'"/>
<xsl:param name="UPPER_CASE_LETTER">ABCDEFGHIJKLMNOPQRSTUVWXYZ</xsl:param>
<xsl:param name="LOWER_CASE_LETTER">abcdefghijklmnopqrstuvwxyz</xsl:param>
<xsl:param name="MOD">MOD</xsl:param>
<xsl:param name="DISPLAY_NONE" select="'display: none;'"/>
<xsl:param name="DISPLAY_INLINE" select="'display: inline;'"/>
<xsl:param name="ClASS_LINK" select="'link_class'"/>
<xsl:param name="ISSUE_TYPE_UNCHANGED" select="'unchanged'"/>
<xsl:param name="ISSUE_TYPE_CHANGED" select="'changed'"/>
<xsl:param name="ISSUE_TYPE_NEW" select="'new'"/>
<xsl:param name="ISSUE_TYPE_DELETED" select="'deleted'"/>




	<xsl:param name="STATUS_TAB_TITLE" select="&quot;[STATUS_ADDRESS_STATUS_REFERENCE_LABEL]&quot;"/>
	<xsl:param name="STATUS_STATUS_SUBTITLE" select="&quot;[STATUS_STATUS_LABEL]&quot;"/>
	<xsl:param name="STATUS_STATUS_ELEMENT" select="&quot;[STATUS_STATUS_TITLE_LABEL]&quot;"/>
	<xsl:param name="STATUS_DMCODE_ELEMENT" select="&quot;[STATUS_DMC_LABEL]&quot;"/>
	<xsl:param name="STATUS_LANGAGE_ELEMENT" select="&quot;[STATUS_LANGUAGE_LABEL]&quot;"/>
	<xsl:param name="STATUS_ISSUEDATE_ELEMENT" select="&quot;[STATUS_EDITION_DATE_LABEL]&quot;"/>
	<xsl:param name="STATUS_PARTNER_ELEMENT" select="&quot;[STATUS_COMPANY_RESPONSIBLE_PARTNER_LABEL]&quot;"/>
	<xsl:param name="STATUS_AUTHOR_ELEMENT" select="&quot;[STATUS_AUTHOR_LABEL]&quot;"/>
	<xsl:param name="STATUS_APPLICABILITY_ELEMENT" select="&quot;[STATUS_APPLICABILITY_LABEL]&quot;"/>
	<xsl:param name="STATUS_REASONFORUPDATE_ELEMENT" select="&quot;[STATUS_UPDATING_REASON_LABEL]&quot;"/>
	<xsl:param name="STATUS_MAINT_AFFECTED_ELEMENT" select="&quot;[STATUS_MAINT_AFFECTED_LABEL]&quot;"/>
	<xsl:param name="STATUS_AUTHORITYNOTES_ELEMENT" select="&quot;[STATUS_NOTE_LABEL]&quot;"/>
	<xsl:param name="STATUS_AUTHORITYEXCEPTIONS_ELEMENT" select="&quot;[STATUS_EXCEPTION_LABEL]&quot;"/>
	<xsl:param name="STATUS_APPLICABILITY_MODEL_ELEMENT" select="&quot;[STATUS_APPLICABILITY_MODEL_LABEL]&quot;"/>
	<xsl:param name="STATUS_APPLICABILITY_VERSION_ELEMENT" select="&quot;[STATUS_APPLICABILITY_VERSION_LABEL]&quot;"/>
	<xsl:param name="STATUS_APPLICABILITY_SERIALNBR_ELEMENT" select="&quot;[STATUS_APPLICABILITY_SERIALNBR_LABEL]&quot;"/>
	<xsl:param name="STATUS_MAINT_AFFECTED_ELEMENT_DESCRIPTION" select="'[STATUS_MAINT_AFFECTED_DESCRIPTION_LABEL]'"/>
	<xsl:param name="STATUS_REFERENCE_ELEMENT" select="'[STATUS_REFERENCE_LABEL]'"/>
	<xsl:param name="STATUS_SHOW_HIDE_APPLICABILITIES_ELEMENT" select="'[STATUS_SHOW_HIDE_APPLICABILITIES_LABEL]'"/>

	<xsl:param name="FIGURE_SHOW_FIGURE" select="&quot;[FIGURE_SHOW_FIGURE_LABEL] &quot;"/>
	<xsl:param name="FIGURE_SHOW_SHEET" select="&quot;[FIGURE_SHOW_SHEET_LABEL] &quot;"/>

	<xsl:param name="FIGURE_LABEL" select="&quot;[FIGURE_LABEL]&quot;"/>
	<xsl:param name="SHEET_LABEL" select="&quot;[SHEET_LABEL]&quot;"/>
	<xsl:param name="TRUE" select="&quot;[TRUE_LABEL]&quot;"/>
	<xsl:param name="FALSE" select="&quot;[FALSE_LABEL]&quot;"/>
	<xsl:param name="LEGEND_TITLE_LABEL" select="&quot;[LEGEND_TITLE_LABEL]&quot;"/>

	<xsl:param name="NOTE" select="&quot;[NOTE_LABEL]&quot;"/>
	<xsl:param name="CAUTION" select="&quot;[CAUTION_LABEL]&quot;"/>
	<xsl:param name="WARNING" select="&quot;[WARNING_LABEL]&quot;"/>

	<!-- constants of IPC DU -->
	<!-- view ipc -->
	<xsl:param name="VIEW_IPC_TH_FIG_ITEM" select="&quot;[VIEW_IPC_TH_FIG_ITEM_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_TH_CSN" select="&quot;[VIEW_IPC_TH_CSN_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_TH_QTY" select="&quot;[VIEW_IPC_TH_QTY_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_TH_FSCM" select="&quot;[VIEW_IPC_TH_FSCM_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_TH_PNR" select="&quot;[VIEW_IPC_TH_PNR_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_TH_DESIGNATION" select="&quot;[VIEW_IPC_TH_DESIGNATION_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_TH_REFTO" select="&quot;[VIEW_IPC_TH_REFTO_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_TH_NRD" select="&quot;[VIEW_IPC_TH_NRD_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_TH_NRD_TOOLTIP" select="&quot;[VIEW_IPC_TH_NRD_TOOLTIP_LABEL]&quot;"/>


	<xsl:param name="VIEW_IPC_DETAIL_TITLE_GENERAL_INFO" select="&quot;[VIEW_IPC_DETAIL_TITLE_GENERAL_INFO_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TITLE_ADDITIONAL_INFO" select="&quot;[VIEW_IPC_DETAIL_TITLE_ADDITIONAL_INFO_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TITLE_HISTORY" select="&quot;[VIEW_IPC_DETAIL_TITLE_HISTORY_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TITLE_LIMITATION" select="&quot;[VIEW_IPC_DETAIL_TITLE_LIMITATION_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_EC_REMARKS" select="&quot;[VIEW_IPC_DETAIL_EC_REMARKS_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TITLE_HEADER" select="&quot;[VIEW_IPC_DETAIL_TITLE_HEADER_LABEL]&quot;"/>


	<!-- view ipc detail -->
	<xsl:param name="VIEW_IPC_DETAIL_TH_CSN" select="&quot;[VIEW_IPC_DETAIL_TH_CSN_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_FIG_ITEM" select="&quot;[VIEW_IPC_DETAIL_TH_FIG_ITEM_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_DESIGNATION" select="&quot;[VIEW_IPC_DETAIL_TH_DESIGNATION_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_QUANTITY" select="&quot;[VIEW_IPC_DETAIL_TH_QUANTITY_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_TOTAL_QUANTITY" select="&quot;[VIEW_IPC_DETAIL_TH_TOTAL_QUANTITY_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_MANUFACTURER_CODE" select="&quot;[VIEW_IPC_DETAIL_TH_MANUFACTURER_CODE_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_PNR" select="&quot;[VIEW_IPC_DETAIL_TH_PNR_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_OPTIONAL_PNR" select="&quot;[VIEW_IPC_DETAIL_TH_OPTIONAL_PNR_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_EC_PNR" select="&quot;[VIEW_IPC_DETAIL_TH_EC_PNR_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_NSN" select="&quot;[VIEW_IPC_DETAIL_TH_NSN_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_ELECTICAL_MRKS" select="&quot;[VIEW_IPC_DETAIL_TH_ELECTICAL_MRKS_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_STOCK" select="&quot;[VIEW_IPC_DETAIL_TH_STOCK_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_APPLICABILITY" select="&quot;[VIEW_IPC_DETAIL_TH_APPLICABILITY_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_REFTO" select="&quot;[VIEW_IPC_DETAIL_TH_REFTO_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_APPLICABLE_SN" select="&quot;[VIEW_IPC_DETAIL_TH_APLICABLE_SN_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_MODIFICATION_NBR" select="&quot;[VIEW_IPC_DETAIL_TH_MODIFICATION_NBR_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_SB_NBR" select="&quot;[VIEW_IPC_DETAIL_TH_SB_NBR_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_INTERCHANGEABILITY" select="&quot;[VIEW_IPC_DETAIL_TH_INTERCHANGEABILITY_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_STOCK_C" select="&quot;[VIEW_IPC_DETAIL_STOCK_C_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_STOCK_NOTAVA" select="&quot;[VIEW_IPC_DETAIL_STOCK_NOTAVA_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_INTERCHANGEABILITY_PNR_OLD_0" select="&quot;[VIEW_IPC_DETAIL_INTERCHANGEABILITY_PNR_OLD_0_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_INTERCHANGEABILITY_PNR_OLD_1" select="&quot;[VIEW_IPC_DETAIL_INTERCHANGEABILITY_PNR_OLD_1_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_INTERCHANGEABILITY_PNR_NEW_0" select="&quot;[VIEW_IPC_DETAIL_INTERCHANGEABILITY_PNR_NEW_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_INTERCHANGEABILITY_PNR_NEW_2" select="&quot;[VIEW_IPC_DETAIL_INTERCHANGEABILITY_PNR_NEW_1_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_INTERCHANGEABILITY_PNR_NEW_8" select="&quot;[VIEW_IPC_DETAIL_INTERCHANGEABILITY_PNR_NEW_2_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_LOCAL_MANUFACTURE" select="&quot;[VIEW_IPC_DETAIL_TH_LOCAL_MANUFACTURE_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_LOCAL_MANUFACTURE_SMF_VALUE_M" select="&quot;[VIEW_IPC_DETAIL_LOCAL_MANUFACTURE_SMF_VALUE_M_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_LOCAL_MANUFACTURE_SMF_OTHER_VALUE" select="&quot;[VIEW_IPC_DETAIL_LOCAL_MANUFACTURE_SMF_OTHER_VALUE_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_DFL" select="&quot;[VIEW_IPC_DETAIL_TH_DFL_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_WEIGHT" select="&quot;[VIEW_IPC_DETAIL_TH_WEIGHT_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_STOCKAGE" select="&quot;[VIEW_IPC_DETAIL_TH_STOCKAGE_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_TBO_LIMIT" select="&quot;[VIEW_IPC_DETAIL_TH_TBO_LIMIT_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_SLL_LIMIT" select="&quot;[VIEW_IPC_DETAIL_TH_SLL_LIMIT_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_UCA_CSS" select="&quot;[VIEW_IPC_DETAIL_TH_UCA_CSS_LABEL]&quot;"/>

	<xsl:param name="VIEW_IPC_DETAIL_TH_FSCM_TOOLTIP" select="&quot;[VIEW_IPC_DETAIL_TH_FSCM_TOOLTIP_LABEL]&quot;"/>
	<xsl:param name="VIEW_IPC_DETAIL_TH_PNR_TOOLTIP" select="&quot;[VIEW_IPC_DETAIL_TH_PNR_TOOLTIP_LABEL]&quot;"/>


	<!-- Constants for search popUp window -->
	<xsl:param name="SEARCH_RESULTS" select="&quot;[SEARCH_RESULTS_TITLE_LABEL]&quot;"/>
	<xsl:param name="SEARCH_QUERY" select="&quot;[SEARCH_RESULTS_QUERY_LABEL]&quot;"/>
	<xsl:param name="SEARCH_NBR_RESULTS" select="&quot;[SEARCH_RESULTS_NBR_LABEL]&quot;"/>
	<xsl:param name="SEARCH_ATTRIB_LINK" select="&quot;[SEARCH_ATTRIB_LINK]&quot;"/>
	<xsl:param name="SEARCH_ATTRIB_CONTEXT" select="&quot;[SEARCH_ATTRIB_CONTEXT]&quot;"/>

	<!-- LOAP Constants -->
	<xsl:param name="LOAP_TITLE" select="&quot;[LOAP_WELCOME_TITLE_LABEL]&quot;"/>
	<xsl:param name="LOAP_NEWS_TITLE" select="&quot;[LOAP_NEWS_TITLE_LABEL]&quot;"/>
	<xsl:param name="LOAP_CODE" select="&quot;[LOAP_WELCOME_CODE_LABEL]&quot;"/>
	<xsl:param name="LOAP_REVISION" select="&quot;[LOAP_WELCOME_REVISION_LABEL]&quot;"/>
	<xsl:param name="LOAP_DATE" select="&quot;[LOAP_WELCOME_DATE_LABEL]&quot;"/>
	<xsl:param name="LOAP_UPDATE_REASON" select="&quot;[LOAP_WELCOME_UPDATE_REASON_LABEL]&quot;"/>
	<xsl:param name="LOAP_MANUAL_TITLE" select="&quot;[LOAP_WELCOME_MANUAL_TITLE_LABEL]&quot;"/>
	<xsl:param name="LOAP_MANUAL_DATE" select="&quot;[LOAP_WELCOME_MANUAL_DATE_LABEL]&quot;"/>
	<xsl:param name="LOAP_MANUAL_LANGUAGE" select="&quot;[LOAP_WELCOME_MANUAL_LANGUAGE_LABEL]&quot;"/>

	<!-- HTL Constants -->
	<xsl:param name="HLT_DMC_LABEL" select="&quot;[HLT_DMC_LABEL]&quot;"/>
	<xsl:param name="HLT_TITLE_LABEL" select="&quot;[HLT_TITLE_LABEL]&quot;"/>
	<xsl:param name="HLT_STATUS_LABEL" select="&quot;[HLT_STATUS_LABEL]&quot;"/>
	<xsl:param name="HLT_ISSUE_DATE_LABEL" select="&quot;[HLT_ISSUE_DATE_LABEL]&quot;"/>
	<xsl:param name="HLT_VERBATIM_TEXT_LABEL" select="&quot;[HLT_VERBATIM_TEXT_LABEL]&quot;"/>
	<xsl:param name="HLT_CHANGEDESC_LABEL" select="&quot;[HLT_CHANGEDESC_LABEL]&quot;"/>
	<xsl:param name="HLT_CHANGELONGDESC_LABEL" select="&quot;[HLT_CHANGELONGDESC_LABEL]&quot;"/>
	<xsl:param name="HLT_MAINTENANCE_AFFECTED_LABEL" select="&quot;[HLT_MAINTENANCE_AFFECTED_LABEL]&quot;"/>
	<xsl:param name="HLT_DU_TITLE_LABEL" select="&quot;[HLT_DU_TITLE_LABEL]&quot;"/>

	 <!-- LOEDU -->
	 <xsl:param name="LOEDU_DU_TITLE_LABEL" select="&quot;[LOEDU_DU_TITLE_LABEL]&quot;"/>
	 <xsl:param name="LOEDU_DMC_LABEL" select="&quot;[LOEDU_DMC_LABEL]&quot;"/>
	<xsl:param name="LOEDU_TITLE_LABEL" select="&quot;[LOEDU_TITLE_LABEL]&quot;"/>
	<xsl:param name="LOEDU_APPLICABILITY_LABEL" select="&quot;[LOEDU_APPLICABILITY_LABEL]&quot;"/>
	<xsl:param name="LOEDU_ISSUE_DATE_LABEL" select="&quot;[LOEDU_ISSUE_DATE_LABEL]&quot;"/>
	<xsl:param name="LOEDU_APPLICABILITY_MODEL_LABEL" select="&quot;[LOEDU_APPLICABILITY_MODEL_LABEL]&quot;"/>
	<xsl:param name="LOEDU_APPLICABILITY_VERSION_LABEL" select="&quot;[LOEDU_APPLICABILITY_VERSION_LABEL]&quot;"/>
	<xsl:param name="LOEDU_APPLICABILITY_SERIALNO_LABEL" select="&quot;[LOEDU_APPLICABILITY_SERIALNO_LABEL]&quot;"/>
	<xsl:param name="LOEDU_APPLICABILITY_SERIALNO_BASIC_INSTALLATION" select="&quot;[LOEDU_APPLICABILITY_SERIALNO_BASIC_INSTALLATION_LABEL]&quot;"/>
	<xsl:param name="LOEDU_APPLICABILITY_SERIALNO_CUSTOMIZED_INSTALLATION" select="&quot;[LOEDU_APPLICABILITY_SERIALNO_CUSTOMIZED_INSTALLATION_LABEL]&quot;"/>
	<!--  Message in pdf version footer  -->
	<xsl:param name="PDF_FOOTER_MESSAGE" select="&quot;[PDF_FOOTER_MESSAGE_LABEL]&quot;"/>
	<xsl:param name="END_OF_DU" select="'[PDF_END_OF_DU_LABEL]'"/>

	<xsl:param name="LINK_APPLICABILITY_MODEL_ELEMENT" select="&quot;[LINK_APPLICABILITY_MODEL_LABEL]&quot;"/>
	<xsl:param name="LINK_APPLICABILITY_VERSION_ELEMENT" select="&quot;[LINK_APPLICABILITY_VERSION_LABEL]&quot;"/>
	<xsl:param name="LINK_APPLICABILITY_SERIALNBR_ELEMENT" select="&quot;[LINK_APPLICABILITY_SERIALNBR_LABEL]&quot;"/>

	<!-- MSM -->
	<xsl:param name="SUBUD_HEADER_TABLE_PNR_DESCRIPTION" select="&quot;[SUBUD_HEADER_TABLE_PNR_DESCRIPTION_LABEL]&quot;"/>
    <xsl:param name="SUBUD_HEADER_TABLE_PNR_MPN_PART_1" select="&quot;[SUBUD_HEADER_TABLE_PNR_MPN_PART_1_LABEL]&quot;"/>
    <xsl:param name="SUBUD_HEADER_TABLE_PNR_MPN_PART_2" select="&quot;[SUBUD_HEADER_TABLE_PNR_MPN_PART_2_LABEL]&quot;"/>
    <xsl:param name="SUBUD_HEADER_TABLE_PNR_LIMIT" select="&quot;[SUBUD_HEADER_TABLE_PNR_LIMIT_LABEL]&quot;"/>
    <xsl:param name="SUBUD_HEADER_TABLE_PNR_INTERVAL" select="&quot;[SUBUD_HEADER_TABLE_PNR_INTERVAL_LABEL]&quot;"/>
    <xsl:param name="SUBUD_HEADER_TABLE_PNR_MARGIN" select="&quot;[SUBUD_HEADER_TABLE_PNR_MARGIN_LABEL]&quot;"/>

    <xsl:param name="SUBUD_HEADER_TABLE_REFDOC_DESCRIPTION" select="&quot;[SUBUD_HEADER_TABLE_REFDOC_DESCRIPTION_LABEL]&quot;"/>
    <xsl:param name="SUBUD_HEADER_TABLE_REFDOC_DOC" select="&quot;[SUBUD_HEADER_TABLE_REFDOC_DOC_LABEL]&quot;"/>
    <xsl:param name="SUBUD_HEADER_TABLE_REFDOC_INTERVAL" select="&quot;[SUBUD_HEADER_TABLE_REFDOC_INTERVAL_LABEL]&quot;"/>
    <xsl:param name="SUBUD_HEADER_TABLE_REFDOC_MARGIN" select="&quot;[SUBUD_HEADER_TABLE_REFDOC_MARGIN_LABEL]&quot;"/>
    <xsl:param name="GLOSSARY_TITLE_LABEL" select="&quot;[GLOSSARY_TITLE_LABEL]&quot;"/>

    <!-- WDM LIST -->
    <xsl:param name="WDMLIST_TABLE_IDENT_TITLE" select="&quot;[WDMLIST_TABLE_IDENT_TITLE_LABEL]&quot;"/>
    <xsl:param name="WDMLIST_TABLE_POSITION_TITLE" select="&quot;[WDMLIST_TABLE_POSITION_TITLE_LABEL]&quot;"/>
    <xsl:param name="WDMLIST_TABLE_DIAGRAM_TITLE" select="&quot;[WDMLIST_TABLE_DIAGRAM_TITLE_LABEL]&quot;"/>
    <xsl:param name="WDMLIST_TABLE_ASSY_TITLE" select="&quot;[WDMLIST_TABLE_ASSY_TITLE_LABEL]&quot;"/>
    <xsl:param name="WDMLIST_TABLE_REMARKS_TITLE" select="&quot;[WDMLIST_TABLE_REMARKS_TITLE_LABEL]&quot;"/>

    <!-- VENDORS LIST -->
    <xsl:param name="VENDORSLIST_TABLE_FSCM_TITLE" select="&quot;[VENDORSLIST_TABLE_FSCM_TITLE_LABEL]&quot;"/>
    <xsl:param name="VENDORSLIST_TABLE_VENDOR_TITLE" select="&quot;[VENDORSLIST_TABLE_VENDOR_TITLE_LABEL]&quot;"/>

    <xsl:param name="BEFORE_PAGE_NUMBER" select="&quot;[PAGE_LABEL]&quot;"/>
    <xsl:param name="LEGEND_DEFLIST_TABLE_TH_ITEM" select="&quot;[LEGEND_DEFLIST_TABLE_TH_ITEM_LABEL]&quot;"/>
    <xsl:param name="LEGEND_DEFLIST_TABLE_TH_DESC" select="&quot;[LEGEND_DEFLIST_TABLE_TH_DESC_LABEL]&quot;"/>
    <xsl:param name="PNR_P_N" select="&quot;[PNR_P_N_LABEL]&quot;"/>
    <xsl:param name="REFINT_DEFAULT_TABLE_TITLE" select="&quot;[REFINT_DEFAULT_TABLE_TITLE_LABEL]&quot;"/>

      <xsl:param name="REFINT_INTRO_TEXT" select='"[REFINT_INTRO_TEXT_LABEL]"'/>
          <xsl:param name="TABLE_TEXT" select='"[TABLE_TEXT_LABEL]"'/>
	<xsl:param name="PRE_MDEFF_PREFIX_TEXT" select="'PRE'"/>
	<xsl:param name="POST_MDEFF_PREFIX_TEXT" select="'POST'"/>
</xsl:stylesheet>`,
  "text/xml"
);
